import React, { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import SendEmail from "../../Common/SendEmail";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function Step2(props) {

    const [percentage, setPercentage] = useState(props.Commission);
    const [remainingBalance, setRemainingBalance] = useState(props.balance);
    const [UserBalance, setUserBalanceBalance] = useState(props.balance);
    const [OTP, setOTP] = useState(0);
    const [commissionAmount, setcommissionAmount] = useState(0);
    const [totalDeduction, setTotalDeduction] = useState(0);
    const [balanceLimit, setbalanceLimit] = useState();
    const [amount, setAmount] = useState(props.step2Data.Current_Balance);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [CodeCheck, setCodeCheck] = useState("");

    // console.log(props.step2Data);


    const [Amounterror, setAmounterror] = useState(false);

    const CalcPercentage = (e) => {
        let amount = e.target.value;

        if (amount > UserBalance) {
            toast.error("Amount should be less than your balance", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000
            });
            setAmount("");
            return false;
        }

        if (amount == 0 || amount != "") {
            if (amount < 20) {
                setAmounterror(true);

            }
            else {
                setAmounterror(false)
            }

        }

        //setAmount(amount.slice(0, 7));

        // if (amount <= props.balance) {
        //     setAmount(amount);
        // }


        let commissionAmount = amount / 100 * percentage;
        let final = parseInt(amount) - parseInt(commissionAmount);
        setcommissionAmount(commissionAmount);
        // console.log(commissionAmount);
        setRemainingBalance(props.balance - final);
        setTotalDeduction(parseInt(amount) + parseInt(commissionAmount));


        if (amount == 0) {
            setRemainingBalance(props.balance);
        }
        setAmount(amount);


        // Check if the value is a valid multiple of 5 or 10

        // if (final === '' || isMultipleOf5Or10(final)) {
        //     setAmount(final);
        //     setShowError(false); // Hide the error message when a valid amount is entered
        // } else {
        //     setShowError(true); // Show the error message when an invalid amount is entered
        // }

    }


    useEffect(() => {
        let length = props.balance.toString().split('.')[0].length;
        setbalanceLimit(length);
    }, []);


    const handleSendCode = () => {
        const props = {
            TransactionType: 'BalanceTransfer'
        };


        SendEmail(props);

        setIsButtonDisabled(true);

        // Enable the button after 1 minute
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 60000);
        setTimer(60);
    }

    const [timer, setTimer] = useState(60); // 60 seconds

    useEffect(() => {
        let interval;

        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isButtonDisabled]);

    const Checkcode = (e) => {
        let value = e.target.value
        setCodeCheck(value)
    }

    return (
        <>
            <ToastContainer />
            <Form noValidate validated={props.validated2} onSubmit={props.handleSubmitAmount}>
                {props.hasError ? <Alert key="danger" variant="danger">{props.errorMessage}</Alert> : ""}
                <div className="transfer-wrap">
                    <div className="available-balance">
                        <label htmlFor="">Available Balance </label>
                        {/* <span>PKR {!isNaN(amount) ? - (UserBalance - amount) : UserBalance}</span> */}
                        <span>{!isNaN(amount) ? (UserBalance - amount - commissionAmount).toFixed(3) : UserBalance}</span>
                        {/* {commissionAmount != 0 ? "$" + commissionAmount.toFixed(2) : ""} */}
                    </div>
                    <div className="transfer-body form-default">
                        <div className="fake-input mb-3">
                            <label>Beneficiary</label>
                            <span>{props.user.User_Name}</span>
                        </div>
                        <div className="mb-3">
                            <div className="form-group">
                                <input type="number"
                                    name="amount"
                                    placeholder="Enter Amount"
                                    onChange={(e) => CalcPercentage(e)}
                                    autoComplete="off"
                                    value={amount}
                                    className="form-control"
                                    required />
                            </div>
                            <ul className="add-condition">
                                {Amounterror && <li> <p className="Resend m-0"> Minimum amount: $20</p></li>}
                                {/* {showError && <li><p className="Resend p-0"> Amount should be in (multiples of 5 or 10).</p></li>} */}
                            </ul>
                            {/* <p className="available-amout">Available Balance: <span> $ {remainingBalance.toFixed(3)}</span></p> */}
                        </div>
                        <div className="form-group mb-3">
                            <input type="text" placeholder="Add Notes (Optional)" className="form-control" name="notes" maxLength={20} />
                        </div>

                        <div className="form-group mb-3">
                            <label>Transaction Fee</label>
                            <input type="text"
                                placeholder="Transaction Fee"
                                className="form-control disabled"
                                value={`${percentage}%`}
                                readOnly={true} />
                            <div className="icon with-label">
                                <b>{commissionAmount != 0 ? "$" + commissionAmount.toFixed(2) : ""}</b>
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <input type="text" placeholder="Receivable Amount" className="form-control disabled" readOnly={true} />
                            <div className="icon">
                                {/* <b>{`${!isNaN(finalAmount) ? finalAmount.toFixed(2) : ''}`}</b> */}
                                <b>${amount}</b>
                            </div>
                        </div>
                        <div className="form-group">
                            <input type="text" placeholder="Total Deduction" className="form-control disabled" readOnly={true} />
                            <div className="icon">
                                {/* <b>{`${!isNaN(finalAmount) ? finalAmount.toFixed(2) : ''}`}</b> */}
                                <b>${parseInt(amount) + parseInt(commissionAmount)}</b>
                            </div>
                        </div>

                        <div className="code pb-2 pt-3 mb-3">
                            <div className="row g-3 align-items-top">
                                <div className="col-12">

                                    <div className="form-group">
                                        <input type="text"
                                            onChange={Checkcode}
                                            className="form-control"
                                            placeholder='Email Verification Code' name="AuthCode" autoComplete="disable" required />
                                    </div>

                                </div>
                                <div className="col-12 text-center">
                                    <button
                                        className="button button-3d-outline w-100"
                                        onClick={handleSendCode}
                                        disabled={isButtonDisabled}
                                    >
                                        GET CODE
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="">Transaction PIN</label>
                            <input type="text"
                                placeholder='XXXXXX' name="pincode" maxLength={6} autoComplete="disable" required className="form-control" />
                        </div>
                    </div>
                    <div className="">
                        <button className={`button button-3d-primary w-100`} disabled={OTP.length === 0 || amount?.length === 0 || CodeCheck.length === 0 || Amounterror == true || props.loading}>
                            Transfer {props.loading ? "..." : ""}
                        </button>
                        <button onClick={props.toStep1} className="button button-3d-outline button-icon-bs w-100 mt-3" >
                            <ArrowLongLeftIcon />
                            <span>BACK</span>
                        </button>
                    </div>
                </div>
            </Form>
        </>
    );
}

export default Step2;