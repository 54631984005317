import React, { useEffect, useState } from "react";
import Config from "../../../Config";
import axios from "axios";
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { Form } from 'react-bootstrap';
import LoginCheck from "../General/LoginCheck";


import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate } from "react-router-dom";


function FutureTransfer(props) {

    let navigate = useNavigate();

    const AccessKey =(localStorage.getItem("AccessKey"));
   const UserID =(localStorage.getItem("ID"));
    const [validated, setValidated] = useState(false);
    
    const [loading, setLoading] = useState(false);
  
    const [MemberInfo, setMemberInfo] = useState({});
    const [ReceivableAmount, setReceivableAmount] = useState(0);

    useEffect(() => {

        setLoading(true);
        
        const Parms = {
            AccessKey: AccessKey,
            UserID: UserID,

        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetDashboardData',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Parms
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {

                    setMemberInfo(response.data.Member);
                        setLoading(false);

                    // if (response.data.Member?.Email_Address_Verified == true) {
                    //     setMemberInfo(response.data.Member);
                    //     setLoading(false);
                    // }
                   
                    
                }
                else if (
                    (response.data.status_message === "Invalid Access Key.")
                ) {
                    navigate("/login");
                }


            })
            .catch(function (error) {
                // console.log(error);
            });



    }, []);


 





    
    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {

           


            // else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                Amount: event.target.amount.value,
                PinCode: event.target.pincode.value,
               
            };

            // console.log(signInData);

            var api_config = {
                method: 'post',
                url: Config.base_url + 'Package/TransferPackageToCash',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {


                    // console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });


                        //

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

            //}

        }

        setValidated(true);
    };


    const handleChangeAmount = (e) => {
        const value = e.target.value;
       

        // Check if the value is a valid number
        const numericValue = parseFloat(value);
        if (!isNaN(numericValue)) {
            const thirtyPercent = (numericValue * 0.3).toFixed(2); // Calculate 30% and format to 2 decimal places
            //// console.log(`30% of Entered Value: ${thirtyPercent}`);
            setReceivableAmount(value - thirtyPercent);
        } 

        // Update the state with the entered value
        
    } 

    return (
        <>
            <LoginCheck />
            <ToastContainer />
            <div className="mobile-container">
                <div className="section-title">Future Transfer</div>
                {
                    loading ?
                    <Spinner animation="border" role="status" className="full-screen-loading">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    :
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="theme-card p-4 mt-3">
                            <p className="mb-0">Future Balance</p>
                            <h3 className="m-0 text-white">${MemberInfo.Investment_Balance}</h3>
                        </div>
                    <div className="form-default mt-3">
                      
                        <div className="form-group mb-4">
                            <label>Enter Amount to Transfer</label>
                            <input type="number" name="amount" onChange={handleChangeAmount} className="form-control" required />
                        </div>
                        
                        <div className="form-group mb-4">
                            <label>PIN Code</label>
                            <input type="number" name="pincode" className="form-control" required />
                        </div>

                        <ul className="list-transactions m-0">
                            <li>
                                <div>
                                    <span>Transaction Fee</span>
                                    <span className="value">30%</span>
                                </div>
                                <div>
                                    <span>Receivable Amount</span>
                                    <span className="value">{ReceivableAmount}</span>
                                </div>
                            </li>
                           
                        </ul>

                        <div className="text-center mt-5">
                        <button type="submit" className="button-3d-primary">Transfer</button>
    
    
                    </div>
    
                    </div>
                    </Form>
                }
               
            </div>
        </>
    );
}

export default FutureTransfer;