import React, { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, useLocation } from "react-router-dom";
import Logo from '../../assets/images/logo/gif-logo.gif';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import dividerH from '../../assets/images/divider-h.svg';
import { ArrowLeftEndOnRectangleIcon, CheckBadgeIcon, ExclamationTriangleIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import ReCAPTCHA from 'react-google-recaptcha';

function ResetPassword(props) {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [Confirmpassword, setConfirmpassword] = useState("");
    const [password, setPassword] = useState("");
    const [IsVerified, setIsVerified] = useState(false);
    const [IsPasswordChanged, setIsPasswordChanged] = useState(false);
    const [Passwordvalid, setPasswordvalid] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false);



    const [captchaValue, setCaptchaValue] = useState(null);

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    }
    let navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const SecurityCode = searchParams.get("AuthorizationCode");
    const UserName = searchParams.get("UserName");


    useEffect(() => {
        document.title = "Reset Password";




        if (SecurityCode && UserName) {
            setLoading(true);
            const data = {
                AuthorizationCode: SecurityCode,
                UserName: UserName,

            };


            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/GetResetUserInfo',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {

                    // console.log(response.data);

                    if (response.data.status_code == 1) {
                        setIsVerified(true);
                        setLoading(false);
                    }
                    else {
                        setIsVerified(false);
                        setLoading(false);
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });


                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }
        else {
            setIsVerified(false);
            setLoading(false);
        }



    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {

            if (password != Confirmpassword) {

                toast.error('Password did\'t match ', {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }

            else {

                if (captchaValue) {
                    setLoading(true);
                    const data = {
                        UserName: UserName,
                        NewPassword: event.target.Confirmpassword.value,
                        AuthorizationCode: SecurityCode,
                        UserName: UserName,
                    };
                    // console.log(data);


                    var api_config = {
                        method: 'post',
                        url: config.base_url + 'Members/ChangePasswordWithoutKey',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };

                    axios(api_config)
                        .then(function (response) {


                            // console.log(response.data);
                            if (response.data.status_code == 1) {


                                toast.success(response.data.status_message, {
                                    position: toast.POSITION.TOP_RIGHT
                                });

                                setIsPasswordChanged(true);


                                //navigate('/dashboard/home');


                            }
                            else {
                                toast.error(response.data.status_message, {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                                setIsPasswordChanged(false);
                            }

                            setLoading(false);


                        })
                        .catch(function (error) {
                            setLoading(false);
                            toast.error('Network Error..', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });

                } else {
                    alert("Please complete the CAPTCHA");
                }
            }

        }

        setValidated(true);
    };


    const handleChange = (event) => {
        const { value } = event.target;
        setPassword(value.slice(0, 18));

        if (isPasswordValid(value)) {
            setPasswordvalid(false)
        }
        else {
            setPasswordvalid(true)
            return false
        }

    };

    const isPasswordValid = (password) => {
        // Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
        return passwordRegex.test(password);
    };

    return (
        <>
            <ToastContainer />
            <main id='main' className="bg-black" style={{ backgroundImage: 'none' }}>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                    <div className="login_box form-default">
                        <div className="login_box_head">
                            <img src={Logo} alt='brand' />
                            <h4>Reset Password</h4>
                        </div>
                        <div className="login_box_body">
                            {
                                loading ?
                                    <div className="text-center">
                                        <Spinner animation="border" role="status" className="full-screen-loading"></Spinner>
                                    </div>
                                    :
                                    <>
                                        {
                                            IsVerified ?
                                                <>
                                                    {
                                                        IsPasswordChanged ?
                                                            <>
                                                                <div className="text-center">
                                                                    <div className="icon">
                                                                        <CheckBadgeIcon className="w-25 text-secondary" />
                                                                    </div>
                                                                    <p>Your password has been changed succesfully.</p>
                                                                    <NavLink to="/login" className="button button-3d-primary button-icon-bs w-100 justify-content-center"><span>Login Now</span> <ArrowLeftEndOnRectangleIcon /></NavLink>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className="icon-input mb-3">
                                                                    <input
                                                                        type={showPassword ? "text" : "password"}
                                                                        name="password"
                                                                        placeholder='New Password'
                                                                        maxLength={18}
                                                                        value={password}
                                                                        onChange={handleChange}
                                                                        className="form-control"
                                                                        required
                                                                    />
                                                                    <div className="icon" onClick={() => setShowPassword(!showPassword)} >
                                                                        {
                                                                            showPassword ?
                                                                                <EyeSlashIcon />
                                                                                :
                                                                                <EyeIcon />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="icon-input mb-3">
                                                                    <input
                                                                        type={showConfirmPassword ? "text" : "password"}
                                                                        onChange={(e) => setConfirmpassword(e.target.value)}
                                                                        name="Confirmpassword"
                                                                        placeholder='Confirm Password'
                                                                        className="form-control"
                                                                        required
                                                                    />
                                                                    <div className="icon" onClick={() => setshowConfirmPassword(!showConfirmPassword)} >
                                                                        {
                                                                            showConfirmPassword ?
                                                                                <EyeSlashIcon />
                                                                                :
                                                                                <EyeIcon />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="mb-4">
                                                                    <ReCAPTCHA
                                                                        theme="dark"
                                                                        sitekey="6LeSRSIqAAAAAFIQTB2ZfkEMeo6BQROgLLUyw4xB"
                                                                        onChange={handleCaptchaChange}
                                                                    />
                                                                </div>
                                                                <button type="submit" className="button-3d-primary w-100"
                                                                    disabled={loading || Confirmpassword.length === 0 || captchaValue == null}
                                                                >
                                                                    Update {loading ? '...' : ''}
                                                                </button>
                                                            </>
                                                    }
                                                </>
                                                :
                                                <>

                                                    <div className="text-center">
                                                        <div className="icon">
                                                            <ExclamationTriangleIcon className="w-25 text-primary" />
                                                        </div>

                                                        <p>You are using invalid or expire link.</p>
                                                    </div>
                                                </>
                                        }
                                    </>
                            }
                            <div style={{ marginTop: "-15px" }}>
                                <img src={dividerH} />
                            </div>
                            <div className='mt-3 d-flex m-auto justify-content-center'>
                                <p className='m-0 fs-6'>Back to <NavLink to={'/login'} className='fs-6'> SIGN IN</NavLink></p>
                            </div>
                        </div>
                    </div>

                </Form>
            </main>
        </>
    );
}

export default ResetPassword;