import React, { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import config from "../../Config";
import axios from "axios";
import Logo from '../../assets/images/logo/gif-logo.gif';
import { NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { generateRandomString } from "../Dashboard/General/Functions";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import ReCAPTCHA from 'react-google-recaptcha';

function Forgot(props) {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);



    const [captchaValue, setCaptchaValue] = useState(null);

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    }



    const navigator = useNavigate();
    useEffect(() => {
        document.title = "Forgot Password";
    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            if (captchaValue) {
                setLoading(true);
                const signInData = {
                    Email_Address: event.target.email.value,
                };
                var api_config = {
                    method: 'post',
                    url: config.base_url + 'Members/ForgotPassword',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: signInData
                };
                axios(api_config)
                    .then(function (response) {

                        if (response.data.status_code == 1) {
                            toast.success(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setTimeout(() => {
                                navigator('/login')
                            }, 3000);

                        }
                        else {
                            toast.error(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setIsButtonDisabled(false)
                        }
                        setLoading(false);
                    })
                    .catch(function (error) {
                        setLoading(false);
                        toast.error('Network Error..', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    });
            } else {
                alert("Please complete the CAPTCHA");
            }
        }

        setValidated(true);
    };


    return (
        <>
            <ToastContainer />
            <main id='main' className="bg-black" style={{ backgroundImage: 'none' }}>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                    <div className="login_box form-default">
                        <div className="login_box_head">
                            <img src={Logo} alt='brand' />
                            <h4>Forgot Password</h4>
                        </div>
                        <div className="login_box_body">
                            <div className="icon-input mb-4">
                                <input
                                    type="text"
                                    name="email"
                                    placeholder='Email/Username'
                                    className="form-control"
                                    required
                                />
                                <div className="icon">
                                    <UserCircleIcon />
                                </div>
                            </div>
                            <div className="mb-4">
                                <ReCAPTCHA
                                    theme="dark"
                                    sitekey="6LeSRSIqAAAAAFIQTB2ZfkEMeo6BQROgLLUyw4xB"
                                    onChange={handleCaptchaChange}
                                />
                            </div>

                            <button type="submit" className="button-3d-primary w-100"
                                disabled={isButtonDisabled || loading || captchaValue == null}
                            >
                                Get Reset Link {loading ? '...' : ''}
                            </button>
                            <div className='mt-3 d-flex m-auto justify-content-center'>
                                <p className='m-0 fs-6'>I already have an account<NavLink to={'/login'} className='fs-6'> SIGN IN</NavLink></p>
                            </div>
                        </div>
                    </div>

                </Form>
            </main>
        </>
    );
}

export default Forgot;