import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { AtSymbolIcon, PhoneIcon, UserCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { toast, ToastContainer } from "react-toastify";
import Config from "../../Config";
import axios from "axios";
import { NavLink, useNavigate } from 'react-router-dom';
import { generateRandomString } from '../Dashboard/General/Functions';
import inquiry from '../../assets/images/support.png';
import whatsapp from '../../assets/images/whatsapp.png'
const Support = () => {

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = (localStorage.getItem("ID"));
  const Email = (localStorage.getItem("Email_Address"));
  const Mobile_No = (localStorage.getItem("Mobile_No"));
  const Full_Name = (localStorage.getItem("Full_Name"));

  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [Usercaptcha, setUsercaptcha] = useState("");
  const [mobileno, setmobileno] = useState("");
  const [massage, setmassage] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    setCaptcha(generateRandomString(6));
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      // check captcha
      let host = window.location.hostname;

      if (host != 'localhost') {
        if (Usercaptcha != captcha) {


          toast.error('Incorrect Captcha', {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;

        }
      }


      setLoading(true);

      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        Full_Name: Full_Name,
        MobileNo: mobileno,
        Email_Address: Email,
        Details: massage,
      };
      // console.log(data);

      var api_config = {
        method: "post",
        url: Config.base_url + "Support/SbumitInquiry",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      // console.log(data);

      axios(api_config)
        .then(function (response) {
          // console.log('fahad', response.data);

          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000
            });
            setLoading(false);
            setmobileno('');
            setmassage('');
          }
          else if
            (response.data.status_message === "Invalid Access Key.") {
            navigate("/login");
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000
            });
            setLoading(false);
          }

        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Something went wrong..", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000
          });
        });
    }

    setValidated(true);
  };

  const UpdateCaptcha = () => {
    setCaptcha(generateRandomString(6));
  }

  return (
    <>
      <ToastContainer />
      <div className="mobile-conatiner">
        <div className="profile-bg-card">
          <div className="section-title mb-3">
            Inquiry Form
          </div>
          <div className="text-center mb-4">
            <img src={inquiry} alt="" className='img-fluid' />
          </div>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>

            <div className="form-default">
              <div className="row gy-3">
                <div className="col-md-12">
                  <div className="icon-form-group">
                    <label>Full Name</label>
                    <div className="icon-input">
                      <input
                        value={Full_Name}
                        className="form-control"
                        readOnly
                      />
                      <div className="icon">
                        <UserCircleIcon />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="icon-form-group">
                    <label>Email</label>
                    <div className="icon-input">
                      <input
                        readOnly
                        value={Email}
                        placeholder="Email"
                        className="form-control"
                      />
                      <div className="icon">
                        <AtSymbolIcon />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="icon-form-group">
                    <label>Phone</label>
                    <div className="icon-input">
                      <input
                        className='form-control'
                        required
                        onChange={(e) => setmobileno(e.target.value)}
                        value={mobileno}
                        placeholder="xxx xxx xxxx"
                      />
                      <div className="icon">
                        <PhoneIcon />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="icon-form-group">
                    <label>Massage</label>
                    <div className="icon-input">
                      <textarea
                        rows={5}
                        placeholder="Massage"
                        value={massage}
                        onChange={(e) => setmassage(e.target.value)}
                        className="form-control ps-0"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="verify">
                    <div className="Character" >
                      {captcha}
                    </div>
                    <div className="form-group">
                      <div className="inputwrap">
                        <input type="text" onChange={(e) => setUsercaptcha(e.target.value)} className="form-control" name="" placeholder="Enter Captcha" required />
                      </div>
                    </div>
                  </div>
                  <div className="my-2">
                    <NavLink style={{ fontSize: "13px" }} onClick={UpdateCaptcha}>Refresh The Captcha</NavLink>
                  </div>
                </div>
                <div className="col-md-12">
                  <button type='submit' disabled={loading} className="button button-3d-primary w-100">
                    Submit {loading ? '...' : ''}
                  </button>
                </div>
              </div>
            </div>
          </Form>

          <a target='_blank' href='http://wa.me/447895649710' className={`whatsapp-icon text-center`} >
            <i className='text-secondary'>For priority support connect with businessi.ai</i>
            <div className="icon">
              <img src={whatsapp} alt="whatsapp" style={{ width: '50px' }} />
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Support;
