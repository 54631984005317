import React, { useState, useEffect } from "react";
import Logo from '../../assets/images/logo/gif-logo.gif';
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import dividerH from '../../assets/images/divider-h.svg';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ImgEmailVerified from "../../assets/images/email-verified.svg";
import ImgEmailFailed from "../../assets/images/emailfailed.svg";
import { ArrowLeftEndOnRectangleIcon, LinkIcon } from "@heroicons/react/24/outline";
import Loading from "../Common/loading";


function VerifyEmail(props) {

    const AccessKey = (localStorage.getItem("AccessKey"));
    const UserID = (localStorage.getItem("ID"));
    const [UserName1, setUserName1] = useState(localStorage.getItem("User_Name"));
    const [loading, setLoading] = useState(false);

    let navigate = useNavigate();


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const SecurityCode = searchParams.get("SecurityCode");
    const UserName = searchParams.get("UserName");
    const ExpiryDate = searchParams.get("ExpiryDate");
    const email = searchParams.get(null);
    const [IsVerified, setIsVerified] = useState(false);


    useEffect(() => {

        if (email) {

            setIsVerified(false);
        }
        else {
            setLoading(true);
            const data = {
                // UserID: UserID,
                // AccessKey: AccessKey,
                SecurityCode: SecurityCode,
                UserName: UserName,
                ExpiryDate: ExpiryDate
            };

            // console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/VerifyEmailAddress',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {

                    // console.log(response.data);

                    if (response.data.status_code == 1) {
                        setIsVerified(true);
                        setLoading(false);
                        //navigate('/login');


                    }
                    else {

                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        setIsVerified(false);
                        setLoading(false);
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
        }

    }, []);

    const ResendEmail = () => {
        const data = {

            UserName: UserName1,

        };

        //   // console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/SendSignUpEmail',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        //// console.log(data);
        axios(api_config)
            .then(function (response) {
                // console.log(response.data)
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                else {

                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }



    return (
        <>
            <ToastContainer />
            <main id='main' className="bg-black" style={{ backgroundImage: 'none' }}>
                <div className="login_box form-default">
                    <div className="login_box_head">
                        <img src={Logo} alt='brand' />
                    </div>
                    <div className="login_box_body">
                        {
                            loading ?

                                <Spinner animation="border" role="status" className="full-screen-loading"></Spinner>

                                :
                                <>
                                    {
                                        IsVerified != true ?
                                            <div className="text-center">
                                                <img src={ImgEmailVerified} style={{ maxWidth: "100px" }} className="mb-3" />
                                                <h4 className="text-primary">Email Verified!</h4>
                                                <p>Congratulations!<br />Your email verification is complete! Welcome to the Business Intelligence.</p>
                                                <Link to={`/login`} className="button button-3d-primary button-icon w-100">
                                                    Login Now {loading ? '...' : ''}<span><ArrowLeftEndOnRectangleIcon /></span>
                                                </Link>
                                            </div>
                                            :
                                            <div className="text-center">
                                                <img src={ImgEmailFailed} style={{ maxWidth: "110px" }} className="mb-3" />
                                                <h4 className="text-primary">Email Verification Failed!</h4>
                                                <p>Unfortunately, your email verification is not complete. Please try again now.</p>
                                                <button type="submit" className="button button-3d-primary button-icon w-100"
                                                    onClick={ResendEmail}
                                                >
                                                    Resend Verify Link {loading ? '...' : ''}<span><LinkIcon /></span>
                                                </button>
                                            </div>
                                    }
                                </>

                        }
                    </div>
                </div>

            </main>
        </>
    );
}

export default VerifyEmail;