import React, { useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { ArrowLeftIcon, CreditCardIcon,ArrowTrendingUpIcon } from "@heroicons/react/24/outline";
import LoginCheck from "../General/LoginCheck";
import Tick from "../../Common/Tick.json";
import Lottie from "lottie-react";
import "@lottiefiles/lottie-player";

const PackageSuccess = (props) => {


    const playerRef = useRef();

    useEffect(() => {
        playerRef.current?.playFromBeginning();
    }, [])


    return (
        <>
            <LoginCheck />
            <div className="mobile-container">
                <div className="button-arrow-back mt-1">
                    <Link to={`/dashboard/packages`}><ArrowLeftIcon /> <span>Back</span></Link>
                </div>

                <div className="theme-card mt-3 p-4">
                    <div className="d-flex justify-content-center">
                        <Lottie loop={true} animationData={Tick} className="w-75" />
                    </div>
                    <h3 className="text-white text-center">Success</h3>
                    <p className="text-center">
                        Your Funds has been Added. We appreciate your contribution and hope you enjoy our service!
                    </p>
                </div>
                <div className="mt-2 d-flex justify-content-center">
                    <NavLink to={`/dashboard/packages`} className="button button-icon-primary">
                        <CreditCardIcon /> Add More
                    </NavLink>
                    <NavLink to="/dashboard/transactions/packages" className="button button-icon-primary">
                        <ArrowTrendingUpIcon /> Transactions
                    </NavLink>
                </div>
            </div>
        </>
    )
}

export default PackageSuccess