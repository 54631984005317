import React, { useEffect, useState } from "react";
import config from "../../../Config";

import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import PackagePurchased from "./PackagePurchase";

import { ArrowLeftIcon } from "@heroicons/react/24/outline";

import { NumberFormat } from "../../Dashboard/General/Functions";
import LoginCheck from "../General/LoginCheck";
import { Spinner } from "react-bootstrap";


function PackagesDetails(props) {

    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));

    const [Name, setName] = useState('');
    const [Amount, setAmount] = useState('');

    const [Profit, setProfit] = useState('');



    const [PackageData, setPackageData] = useState({});

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let params = useParams();

    //Selected Package Amount
    const [SPA, setSPA] = useState(0);

    const [MaxDuration, setMaxDuration] = useState(16);

    //Per Month Percentage
    const [PMP, setPMP] = useState(0);
    const [Duration, setDuration] = useState(1);
    const [Progress, setProgress] = useState(0);
    const [Percentage, setPercentage] = useState(0);


    useEffect(() => {

        let TRP = 0;

        if (SPA == 100000) {
            setMaxDuration(24);
            setPercentage(9);
        }
        else if (SPA == 300000) {
            setPercentage(11);
            setMaxDuration(24);
        }
        else if (SPA == 600000) {
            setPercentage(11);
            setMaxDuration(24);
        }
        else if (SPA == 200000) {
            setPercentage(11);
            setMaxDuration(16);
        }
        else if (SPA == 500000) {
            setPercentage(12);
            setMaxDuration(16);
        }
        else {
            setPercentage(14);
            setMaxDuration(15);
        }

        TRP = (SPA / 100 * Percentage) * MaxDuration;


        let progress = ((SPA / 100 * Percentage) * Duration) / TRP * 100;

        let PMP1 = TRP / MaxDuration * Duration;
        setPMP(PMP1);

        //Bar Percentage =  (Package amount / 100 * 13 * NoofMonthSelected in Bar)  / TRP * 100;

        setProfit(TRP);
        setProgress(progress);


    }, [SPA, Duration, MaxDuration]);



    useEffect(() => {
        setDuration(1);
    }, [SPA]);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            Member_Type: MemberType,
            ID: params.id,
        };
        // console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Package/GetPackagebyID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                // console.log("GetPackagebyID", response.data);

                if (response.data.status_code == 1) {
                    setPackageData(response.data.Package);
                    setAmount(response.data.Package.MinAmount);
                    setLoading(false);

                }
                else if (
                    (response.data.status_message === "Invalid Access Key.")
                ) {
                    navigate("/login");
                } else if (response.data.status_code == 0) {
                    localStorage.clear();
                    navigate("/login");
                }

                else {
                    setLoading(false);
                }
            })
            .catch(function (error) {
            });
    }, []);




    const handleDuration = (e) => {
        setAmount(e.target.value);
    }


    return (
        <>
            <LoginCheck />
            <ToastContainer />
            <PackagePurchased
                show={show}
                handleClose={handleClose}
                loading={loading}
                PackageData={PackageData}
                amount={Amount}
            />
            <div className="package-detail-section">
                <div className="button-arrow-back">
                    <Link to={`/dashboard/packages`}><ArrowLeftIcon /> <span>Back</span></Link>
                </div>
                {
                    loading ?
                        <div className="text-center">
                            <Spinner animation="border" role="status" className="full-screen-loading"></Spinner>
                        </div>
                        :
                        <>
                            <div className="row gy-3">
                                <div className="col-md-12">
                                    <div className="package-detail-card">
                                        <h3 className="text-white fs-4 text-center my-3 fw-bold">{PackageData.Name}</h3>
                                        <div className="position-relative">

                                            <div className="image">
                                                <img src={config.root + "Images/Packages/" + PackageData.Image} alt={Name} className="img-fluid" />
                                            </div>
                                            <div className={`package-icon`} style={{ top: 'initial', bottom: '15px' }}>
                                                <span>{PackageData.ROI}<small>%</small> </span>
                                                <label>Daily</label>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <svg width="314" height="3" viewBox="0 0 314 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0.5" width="313" height="3" fill="url(#paint0_linear_175_517)" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_175_517" x1="0.5" y1="1.5" x2="313.5" y2="1.5" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#FFBE18" stopOpacity="0" />
                                                        <stop offset="0.5" stopColor="#FFBE18" />
                                                        <stop offset="1" stopColor="#FFBE18" stopOpacity="0" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className="content">
                                            <div className="package-header">
                                                <h4>{Name}</h4>
                                            </div>
                                            <div className="detail">
                                                <span>${PackageData.MinAmount} - ${PackageData.MaxAmount}</span>
                                                <p>
                                                    {PackageData.Details}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="my-3 form-default">
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={Amount}
                                                min={PackageData.MinAmount}
                                                max={PackageData.MaxAmount}
                                                onBlur={(e) => {
                                                    const inputValue = parseInt(e.target.value);
                                                    if (isNaN(inputValue) || inputValue < PackageData.MinAmount || inputValue > PackageData.MaxAmount) {
                                                        toast.error(`Amount should be between $${PackageData.MinAmount} and $${PackageData.MaxAmount}`, {
                                                            position: toast.POSITION.TOP_RIGHT,
                                                            autoClose: 1000
                                                        });
                                                        setAmount('');
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    setAmount(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="mb-5">
                                            <div className="calc-item pe-0">
                                                <div className="d-flex justify-content-between">
                                                    <p>Min Amount: <strong className='text-primary'>${PackageData.MinAmount}</strong></p>
                                                    <p>Max Amount: <strong className='text-primary'>${PackageData.MaxAmount}</strong></p>
                                                </div>
                                                <input
                                                    type="range"
                                                    value={Amount}
                                                    min={PackageData.MinAmount}
                                                    className="w-100 text-start"
                                                    max={PackageData.MaxAmount}
                                                    onChange={(e) => handleDuration(e)} />
                                            </div>
                                            <div className="calc-item pe-0">
                                                <div className="d-flex justify-content-between">
                                                    <p>Amount</p>
                                                    <p><strong>${NumberFormat(Amount)}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="package-footer text-center">
                                            <button className={`button button-3d-primary w-100`}
                                                disabled={PackageData.MinAmount > Amount || PackageData.MaxAmount < Amount}
                                                onClick={handleShow}>Initiate</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div >
        </>
    );
}

export default PackagesDetails; 