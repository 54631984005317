import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import { NumberFormat } from "../../Dashboard/General/Functions";
import TransactionMenu from "./TransactionMenu";
import BsPagination from "../BsPagination";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import LoginCheck from "../General/LoginCheck";

const Transactions = () => {


    const AccessKey =(localStorage.getItem("AccessKey"));
   const UserID =(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);

    // pagination 
    const [pageNo, setPageNo] = useState('1');
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {


        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Package/GetMemberPackageDetails',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                setLedger(response.data.ListofPackages);
                setTotalRecords(response.data.totalRecords);
                setLoading(false);

            })
            .catch(function (error) {
                // console.log(error);
            });



    }, [pageNo, pageSize]);


    const handlePageChange = (page) => {
        setPageNo(page);
    };


    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    let amount = 0;
                    if (item.Debit != 0) {
                        amount = item.Debit;
                    }
                    else {
                        amount = item.Credit;
                    }

                    return (
                        <tr key={index}>
                            <td data-label="Fund ID">{item.SecretID}</td>
                            <td data-label="Category">{item.Name}</td>
                            <td data-label="Purchase Date"> {moment(item.BuyingDate).format('DD/MM/YYYY')}</td>
                            <td data-label="Fund Amount">$ {item.PackagePrice == 0 ? "-" : NumberFormat(item.PackagePrice)}</td>
                            <td data-label="Profit Return">{item.Profit_Calculated == 0 ? "-" : "PKR" + NumberFormat(item.Profit_Calculated)}</td>
                            <td data-label="Status">{item.Status}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (

                <div className="else">
                    <FaceFrownIcon />
                    <h4>No Data Found</h4>
                </div>

            )
        }

    }

    return (
        <>
            <LoginCheck />
            <TransactionMenu />

            <div className="partner-table">
                <div className="table-wrap">
                    <table className="table">
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colSpan="8">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    renderLedger()
                            }


                        </tbody>
                    </table>
                    {
                        totalRecords > 10 &&
                        <BsPagination
                            length={listOfLedger.length}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                setPageNo(1)
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default Transactions