import React, { useEffect, useState } from 'react'
import Config from '../../../Config';
import axios from 'axios';
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { BellIcon, CheckIcon, FaceFrownIcon, XMarkIcon } from '@heroicons/react/24/outline';

const Notifications = () => {

    const UserID =(localStorage.getItem("ID"));
    const AccessKey =(localStorage.getItem("AccessKey"));
    const [isUpdated, setIsUpdated] = useState(false);
    const [ListofNotifcations, setListofNotifcations] = useState([]);
    const [unseenCount, setunseenCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const [loadingReadAll, setloadingReadAll] = useState(false);
    const [loadingDeleteAll, setloadingDeleteAll] = useState(false);

    const [isDeleted, setIsDeleted] = useState(false);
    const [isChanged, setIsChanged] = useState(0);

    useEffect(() => {
        setIsDeleted(false);
        setIsUpdated(false);
        const Params = {
            AccessKey: AccessKey,
            UserID: UserID,
        };



        var api_config = {
            method: 'post',
            url: Config.base_url + 'Notification/GetAllNotifications',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Params
        };

        axios(api_config)
            .then(function (response) {
               // console.log('get all',response.data);
                if (response.data.status_code == 1) {

                    setListofNotifcations(response.data.ListofNotifcations);
                    let list = response.data.ListofNotifcations;
                    const unseenCount = list.filter(notification => !notification.Is_Seen).length;
                    setunseenCount(unseenCount);
                    document.title = `${unseenCount > 0 ? ('(' + unseenCount + ')') : ''} BI.AI Partners`;
                }
                else {
                    document.title = "BI.AI Partners";
                    setunseenCount(0);
                    setListofNotifcations([]);
                }

            })
            .catch(function (error) {
                // console.log(error);
            });
    }, [isUpdated, isDeleted]);



    const handleDeleteAll = () => {

        setloadingDeleteAll(true);
        const Params = {
            AccessKey: AccessKey,
            UserID: UserID,
        };

        //// console.log(Params);

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Notification/DeleteAllNotifications',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Params
        };

        axios(api_config)
            .then(function (response) {
                //// console.log(response.data);
                if (response.data.status_code == 1) {
                    setIsUpdated(true);
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setloadingDeleteAll(false);
                }

            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    const handleReadAll = () => {

        setloadingReadAll(true);
        const Params = {
            AccessKey: AccessKey,
            UserID: UserID,
        };

        //// console.log(Params);

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Notification/ReadAllNotifications',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Params
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setIsUpdated(true);
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setloadingReadAll(false);
                }

            })
            .catch(function (error) {
                // console.log(error);
            });
    }
    const MarkDelete = (NotificationID) => {
       // console.log(NotificationID, 'click');
       
        let status = isChanged;
        let newStatus = parseInt(status) + parseInt(1);
        setIsChanged(newStatus);
        setLoading(true);
        const Params = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: NotificationID
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Notification/DeleteNotification',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Params
        };

        axios(api_config)
            .then(function (response) {
               // console.log(response.data)
                if (response.data.status_code == 1) {
                    setIsDeleted(true);
                    setLoading(false);
                    setIsUpdated(true);
                    //let newarray = ListofNotifcations.filter(item => item.ID !== NotificationID);
                    //setListofNotifcations(newarray);
                   // console.log('if');
                }
                else {
                   // console.log('else');
                    setIsDeleted(false);
                }

            })
            .catch(function (error) {
                // console.log(error);
            });




    }
    const MarkRead = (NotificationID) => {


        let status = isChanged;
        let newStatus = parseInt(status) + parseInt(1);
        setIsChanged(newStatus);
        setLoading(true);
        const Params = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: NotificationID
        };

        var api_config = {
            method: 'post',
            //url: config.base_url + 'Notification/DeleteNotifications',
            url: Config.base_url + 'Notification/ReadNotification',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Params
        };

        axios(api_config)
            .then(function (response) {
                //   // console.log(response.data);
                if (response.data.status_code == 1) {
                    setIsDeleted(true);
                    setLoading(false);
                    setIsUpdated(true);
                    //let newarray = ListofNotifcations.filter(item => item.ID !== NotificationID);
                    //setListofNotifcations(newarray);
                }

            })
            .catch(function (error) {
                // console.log(error);
            });




    }
    const renderNotifications = () => {
        if (ListofNotifcations != null && ListofNotifcations.length > 0) {
            return (
                ListofNotifcations.map((item, index) => {
                    return (
                        <>
                            <li className={`${item.Is_Seen ? '' : 'active'}`} key={index} onClick={() => MarkRead(item.ID)}>
                                <div className={`indicator ${item.Is_Seen ? '' : 'active'}`}></div>
                                <div className='massage-content'>
                                    <h5>{item.Title}</h5>
                                    <p>{item.Detail}</p>
                                    <span>{moment(item.Created_Date).format("DD MMMM YYYY")}</span>
                                    <div className="divider"></div>
                                </div>
                                <XMarkIcon onClick={(e) => MarkDelete(item.ID)} />
                            </li>
                        </>
                    )
                })
            )
        }
        else {
            return (
                <div className="else-emoji w-100 mt-5">
                    <FaceFrownIcon />
                    <h4 className='text-white'>No Notifcation</h4>
                </div>
            );
        }
    }
    return (
        <>
            <Dropdown className='bs-notifications'>
                <Dropdown.Toggle>
                    <div>
                        <span className={`notifications-icon ${unseenCount === 0 ? '' : 'active'}`}></span>
                        <BellIcon />
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="notifications-wrap" onClick={(e) => e.stopPropagation()}>
                    <div className="noifications-content">
                        <div className="notifications_head">

                            <label htmlFor="">Notifications</label>
                            <ul>
                                <li>{
                                    loadingReadAll ?
                                        '...'
                                        :
                                        <>
                                            <div onClick={handleReadAll}>
                                                <CheckIcon />
                                                <span >Read All</span>
                                            </div>
                                        </>
                                }
                                </li>
                                <li>
                                    {
                                        loadingDeleteAll ?
                                            '...'
                                            :
                                            <>
                                                <div>
                                                    <span onClick={handleDeleteAll}>Clear All</span>
                                                    <XMarkIcon />
                                                </div>
                                            </>
                                    }
                                </li>
                            </ul>


                        </div>
                        <ul className='notification-list scrollbar w-100'>
                            {renderNotifications()}
                        </ul>
                    </div>

                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default Notifications