import React from 'react';
import { NavLink } from 'react-router-dom';

function ProfileMenu(props) {
    return (
        <>
            <div className="section-title mb-3">
                Settings
            </div>
            <div className='active-link-menu'>
                <ul className="nav justify-content-center">
                    <li className='nav-item'>
                        <NavLink className="nav-link" to="/dashboard/profile/account">
                            <span>
                                Profile
                            </span>
                        </NavLink>
                    </li>
                    <li className='nav-item'>
                        <NavLink className="nav-link" to="/dashboard/profile/security">
                            <span>
                                Security
                            </span>
                        </NavLink>
                    </li>
                    <li className='nav-item'>
                        <NavLink className="nav-link" to="/dashboard/profile-kyc">
                            <span>
                                KYC
                            </span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default ProfileMenu;