import React from 'react'

const Services = () => {
    return (
        <>
            <div className='simple-section'>
                <div className=''>
                    <h3>Terms and Conditions: </h3>
                    <ol className=''>
                        <li>

                            <h3>Acceptance of Terms:</h3>
                            By accessing or using any service or product provided by BUSINESSI.AI, you agree
                            to be bound by these Terms and Conditions.
                            <ul className='p-0'>
                                <li>
                                    Agreement to Terms: Upon accessing or using any of BUSINESSI.AI'
                                    services or products, users implicitly consent to the terms outlined herein.
                                    Continued usage indicates acceptance of these terms
                                </li>
                                <li>User Obligations: Users are responsible for regularly reviewing the Terms and
                                    Conditions for any updates or modifications. Failure to do so does not exempt
                                    users from being bound by the revised terms.
                                </li>
                            </ul>
                        </li>
                        <li>

                            <h3> Intellectual Property:
                            </h3>
                            All content provided by BUSINESSI.AI, including logos, trademarks, text, images, and
                            software, remains the property of BUSINESSI.AI and its licensors.



                            <ul className='p-0'>
                                <li>
                                    <b>Use of Intellectual Property:</b> Users are prohibited from using, reproducing,
                                    distributing, or modifying any content belonging to BUSINESSI.AI without
                                    explicit written permission.

                                </li>
                                <li><b>Enforcement of Intellectual Property Rights:</b> BUSINESSI.AI reserves
                                    the right to enforce its intellectual property rights through legal action against
                                    infringement or unauthorized usage.

                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3>
                                Services and Products:
                            </h3>
                            <p> BUSINESSI.AI offers a diverse range of services and products in multiple industries.</p>

                            <ul className='p-0'>
                                <li>
                                    <b>Service Modifications:</b> BUSINESSI.AI retains the right to alter or
                                    discontinue any service or product without prior notice. Updates or changes may
                                    affect service availability or features.
                                </li>
                                <li>
                                    <b>Availability Disclaimer:</b> The availability of services and products may vary
                                    based on geographical location or regulatory constraints.

                                </li>
                            </ul>
                        </li>
                        <li>

                            <h3> Use of Services:</h3>
                            <p>Users must meet specific criteria to access services, including legal age or parental
                                consent.
                            </p>

                            <ul className='p-0'>
                                <li>
                                    <b>Account Security: </b> Users are solely responsible for maintaining the
                                    confidentiality and security of their accounts.
                                </li>
                                <li>
                                    <b>Prohibited Activities: </b> A detailed list of prohibited activities is outlined,
                                    emphasizing unauthorized access, spamming, or any action violating local,
                                    regional, or international laws.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>
                                <h3>user Content:</h3>
                                <p>Users can submit content to BUSINESSI.AI' Services.
                                </p>
                            </b>
                            <ul className='p-0'>
                                <li>
                                    <b>Content Ownership:</b> Users retain ownership of their submitted content but
                                    grant BUSINESSI.AI specific rights to utilize it for service enhancement.

                                </li>
                                <li>
                                    <b>Content Guidelines:</b> Outlined guidelines ensure that user-generated content
                                    does not infringe upon third-party rights.

                                </li>
                                <li>
                                    <b>Content Moderation:</b> BUSINESSI.AI reserves the right to moderate or
                                    remove user-generated content that violates guidelines or legal standards.

                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>
                                <h3> Pricing and Payments:</h3>
                                <p>Pricing policies and payment terms for services and products offered by BUSINESSI.AI
                                     are provided in detail.
                                </p>
                            </b>
                            <ul className='p-0'>
                                <li>
                                    <b>Payment Procedures:</b> Specific payment methods, invoicing details, and
                                    applicable taxes are elaborated upon.
                                </li>
                                <li>
                                    <b>Price Adjustment Clause:</b> BUSINESSI.AI reserves the right to adjust
                                    prices due to market fluctuations or operational costs.

                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>
                                <h3> Privacy Policy:
                                </h3>
                                <p>Here are the main privacy policies of BUSINESSI.AI</p>
                            </b>
                            <ul className='p-0'>
                                <li>
                                    <b>Data Collection and Usage:</b> Further details on how personal data is
                                    collected, stored, and utilized by BUSINESSI.AI are provided in alignment with
                                    privacy laws.

                                </li>
                                <li>
                                    <b>Data Security Measures:</b> Elaboration on security protocols and measures
                                    implemented to safeguard user data.

                                </li>
                                <li>
                                    <b>User Rights:</b> Information regarding user rights regarding their personal data,
                                    including access, correction, or deletion.

                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>
                                <h3> Disclaimer of Warranties:
                                </h3>
                            </b>
                            <ul className='p-0'>
                                <li>
                                    <b>Service Representation:</b> BUSINESSI.AI specifies that services and
                                    products are provided "as is" without any expressed or implied warranties
                                    regarding their suitability, reliability, or accuracy.

                                </li>
                                <li>
                                    <b>Service Limitations:</b> Details on the limitations of services and products,
                                    including potential technical or functional constraints, are outlined.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>
                                <h3>
                                    Third-Party Links and External Content:
                                </h3>
                            </b>
                            <ul className='p-0'>
                                <li>
                                    <b>External Links Disclaimer:</b> BUSINESSI.AI' services or products may
                                    contain links to third-party websites, applications, or content for users'
                                    convenience. However, BUSINESSI.AI does not endorse or take responsibility
                                    for the content, accuracy, or practices of these external sources.

                                </li>
                                <li>
                                    <b>User Discretion Advised:</b> Users accessing third-party links or content from
                                    BUSINESSI.AI' platforms do so at their own risk and discretion. BUSINESSI.AI
                                     shall not be held liable for any damages or issues arising from
                                    interactions with third-party content.

                                </li>
                                <li>
                                    <b>External Content Policies:</b> Users engaging with third-party content should
                                    review and adhere to the terms and conditions, privacy policies, and practices of
                                    these external sources, as they may differ from BUSINESSI.AI' policies.

                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>
                                <h3>Limitation of Liability:
                                </h3>
                            </b>
                            <ul className='p-0'>
                                <li>
                                    <b>Indemnification:</b> Users agree to indemnify and hold harmless BUSINESSI.AI
                                     against any claims, liabilities, or damages arising from their use of
                                    services or products.
                                </li>
                                <li>
                                    <b>Liability Scope:</b> BUSINESSI.AI' liability is expressly limited to the extent
                                    permitted by applicable law, excluding any indirect, consequential, or punitive
                                    damages.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>
                                <h3>
                                    Governing Law:

                                </h3>
                            </b>
                            <ul className='p-0'>
                                <li>
                                    <b>Jurisdiction:</b> Clarification on the jurisdiction governing these Terms and
                                    Conditions and any legal disputes related to BUSINESSI.AI' services or
                                    products.

                                </li>
                                <li>
                                    <b>Dispute Resolution:</b> Details on procedures for resolving disputes, including
                                    arbitration or mediation clauses, if applicable.

                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>
                                <h3>
                                    Changes to Terms and Conditions:
                                </h3>
                            </b>
                            <ul className='p-0'>
                                <li>
                                    <b>Notification of Changes:</b> BUSINESSI.AI undertakes to notify users of any
                                    changes to the Terms and Conditions through prominent notifications on the
                                    platform or via registered email addresses.
                                </li>
                                <li>
                                    <b>Acceptance of Modifications:</b> Users' continued use of services
                                    post-modification signifies acceptance of the revised Terms and Conditions.

                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>
                                <h3>
                                    Modification Notification Preferences:
                                </h3>
                            </b>
                            <ul className='p-0'>
                                <li>
                                    <b>User Notification Preferences:</b> BUSINESSI.AI provides users with options
                                    to customize their notification preferences for any modifications or updates to the
                                    Terms and Conditions.

                                </li>
                                <li>
                                    <b>Notification Channels:</b> Users can choose their preferred communication
                                    channels (email, in-app notifications, or other specified means) to receive alerts
                                    regarding any changes made to the Terms and Conditions.
                                </li>
                                <li>
                                    <b>Frequency of Notifications:</b> Users have the flexibility to select the frequency
                                    of notifications, whether immediate, periodic summaries, or updates only upon
                                    significant alterations to the Terms and Conditions.
                                </li>
                                <li>
                                    <b>Opt-out Mechanism:</b> Users retain the right to opt-out of receiving notifications
                                    about changes to the Terms and Conditions, although continued usage will still
                                    be bound by any updates made.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>
                                <h3>Termination:
                                </h3>
                            </b>
                            <ul className='p-0'>
                                <li>
                                    <b>Termination Procedures:</b> Clarification on the procedures and grounds for
                                    terminating access to services, including violation of terms, inactivity, or unilateral
                                    termination by BUSINESSI.AI.
                                </li>
                                <li>
                                    <b>Effect of Termination:</b> Details regarding the consequences of termination on
                                    user access, ongoing agreements, or obligations.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>
                                <h3>
                                    Contact Information:
                                </h3>
                            </b>
                            <ul className='p-0'>
                                <li>
                                    <b>Communication Channels:</b> Clear channels of communication for users to
                                    address inquiries, complaints, or clarifications regarding the Terms and
                                    Conditions.

                                </li>
                                <li>
                                    <b>Response Time:</b> A commitment to respond to user inquiries within a specified
                                    time frame.

                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>
                                <h3>Additional Provisions:</h3>
                            </b>
                            <ul className='p-0'>
                                <li>

                                    <b>Severability:</b> A clause outlining that if any part of these Terms and Conditions is
                                    deemed unenforceable, the remaining provisions remain in effect.
                                </li>
                                <li>
                                    <b>Entire Agreement:</b> Stating that these Terms and Conditions constitute the
                                    entire agreement between users and BUSINESSI.AI, superseding any prior
                                    agreements or understandings.
                                </li>
                                <li>
                                    <b>Waiver:</b> Explicitly stating that failure to enforce any provision of these Terms and
                                    Conditions does not constitute a waiver of rights.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <b>To get other and more details about BUSINESSI.AI , Visit the following link: </b>
                    <a href="https://www.businessi.ai">https://www.businessi.ai</a>
                </div>
            </div>

        </>
    )
}

export default Services