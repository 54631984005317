import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import UserImg from "../../../assets/images/dashboard/user.svg";
import verification from "../../../assets/images/dashboard/verification-icon.svg";
import Accordion from 'react-bootstrap/Accordion';
import LoginCheck from '../General/LoginCheck';
import Config from '../../../Config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';


const KycSubmitted = () => {

  const AccessKey = (localStorage.getItem("AccessKey"));
  const UserID = (localStorage.getItem("ID"));
  const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");


  let navigate = useNavigate();



  useEffect(() => {
    // console.log(MemberID);


    setIsUpdated(false);
    setLoading(true);
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID
    };

    var api_config = {
      method: 'post',
      url: Config.base_url + 'MemberKYC/GetMemberKYC',
      headers: {
        'Content-Type': 'application/json'
      },
      data: dashboardParms
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setUser(response.data)
          setLoading(false);
        }
        else if (
          (response.data.status_message === "Invalid Access Key.")
      ) {
          navigate("/login");
      }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000
          });
        }


      })
      .catch(function (error) {
        toast.error('Network Error..', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000
          //onClose: () => window.alert('Called when I close')
        });
      });


  }, []);

  return (
    <>
      <ToastContainer />
      <LoginCheck />
      <div className="section-title mb-3">KYC (Know your client)</div>
      <div className="mobile-container">
        <div className="dash-bg-card kyc-submitted p-0">
          <div className="row">
            <div className="col-12">
              <div className='pink-bg'>
                <img src={UserImg} alt="" />
                <h5>KYC Submission Completed</h5>
                <p>
                  Your document is successfully submitted and is under review. It
                  may take 24-48 hours. Thank you for your patience.
                </p>
              </div>
              <div className="verification mt-3">
                <div className="content">
                  <h4>Verification is Under Review</h4>
                  {/* <span>Submit on 2/1/2024</span> */}
                </div>
                <div className="icon">
                  <img src={verification} alt="" />
                </div>
              </div>
              <div className="accordion-section mt-3">
                <Accordion className='border-free-accordion' defaultActiveKey={['0']} flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Documents Detail</Accordion.Header>
                    <Accordion.Body>
                      <ul className="opportunity-icon-list p-0">
                        <li className={`${user.DocumentType == "ID" ? '' : 'close'}`}>
                          <div className="info">
                            {user.DocumentType == "ID" ?
                              <CheckCircleIcon />
                              :
                              <XCircleIcon />
                            }
                            <p>National Identity Card</p>
                          </div>
                          <div className={`image ${user.DocumentType === "ID" ? '' : 'd-none'}`}>
                            {user.DocumentType === "ID" && (
                              <>
                                <div className="user-card">
                                  <div className="front">
                                    <img
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      src={`${Config.imge}/${user.IDFront}`}
                                      alt={user.DocumentType}
                                    />
                                  </div>
                                  <div className="back">
                                    <img
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      src={`${Config.imge}/${user.IDBack}`}
                                      alt={user.DocumentType}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </li>
                        <li className={`${user.DocumentType == "License" ? '' : 'close'}`}>
                          <div className="info">
                            {user.DocumentType == "License" ?
                              <CheckCircleIcon />
                              :
                              <XCircleIcon />
                            }

                            <p>Licence</p>
                          </div>
                          <div className={`image ${user.DocumentType === "License" ? '' : 'd-none'}`}>
                            {user.DocumentType === "License" && (
                              <>
                                <div className="user-card">
                                  <div className="front">
                                    <img
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      src={`${Config.imge}/${user.LicenseFront}`}
                                      alt={user.DocumentType}
                                    />
                                  </div>
                                  <div className="back">
                                    <img
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      src={`${Config.imge}/${user.LicenseBack}`}
                                      alt={user.DocumentType}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </li>
                        <li className={`${user.DocumentType == "Passport" ? '' : 'close'}`}>
                          <div className="info">
                            {user.DocumentType == "Passport" ?
                              <CheckCircleIcon />
                              :
                              <XCircleIcon />
                            }
                            <p>Passport</p>
                          </div>
                          <div className={`image ${user.DocumentType === "Passport" ? '' : 'd-none'}`}>
                            {user.DocumentType === "Passport" && (
                              <>
                                <div className="user-card">
                                  <div className="passport-image">
                                    <img
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      src={`${Config.imge}/${user.Passport}`}
                                      alt={user.DocumentType}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Profile Information</Accordion.Header>
                    <Accordion.Body>
                      <ul className='kyc-user-info'>
                        <li>
                          <span>First name</span>
                          <h5>{user.FirstName}</h5>
                        </li>
                        <li>
                          <span>Last name</span>
                          <h5>{user.LastName}</h5>
                        </li>
                        <li>
                          <span>KYC Type</span>
                          <h5>{user.KYCType == null ? 'Standard' : user.KYCType}</h5>
                        </li>
                        <li>
                          <span>Phone</span>
                          <h5>{user.MobileNo}</h5>
                        </li>
                        <li>
                          <span>Gender</span>
                          <h5>{user.Gender == null ? '---' : user.Gender}</h5>
                        </li>
                        <li>
                          <span>Date of Birth</span>
                          <h5>{moment(user.DateOfBirth).format(Config.date_format)}</h5>
                        </li>
                        <li>
                          <span>Nationality</span>
                          <h5>{user.BirthCountry}</h5>
                        </li>
                        <li>
                          <span>Residency</span>
                          <h5>Pakistan</h5>
                        </li>
                        <li>
                          <span>Occupation</span>
                          <h5>{user.Occupation == null ? '---' : user.Occupation}</h5>
                        </li>
                        <li>
                          <span>Email</span>
                          <h5>{Email}</h5>
                        </li>
                        <li>
                          <span>Address</span>
                          <h5>{user.Address == null ? '---' : user.Address}</h5>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>

              <div className="button-row">
                <Link to={`/dashboard/dashboard`} className='button button-3d-primary w-100 mt-3'>
                  Go to Home
                </Link>
                <Link to={`/dashboard/dashboard`} className='button button-3d-outline button-icon w-100 mt-3'>
                  <ArrowLeftIcon />
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default KycSubmitted