import React, { useEffect, useState, useRef } from 'react';
import QRCode from "react-qr-code";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import { NumberFormat } from "../../Dashboard/General/Functions";
import BsPagination from "../BsPagination";
import Config from "../../../Config";
import TransactionMenu from "./TransactionMenu";
import { ArrowDownTrayIcon, FaceFrownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import LoginCheck from "../General/LoginCheck";
import Logo from "../../../assets/images/logo/gif-logo.gif";
import html2canvas from "html2canvas";
import { Dropdown } from 'react-bootstrap';

import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";



function WithdrawalRequests(props) {

    const AccessKey = (localStorage.getItem("AccessKey"));
    const UserID = (localStorage.getItem("ID"));

    const [loading, setLoading] = useState(false);
    const [withdraw, setWithdraw] = useState([]);
    const [widthOld, setWithdrawOld] = useState([]);
    const [subTotal, setsubTotal] = useState(0);
    const [Total, setTotal] = useState(0);
    const [loadingRecordId, setLoadingRecordId] = useState(false);
    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState('10');
    const [totalRecords, setTotalRecords] = useState(0);
    const [IsCanceled, setIsCanceled] = useState(false);
    const [RequestID, setRequestID] = useState(null);
    const [withdrawByID, setwithdrawByID] = useState([]);

    const [loadinginvoice, setloadinginvoice] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const HostUrl = window.location.origin + `/dashboard/transactions/withdraw/img?id=${withdrawByID.PaymentID}`;


    useEffect(() => {

        setIsCanceled(false);
        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetWithdrawlList',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };
        // console.log(dashboardParms)
        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                setWithdraw(response.data.ListofWithdrawl);
                setWithdrawOld(response.data.ListofWithdrawl);
                setTotalRecords(response.data.totalRecords);
                setTotal(response.data.Total);
                setsubTotal(response.data.subTotal);
                setLoading(false);
            })
            .catch(function (error) {
                // console.log(error);
            });

    }, [pageNo, pageSize, IsCanceled]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };





    const renderWithdraw = () => {
        if (withdraw != "" && withdraw != null) {

            return (
                withdraw.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td data-label="TID">{item.PaymentID}</td>
                            <td data-label="Date">{moment(item.Request_Date).format("DD/MM/YYYY")}</td>
                            <td data-label="Amount">USD {NumberFormat(item.Amount)}</td>
                            <td data-label="Fee">USD {NumberFormat(item.FeeAmount)}</td>
                            <td data-label="Receivable Amount">{item.NetReturnableInCurrency}</td>
                            <td data-label="Withdrawal Mode">{item.PaymentMode}</td>
                            <td data-label="Status">
                                {item.Status === 'Initiated' ? (
                                    <div>
                                        <span>{item.Status}</span>
                                        <svg style={{ cursor: "pointer", marginLeft: "10px" }} onClick={(e) => CancelRequest(item.ID)} width="16" height="16" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.7308 0.64142C6.44082 0.56142 0.470825 6.53142 0.390825 13.6314C0.350825 20.9214 6.32082 26.9314 13.4608 26.8914C20.6808 27.0014 26.5708 21.0414 26.6408 13.9314C26.7608 6.64142 20.8008 0.76142 13.7308 0.64142ZM19.8808 20.1314C19.3508 20.6614 18.5108 20.6614 17.9808 20.1714L13.5708 15.8414L9.24082 20.2814C9.01083 20.5514 8.63083 20.7014 8.29083 20.7014C7.95083 20.7014 7.64082 20.5514 7.38082 20.3214C6.85082 19.7914 6.85082 18.9514 7.34082 18.4214L11.6308 13.9814L7.22082 9.69142C6.95082 9.42142 6.80082 9.08142 6.80082 8.74142C6.80082 8.40142 6.95082 8.06142 7.18082 7.83142C7.71082 7.30142 8.55083 7.26142 9.08082 7.79142L13.5008 12.0814L17.7908 7.67142C18.3208 7.14142 19.1608 7.10142 19.6908 7.63142C20.2208 8.16142 20.2208 9.00142 19.7308 9.53142L15.4408 13.9414L19.8508 18.2314C20.3808 18.7614 20.3808 19.6014 19.8808 20.1314Z" fill="#D7190E" />
                                        </svg>

                                    </div>
                                ) : (
                                    <span>{item.Status}</span>
                                )}
                            </td>
                            <td data-label="Invoice">
                                {
                                    item.Status == "Success" &&
                                    <>
                                        {
                                            loadingRecordId == item.PaymentID ?
                                                <Spinner animation="border" size="sm" />
                                                :
                                                <ArrowDownTrayIcon onClick={(ID) => handleViewInvoice(item.PaymentID)} className='download' />
                                            // <a onClick={(e) => handleDownload(item.PaymentID)} style={{ cursor: "pointer" }} className='button bg-transparent p-0'>
                                            //     <span style={{ fontSize: "30px" }} className="material-symbols-outlined">picture_as_pdf</span>
                                            // </a>

                                        }

                                    </>

                                }
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="8">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }
    }



    const CancelRequest = (ID) => {


        const result = window.confirm('Are you sure you want to cancel?');

        if (result) {
            setRequestID(ID);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                ID: ID
            };



            var api_config = {
                method: 'post',
                url: Config.base_url + 'Members/CancelWithdrawlRequest',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {
                    //// console.log(response.data);
                    setIsCanceled(true);

                })
                .catch(function (error) {
                    // console.log(error);
                });
        }
    }

    const handleViewInvoice = (ID) => {
        setShow(true);
        setLoadingRecordId(true);
        setloadinginvoice(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            Payment_ID: ID,

        };
        // console.log(data)

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetWithdrawlByTransactionID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setwithdrawByID(response.data)
                    setLoadingRecordId(false);
                    setloadinginvoice(false);
                }


            })
            .catch(function (error) {
                // console.log(error);
            });


    }

    const InvoiceRef = useRef();

    const handleCapture = () => {
        if (InvoiceRef.current !== null) {
            html2canvas(InvoiceRef.current).then((canvas) => {
                const imgData = canvas.toDataURL("image/jpeg");

                // Create a link element
                const link = document.createElement("a");
                link.href = imgData;
                link.download = "Withdraw Invoice.jpg";

                // Append the link to the body and trigger the download
                document.body.appendChild(link);
                link.click();

                // Remove the link from the body
                document.body.removeChild(link);
            });
        }
    };


    return (
        <>
            <LoginCheck />
            <TransactionMenu />
            <div className="partner-table">
                <div className="table-wrap">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>TID</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Fee</th>
                                <th>Receivable Amount</th>
                                <th>Withdrawal Mode</th>
                                <th>Status</th>
                                <th>Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colSpan="8">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    <>
                                        {renderWithdraw()}
                                        {
                                            withdraw != null && withdraw.length > 0 &&
                                            <tr className="table-total">
                                                <td></td>
                                                <td className="text-end"><strong>Total:</strong></td>
                                                <td><strong>USD {NumberFormat(Total)}</strong></td>
                                                <td colSpan={5}>
                                                </td>
                                            </tr>
                                        }
                                    </>
                            }

                        </tbody>
                    </table>
                    {
                        totalRecords > 10 &&
                        <BsPagination
                            length={withdraw.length}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                setPageNo(1)
                            }}
                        />
                    }
                </div>
            </div>
            <Modal centered show={show} onHide={handleClose} className="modal-theme transparent">
                <Modal.Header closeButton>
                    <Modal.Title>Payout Invoice</Modal.Title>
                    <button className="btn-close" onClick={handleClose}>
                        <XMarkIcon />
                    </button>
                </Modal.Header>
                <Modal.Body>

                    {
                        loadinginvoice ?
                            <Spinner animation="border" size="sm" />
                            :
                            <div className="invoice-page" ref={InvoiceRef}>
                                <div className="subpage">
                                    <div className="invoice-top">
                                        <img src={Logo} style={{width:'150px'}}/>
                                        <div className="title">Withdraw Invoice</div>
                                        <span>{moment(withdrawByID.Dated).format('DD/MM/YYYY - HH:mma')}</span>
                                        <div className="qr-code-wrap">
                                            <QRCode
                                                size={256}
                                                value={`${HostUrl}`}
                                                viewBox={`0 0 256 256`}
                                            />
                                        </div>
                                    </div>
                                    <div className="invoice-content">
                                        <ul>
                                            <li>
                                                <label htmlFor="">Beneficiary</label>
                                                <span>{withdrawByID.To}</span>
                                            </li>

                                            <li>
                                                <label htmlFor="">Wallet Type</label>
                                                <span>BI.AI Wallet</span>
                                            </li>
                                            <li>
                                                <label htmlFor="">Payment Mode</label>
                                                <span>{withdrawByID.PaymentMode}</span>
                                            </li>
                                            <li>
                                                <label htmlFor="">Address / Account No.</label>
                                                <span>
                                                    {withdrawByID.WalletAddress && withdrawByID.WalletAddress.length > 15
                                                        ? "****" + withdrawByID.WalletAddress.substring(withdrawByID.WalletAddress.length - 15)
                                                        : withdrawByID.WalletAddress}
                                                </span>


                                            </li>
                                            <li>
                                                <label htmlFor="">TID</label>
                                                <span>{withdrawByID.PaymentID}</span>
                                            </li>
                                            <li>
                                                <label>Amount</label>
                                                <span>$ {withdrawByID.Amount}</span>
                                            </li>
                                            <li>
                                                <label>Fee</label>
                                                <span>$ {withdrawByID.Fee}</span>
                                            </li>
                                            <li>
                                                <label>Exchange Rate</label>
                                                <span>{withdrawByID.ExchangeRate} {withdrawByID.ReceiveableCurrency}</span>
                                            </li>
                                            <li>
                                                <label>Receivable Amount</label>
                                                <span>{withdrawByID.ReceiveableAmount} {withdrawByID.ReceiveableCurrency}</span>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                    }

                    <div className="invoice-footer black button-row">


                        <Dropdown className='w-100'>
                            <Dropdown.Toggle variant="success" className='button button-light w-100' id="dropdown-basic">
                                Share
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/">
                                    <EmailShareButton url={HostUrl}>
                                        Email
                                    </EmailShareButton>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/">
                                    <FacebookShareButton url={HostUrl}>
                                        Facebook
                                    </FacebookShareButton>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/">
                                    <TwitterShareButton url={HostUrl}>
                                        Twitter
                                    </TwitterShareButton>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/">
                                    <WhatsappShareButton url={HostUrl}>
                                        Whatsapp
                                    </WhatsappShareButton>
                                </Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>

                        <button className='button button-3d-primary w-100' onClick={handleCapture}>
                            Save to Gallery
                        </button>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
}

export default WithdrawalRequests;