import React, { useState, useEffect } from "react";
import TransactionMenu from './TransactionMenu';
import axios from "axios";
import config from "../../../Config";
import BsPagination from "../BsPagination";
import LoginCheck from "../General/LoginCheck";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { Spinner } from "react-bootstrap";

function L2DirectReward(props) {

    const AccessKey =(localStorage.getItem("AccessKey"));
   const UserID =(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [listOfTransactions, setlistOfTransactions] = useState([]);


    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        document.title = "ROI Self Transaction";


        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            LevelNo: 2

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Roi/GetDirectIncomeByLevel',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //// console.log("response", response.data);
                setlistOfTransactions(response.data.ListofLedger);
                setTotalRecords(response.data.totalRecords);
                setLoading(false);

            })
            .catch(function (error) {
                //// console.log(error);
            });



    }, [pageNo, pageSize]);



    const renderLedger = () => {

        if (listOfTransactions != null && listOfTransactions.length > 0) {
            return (
                listOfTransactions.map((item, index) => {

                    return (
                        <tr key={index}>
                            <td data-label="SR.">
                                {index++ + pageSize * (pageNo - 1) + 1}
                            </td>
                            <td data-label="Date"> {moment(item.RewardDate).format('DD/MM/YYYY')}</td>
                            <td data-label="Crew Name">{item.Full_Name}</td>
                            <td data-label="Email Address">{item.Email_Address}</td>
                            <td data-label="Funding Amount">$ {item.PackageAmount}</td>
                            <td data-label="Reward Amount">$ {item.Debit}</td>
                            <td data-label="From Level No">{item.LevelNumber}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td className="else" colSpan="6">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </td>
                </tr>
            )
        }

    }


    return (
        <>

            <LoginCheck />
            <TransactionMenu />
            <div className="partner-table">
                <div className="table-wrap">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>SR</th>
                                <th>Dated</th>
                                <th>Name</th>
                                <th>Email Address</th>
                                <th>Trading Amount</th>
                                <th>Profit</th>
                                <th>Level No</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td className="text-center" colSpan="8">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                    :
                                    renderLedger()
                            }


                        </tbody>
                    </table>
                    {
                        totalRecords > 10 &&

                        <BsPagination
                            length={listOfTransactions.length}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={(page) => setPageNo(page)}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                setPageNo(1)
                            }}
                        />
                    }
                </div>
            </div>
        </>
    );
}

export default L2DirectReward;