import React, { useState } from 'react';
import SupportImage from '../../assets/images/support.png'
import { Link } from 'react-router-dom';
const AssistiveButton = () => {
  const [position, setPosition] = useState({ x: 330, y: 72 });
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const startDrag = (e) => {
    setDragging(true);
    const event = e.type === 'touchstart' ? e.touches[0] : e;
    setOffset({
      x: event.clientX - position.x,
      y: event.clientY - position.y,
    });
  };

  const onDrag = (e) => {
    if (dragging) {
      const event = e.type === 'touchmove' ? e.touches[0] : e;
      setPosition({
        x: event.clientX - offset.x,
        y: event.clientY - offset.y,
      });
    }
  };

  const endDrag = () => {
    setDragging(false);
  };


  return (
    <div>
      <Link to={`/dashboard/support`}
        className="assistive-button"
        onMouseDown={startDrag}
        onMouseMove={onDrag}
        onMouseUp={endDrag}
        onMouseLeave={endDrag}
        onTouchStart={startDrag}
        onTouchMove={onDrag}
        onTouchEnd={endDrag}
        style={{ top: position.y, left: position.x }}
      >
        <img src={SupportImage} alt="Service Girl" />
      </Link>
    </div>
  );
};

export default AssistiveButton;
