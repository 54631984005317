import React from "react";
import Spinner from 'react-bootstrap/Spinner';
import logo from "../../../assets/images/logo/gif-logo.gif";


function Loader(props) {
    return (
        <div className={`placehoder-loader ${props.color == "white" ? 'bg-white' : ''} ${props.fullpage ? 'fullpage' : ''}`}>
            {
                props.logo === true &&
                <>
                    <img src={logo} className="mb-5" />
                </>
            }
            <Spinner animation="border" variant={props.variant} />
        </div>
    );
}

export default Loader;