import React, { useState, useEffect, useRef } from "react"
import { FaceFrownIcon, UserGroupIcon, UserIcon } from "@heroicons/react/24/outline"
import { NavLink, useNavigate } from "react-router-dom";
import Config from "../../../Config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import LoginCheck from "../General/LoginCheck";
import clock from "../../../assets/images/animation/clock.json";
import { Player } from '@lordicon/react';
import ServerClock from "../../Dashboard/UserControls/ServerClock";
import moment from "moment";
import Countdown from "../../Common/Countdown";
import { Form, Spinner } from "react-bootstrap";

const CollectProfit = (props) => {

    let navigate = useNavigate();

    const AccessKey = (localStorage.getItem("AccessKey"));
    const UserID = (localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [Collectloading, setCollectloading] = useState(false);
    const [CanTransfer, setCanTransfer] = useState(false);
    const [Data, setData] = useState({});

    const [isDisabled, setIsDisabled] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [Amount, setAmount] = useState(0);

    const [serverTime, setServerTime] = useState(null);
    const [autoCollect, setautoCollect] = useState(false);


    const [startTransferTime, setStartTransferTime] = useState(null);
    const [endTransferTime, setEndTransferTime] = useState(null);

    const [TimeType, setTimeType] = useState(null);
    const [Status, setStatus] = useState('');

    const [AutoCollectSelf, setAutoCollectSelf] = useState(null);

    const playerRef = useRef();
    const coinsRef = useRef();
    useEffect(() => {
        if (!loading) {
            playerRef.current?.playFromBeginning();
            coinsRef.current?.playFromBeginning();
        }
    }, [loading])


    useEffect(() => {
        setLoading(true);
        const Parms = {
            AccessKey: AccessKey,
            UserID: UserID,
        };

        const api_config = {
            method: 'post',
            url: Config.base_url + 'Roi/GetSelfROIforTransfer',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Parms
        };

        axios(api_config)
            .then(function (response) {
                // console.log('GetSelfROIforTransfer', response.data);
                if (response.data.status_code === 1) {
                    setCanTransfer(response.data.CanTransfer);
                    setData(response.data);
                    setAmount(response.data.totalAmount);
                    setServerTime(response.data.ServerTime);
                    setStartTransferTime(response.data.startTransferTime);
                    setEndTransferTime(response.data.endTransferTime);

                    setAutoCollectSelf(response.data.AutoCollectSelf);

                    if (response.data.status_message == "User Can Transfer Profit") {
                        setStatus('transfer');
                    }
                    if (response.data.status_message == "Time Finished for Trasnfer") {
                        setStatus('finished');
                    }
                    if (response.data.status_message == "Unfortunately you did not collected your today's profit on time. Please collect tomorrow!") {
                        setStatus('missed');
                    }
                    if (response.data.status_message == "Waiting for Time to Transfer") {
                        setStatus('waiting');
                    }
                    if (response.data.status_message == "No Profit Available for Transfer") {
                        setStatus('notransfer');
                    }

                    setLoading(false);
                }
                else if (
                    (response.data.status_message === "Invalid Access Key.")
                ) {
                    navigate("/login");
                }
                else {
                    setLoading(false);
                }

            })
            .catch(function (error) {
                // console.log(error);
                setLoading(false);
            });

    }, [Status]);


    useEffect(() => {
        const interval = setInterval(() => {
            setServerTime(prevTime => moment.tz(prevTime, 'America/New_York').add(1, 'second').format());
        }, 1000);


        return () => clearInterval(interval);


    }, []);


    useEffect(() => {

        if (serverTime && startTransferTime && endTransferTime) {
            const serverTimeMoment = moment.tz(serverTime, 'America/New_York');
            const startTransferTimeMoment = moment.tz(startTransferTime, 'America/New_York');
            const endTransferTimeMoment = moment.tz(endTransferTime, 'America/New_York');

            if (serverTimeMoment.isBefore(startTransferTimeMoment)) {
                setTimeType("Remaining");
                setShowTimer(false);
                // console.log("ServerTime is not within the transfer time range.");
                setStatus('waiting');
            }
            else if (serverTimeMoment.isAfter(startTransferTimeMoment) && serverTimeMoment.isBefore(endTransferTimeMoment)) {
                setTimeType("30min");
                setShowTimer(true);
                setIsDisabled(false);
                setStatus('transfer');
                // console.log("ServerTime is within the transfer time range.");
            }
            else {
                setTimeType(null);
            }
        }

    }, [serverTime, startTransferTime, endTransferTime]);




    const handleCollect = () => {
        setCollectloading(true);
        const Parms = {
            AccessKey: AccessKey,
            UserID: UserID,

        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Roi/TransferSelfROIintoCash',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Parms
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setTimeout(() => {
                        navigate(`/dashboard/collect-profit/success`);
                    }, 2000);
                    setCollectloading(false);
                }
                else if (
                    (response.data.status_message === "Invalid Access Key.")
                ) {
                    navigate("/login");
                }
                else {

                }
            })
            .catch(function (error) {
                // console.log(error);
            });

    }
    const handleAutoCollect = (e) => {
        let isChecked = e.target.checked;


        setautoCollect(isChecked);
        setAutoCollectSelf(isChecked)


        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            AutoCollectSelf: isChecked,
            MemberType: "Website"
        };

        // console.log(data);

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Roi/AutomateSelfRoi',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code === 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } else if (response.data.status_message === "Invalid Access Key.") {
                    navigate("/login");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };


    return (
        <>

            <LoginCheck />
            <ToastContainer />
            <div className="section-title mb-3">Collect Share</div>
            <ul className="nav-menu">
                <li>
                    <NavLink to="/dashboard/collect-profit">
                        <UserIcon />
                        Self
                    </NavLink>
                </li>
                <li className="divider">
                    <svg width="2" height="39" viewBox="0 0 2 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="39" width="39" height="1" transform="rotate(-90 0.5 39)" fill="url(#paint0_linear_380_842)" />
                        <defs>
                            <linearGradient id="paint0_linear_380_842" x1="0.5" y1="39.5" x2="39.5" y2="39.5" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFBE18" stopOpacity="0" />
                                <stop offset="0.5" stopColor="#FFBE18" />
                                <stop offset="1" stopColor="#FFBE18" stopOpacity="0" />
                            </linearGradient>
                        </defs>
                    </svg>

                </li>
                <li>
                    <NavLink to="/dashboard/collect-team-profit">
                        <UserGroupIcon />
                        Crew
                    </NavLink>
                </li>
            </ul>

            <div className="mobile-container">

                <div className="auto-collect">

                <h6>Take advantage of our exclusive <span>$5</span> Auto Collection offer and double your collection time.</h6>
                    {
                        loading ?
                            "Loading..."
                            :

                            <div className="radio-onoff">
                                <input type="checkbox" id="switch" checked={AutoCollectSelf} onChange={handleAutoCollect} />
                                <label for="switch"></label>
                            </div>
                    }

                </div>
            </div>

            <div className={`mobile-container`}>
                {
                    Amount > 0 &&
                    <div className="server-time">
                        <p>Server time</p>
                        <h4>
                            <ServerClock />
                        </h4>
                    </div>
                }


                {loading
                    ?
                    <div className="text-center">
                        <Spinner animation="border" role="status" className="full-screen-loading"></Spinner>
                    </div>
                    :

                    <>
                        <div className="advertisement">
                            Advertisement
                        </div>
                        {
                            Status == 'notransfer' &&
                            <>

                                <div className="text-center mt-4">
                                    <h4 className="text-white">No Self Share to Collect</h4>
                                    <p>
                                        It looks like there are no shares available for collection at this time. Check back soon to see your earnings grow!
                                    </p>
                                </div>

                            </>
                        }
                        {Status == 'waiting' &&
                            <>

                                <p className="text-center mt-2" style={{ fontSize: 13 }}>
                                    You can collect your share only within the specified time frame between the start and end times.
                                </p>

                                <div className="timer-box">
                                    <div className="time-item">
                                        <p>Start Time</p>
                                        <h4>{moment(Data.startTransferTime).format("h:mm a")}</h4>
                                    </div>
                                    <div>


                                        <Player
                                            size={100}
                                            ref={playerRef}
                                            icon={clock}
                                            onComplete={() => playerRef.current?.playFromBeginning()}
                                        />
                                    </div>
                                    <div className="time-item text-end">
                                        <p>End Time</p>
                                        <h4 className="red">{moment(Data.endTransferTime).format("h:mm a")}</h4>
                                    </div>
                                </div>

                                {
                                    TimeType != null && TimeType != 'Remaining' &&
                                    <>
                                        <h6 className="text-center my-3 text-primary">Remaining Time</h6>
                                        <div className="dash-countdown">
                                            <Countdown
                                                days={`d-none`}
                                                hours={`d-none`}
                                                class={`mt-3 m-auto short-timer countdown-timer  ${isDisabled ? '' : ''}`}
                                                EndDate={TimeType == "30min" ? endTransferTime : Data.startTransferTime} />
                                        </div>
                                    </>
                                }

                                <div className="balance-card theme-card bg-safe py-3 mt-4 position-relative overflow-hidden">

                                    <div className="balance">
                                        <p>Total Share</p>
                                        <h2>$ {Amount}</h2>
                                    </div>

                                    <div className="text-center my-3" style={{ zIndex: 4 }}>
                                        <p className="text-secondary">
                                            Waiting for Time to Transfer
                                        </p>
                                    </div>
                                </div>
                            </>
                        }
                        {Status == 'transfer' &&
                            <>
                                <p className="text-center mt-2">
                                    You can collect your share only within the specified time frame between the start and end times.
                                </p>
                                <div className="timer-box">
                                    <div className="time-item">
                                        <p>Start Time</p>
                                        <h4>{moment(Data.startTransferTime).format("h:mm a")}</h4>
                                    </div>
                                    <div>


                                        <Player
                                            size={100}
                                            ref={playerRef}
                                            icon={clock}
                                            onComplete={() => playerRef.current?.playFromBeginning()}
                                        />
                                    </div>
                                    <div className="time-item text-end">
                                        <p>End Time</p>
                                        <h4 className="red">{moment(Data.endTransferTime).format("h:mm a")}</h4>
                                    </div>
                                </div>

                                {
                                    TimeType != null && TimeType != 'Remaining' &&
                                    <>
                                        <h6 className="text-center my-3 text-primary">Remaining Time</h6>
                                        <div className="dash-countdown">
                                            <Countdown
                                                days={`d-none`}
                                                hours={`d-none`}
                                                class={`mt-3 m-auto short-timer countdown-timer  ${isDisabled ? '' : ''}`}
                                                EndDate={TimeType == "30min" ? endTransferTime : Data.startTransferTime} />
                                        </div>
                                    </>
                                }

                                <div className="balance-card theme-card bg-safe py-3 mt-4 position-relative overflow-hidden">
                                    <div className="balance">
                                        <p>Total Share</p>
                                        <h2>$ {Amount}</h2>
                                    </div>

                                    <div className="text-center my-3" style={{ zIndex: 4 }}>
                                        <button onClick={handleCollect} className={`button-3d-primary  ${isDisabled ? 'disabled' : ''}`} disabled={isDisabled}>Collect Share {isDisabled ? '...' : ''}</button>
                                    </div>
                                </div>
                            </>
                        }
                        {Status == 'missed' &&
                            <>

                                <div className="timer-box mt-3">
                                    <div className="time-item">
                                        <p>Start Time</p>
                                        <h4>{moment(Data.startTransferTime).format("h:mm a")}</h4>
                                    </div>
                                    <div>
                                        <Player
                                            size={100}
                                            ref={playerRef}
                                            icon={clock}
                                            onComplete={() => playerRef.current?.playFromBeginning()}
                                        />
                                    </div>
                                    <div className="time-item text-end">
                                        <p>End Time</p>
                                        <h4 className="red">{moment(Data.endTransferTime).format("h:mm a")}</h4>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <FaceFrownIcon className="text-primary w-25 text-center mt-3" />
                                    <h4 className="text-white">Oops, You Missed It!</h4>
                                    <p className="">
                                        Unfortunately, you didn't collect your profit for today in time. Please make sure to collect it tomorrow!
                                    </p>

                                </div>
                            </>
                        }
                    </>
                }
            </div >

        </>
    )
}

export default CollectProfit