import { CheckIcon } from "@heroicons/react/24/outline";
import React from "react";

function AddMoneyWizard(props) {
    return (
        <>
            <ul className="wizard-tabs tabs-deposit">
                <li className={props.active1 ? 'active' : ''}>
                    <div className="wizard-number-wrap">
                        <span>{props.active1 ? <CheckIcon /> : '1'}</span>
                    </div>
                    <p>Amount</p>
                    <div className="line"></div>
                </li>
                <li className={props.active2 ? 'active' : ''}>
                    <div className="wizard-number-wrap">
                        <span>{props.active2 ? <CheckIcon /> : '2'}</span>
                    </div>
                    <p>Mode</p>
                    <div className="line"></div>
                </li>
                <li className={props.active3 ? 'active' : ''}>
                    <div className="wizard-number-wrap">
                        <span>{props.active3 ? <CheckIcon /> : '3'}</span>
                    </div>
                    <p>Address & Deposit</p>
                    <div className="line"></div>
                </li>
                <li className={props.active4 ? 'active' : ''}>
                    <div className="wizard-number-wrap">
                        <span>{props.active4 ? <CheckIcon /> : '4'}</span>
                    </div>
                    {/* <p>{props.label4}</p> */}
                    <p>Success</p>
                </li>
            </ul>
        </>
    );
}

export default AddMoneyWizard;