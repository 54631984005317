import React, { useEffect, useState } from "react";
import config from "../../../Config";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import LoginCheck from "../General/LoginCheck";
import { Spinner } from "react-bootstrap";

function BusinessPackages(props) {

    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    // packages
    const [Loading, setLoading] = useState(false);

    const [ListofPackages, setListofPackages] = useState([]);

    // loading states
    let navigate = useNavigate();


    useEffect(() => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID
        };
        // console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Package/GetAllPackagesforPurchase',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                // console.log("GetAllPackages", response.data);
                if (response.data.status_code == 1) {
                    setListofPackages(response.data.ListofPackages);
                    setLoading(false);
                }
                else if (
                    (response.data.status_message === "Invalid Access Key.")
                ) {
                    navigate("/login");
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000
                    });
                    setLoading(false);
                }

            })
            .catch(function (error) {

            })

    }, []);





    return (
        <>
            <LoginCheck />
            <ToastContainer />
            <div className="button-row justify-content-between px-4">
                <h2 className="section-title">
                    Research Funds
                </h2>
                <div className="button-arrow-back">
                    <Link to={`/dashboard/packages/my-packages`}>MY Funds</Link>
                </div>
            </div>
            {Loading ?
                <Spinner animation="border" role="status" className="full-screen-loading">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                :
                <ul className="responsive-package-list mt-4">
                    {ListofPackages.map((item, index) =>
                        <li key={index}>
                            <Link
                                to={`/dashboard/packages/view/${item.ID}`}
                                onClick={() => window.scrollTo(0, 0)}
                            >
                                <div className={`package-card ${item.ID === 1 ? 'primary' : item.ID === 2 ? 'secondary' : item.ID === 3 ? 'blue' : item.ID === 4 ? 'red' : 'blue'}`} style={{ backgroundImage: `url(${config.root + "Images/Packages/" + item.Image})` }}>
                                    <div className="content">
                                        <div className="package-header">
                                            <h4>{item.Name}</h4>
                                        </div>
                                        <div className="detail">
                                            <span className={`price ${item.ID === 1 ? 'primary' : item.ID === 2 ? 'secondary-text' : item.ID === 3 ? 'blue-text' : item.ID === 4 ? 'red-text' : 'blue-text'}`}>${item.MinAmount} - ${item.MaxAmount}</span>
                                        </div>
                                        <div className={`package-icon ${item.ID === 1 ? 'icon-primary' : item.ID === 2 ? 'icon-secondary' : item.ID === 3 ? 'icon-blue' : item.ID === 4 ? 'icon-red' : 'icon-blue'}`}>
                                            <span>{item.ROI}<small>%</small></span>
                                            <label>Daily</label>
                                        </div>

                                        <h5 className="text-white mt-3 mb-1 fs-5">Direct Reward</h5>
                                        <ul className="list">

                                            <li>
                                                <label>Tier 1:</label>
                                                <span>{item.DirectL1Commission}%</span>
                                            </li>
                                            <li>
                                                <label>Tier 2:</label>
                                                <span>{item.DirectL2Commission}%</span>
                                            </li>
                                            <li>
                                                <label>Tier 3:</label>
                                                <span>{item.DirectL3Commission}%</span>
                                            </li>
                                        </ul>
                                        <h5 className="text-white mt-3 mb-1 fs-5">Crew Reward</h5>
                                        <ul className="list">
                                            <li>
                                                <label>Tier 1:</label>
                                                <span>{item.TeamL1Commission}%</span>
                                            </li>
                                            <li>
                                                <label>Tier 2:</label>
                                                <span>{item.TeamL2Commission}%</span>
                                            </li>
                                            <li>
                                                <label>Tier 3:</label>
                                                <span>{item.TeamL3Commission}%</span>
                                            </li>
                                        </ul>
                                        <div className="package-footer">
                                            <div className={`button ${item.ID === 1 ? 'button-3d-primary' : item.ID === 2 ? 'button-3d-secondary' : item.ID === 3 ? 'button-3d-primary button-blue' : item.ID === 4 ? 'button-3d-primary button-red' : 'button-3d-primary button-blue'}`}>Initiate</div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    )}
                </ul>
            }
        </>
    );
}

export default BusinessPackages;