import React, { useState } from "react";
import Logo from '../../assets/images/logo/gif-logo.gif';
import dividerH from '../../assets/images/divider-h.svg';
import config from "../../Config";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ArrowLeftEndOnRectangleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";

function EmailNotVerified(props) {


    const [UserName1, setUserName1] = useState(localStorage.getItem("User_Name"));
    const [loading, setLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);




    const ResendEmail = () => {
        const data = {

            UserName: UserName1,

        };

        //// console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/SendSignUpEmail',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        //// console.log(data);
        axios(api_config)
            .then(function (response) {

                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    setIsButtonDisabled(true);

                    // Enable the button after 1 minute
                    setTimeout(() => {
                        setIsButtonDisabled(false);
                    }, 60000);
                }
                else {

                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }



    return (
        <>
            <ToastContainer />
            <main id='main' className="bg-black" style={{ backgroundImage: 'none' }}>
                <div className="login_box form-default">
                    <div className="login_box_head">
                        <img src={Logo} alt='brand' />
                        <h4>Email Not Verified!</h4>
                    </div>
                    <div className="login_box_body text-center">

                        <div className="icon">
                        <ExclamationTriangleIcon className="w-25 text-primary"/>
                        </div>
                        <p>Your email was not verified. Please check your inbox for a verification email.
                            <br />
                            <br />
                            If you haven't received any,
                            click the button below to get a new verification link.
                        </p>
                        <button type="submit" className="button button-3d-primary button-icon w-100"
                            onClick={ResendEmail}
                            disabled={isButtonDisabled}
                        >
                            Resend Verify Link {loading ? '...' : ''}<span><ArrowLeftEndOnRectangleIcon /></span>
                        </button>
                        <div style={{ marginTop: "15px",textAlign:'center'}}>
                            <img src={dividerH} />
                        </div>
                        <div className='mt-3 d-flex m-auto justify-content-center'>
                            <p className='m-0 fs-6'>Back to <NavLink to={'/login'} className='fs-6'> SIGN IN</NavLink></p>
                        </div>
                    </div>
                </div>
            </main >
        </>
    );
}

export default EmailNotVerified;