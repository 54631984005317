import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import ReactTooltip from 'react-tooltip';

import { Link, NavLink, useParams } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import ETH from "../../../assets/images/coins/eth.png";
import BTC from "../../../assets/images/coins/btc.png";
import USDT from "../../../assets/images/coins/usdt.png";
import Tron from "../../../assets/images/coins/trx.png";
import QRCode from "react-qr-code";
import AddMoneyWizard from "./AddMoneyWizard";
import { NumberFormat } from "../General/Functions";
import { CurrencyDollarIcon, DocumentDuplicateIcon, QrCodeIcon } from "@heroicons/react/24/outline";
import LoginCheck from "../General/LoginCheck";

function AddMoneyCrypto(props) {


    const AccessKey =(localStorage.getItem("AccessKey"));
   const UserID =(localStorage.getItem("ID"));

    const [loading, setLoading] = useState(false);

    const [amount, setAmount] = useState(0);
    const [Converted, setConverted] = useState(null);
    const [TargetCryptoCurrency, setTargetCryptoCurrency] = useState(null);
    const [EstimatedAmount, setEstimatedAmount] = useState(0);
    const [Order, setOrder] = useState({});
    const [step, setStep] = useState(1);
    const [selectedMode, setSelectedMode] = useState(null);

    // wizard tabs active status
    const [active2, setActive2] = useState(false);
    const [active3, setActive3] = useState(false);
    const [active4, setActive4] = useState(false);

    let params = useParams();

    useEffect(() => {

        if (params.amount) {
            setAmount(params.amount);
        }

        // const Params = {
        //     AccessKey: AccessKey,
        //     UserID: UserID,
        // };

        // var api_config = {
        //     method: 'post',
        //     url: config.base_url + 'Members/GetCryptoCurrencyList',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     data: Params
        // };

        // axios(api_config)
        //     .then(function (response) {

        //         if (response.data.status_code == 1) {
        //             // console.log(response.data);
        //             setCurrencyList(response.data.listofCurrency);
        //         }



        //     })
        //     .catch(function (error) {
        //         // console.log(error);
        //     });

    }, []);





    const ConvertUSDToCryptoValue = (Currency, e) => {

        setSelectedMode(e.target.innerText);
        setLoading(true);
        setTargetCryptoCurrency(Currency);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            NoOfUSD: amount,
            TargetCryptoCurrency: Currency
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/ConvertUSDToCryptoValue',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                 console.log(response.data);
                if (response.data.status_code == 1) {
                    setConverted(response.data);
                    setEstimatedAmount(response.data.EstimatedAmount);
                    setActive2(true);
                    setLoading(false);
                }



            })
            .catch(function (error) {
                // console.log(error);
            });




    }

    const CreateCryptoOrder = () => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            NoOfUSD: amount,
            TargetCryptoCurrency: TargetCryptoCurrency,
            EstimatedAmount: EstimatedAmount
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/CreateCryptoOrder',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setOrder(response.data);
                }

                setLoading(false);
                setStep(2);
                setLoading(false);
                setActive3(true);
            })
            .catch(function (error) {
                // console.log(error);
            });


    }

    return (
        <>
            <ToastContainer />
            <ReactTooltip />
            <LoginCheck />

            <div className="section-title">BI.AI Safe</div>
            <div className="mobile-container">

                <div className="dash-bg-card card-bg-addmoney mb-4">


                    <AddMoneyWizard
                        active1={true}
                        active2={active2}
                        active3={active3}
                        active4={active4}
                        label4="Upload"
                    />

                    <div className="position-relative">
                        {
                            step == 1 &&
                            <>
                                <h4 className="text-center mt-4 mb-3">Select Payment Mode</h4>


                                <ul className="payment-modes">
                                    <li>
                                        <a className={TargetCryptoCurrency == "BTC" ? "active" : ""} onClick={(e) => ConvertUSDToCryptoValue("BTC", e)} href="#">
                                            <img src={BTC} />
                                            Bitcoin - BTC</a>
                                    </li>
                                    <li>
                                        <a className={TargetCryptoCurrency == "USDTTRC20" ? "active" : ""} onClick={(e) => ConvertUSDToCryptoValue("USDTTRC20", e)} href="#">
                                            <img src={USDT} />TRC20 - USDT
                                        </a>
                                    </li>
                                    {/* <li><a className={TargetCryptoCurrency == "ETH" ? "active" : ""} href="#" onClick={(e) => ConvertUSDToCryptoValue("ETH", e)}> <img src={ETH} />Ethereum (ERC-20) - ETH</a></li> */}
                                    <li><a className={TargetCryptoCurrency == "TRX" ? "active" : ""} href="#" onClick={(e) => ConvertUSDToCryptoValue("TRX", e)}> <img src={Tron} />TRON - TRX</a></li>
                                </ul>


                                {
                                    loading ?
                                        <div className="text-center w-100 mt-3">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </div>
                                        :
                                        Converted != null &&
                                        <>
                                            <div className="deposit-info-box bg my-3">
                                                <div>
                                                    <CurrencyDollarIcon />
                                                    <h4>Amount in USD: </h4>
                                                </div>

                                                <p>${NumberFormat(amount)}</p>

                                            </div>


                                            <div className="deposit-info-box">
                                                <div>
                                                    {
                                                        TargetCryptoCurrency == "BTC" ?
                                                            <img src={BTC} />
                                                            :
                                                            TargetCryptoCurrency == "USDTTRC20" ?
                                                                <img src={USDT} />
                                                                :
                                                                TargetCryptoCurrency == "ETH" ?
                                                                    <img src={ETH} />
                                                                    :
                                                                    TargetCryptoCurrency == "TRX" ?
                                                                        <img src={Tron} />
                                                                        :
                                                                        ""

                                                    }


                                                    <h4>Deposit in {selectedMode}: </h4>
                                                </div>
                                                <p>{Converted.EstimatedAmount}</p>
                                            </div>

                                            <div className="bg-white p-3 d-none">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Estimated Amount</td>
                                                            <td className="text-end">{Converted.EstimatedAmount}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>No. of USD</td>
                                                            <td className="text-end">{Converted.NoOfUSD}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Selected Currency</td>
                                                            <td className="text-end">{Converted.SelectedCurrency}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row gy-3 mt-3">
                                                <div className="col-md-6">
                                                    <NavLink to="/dashboard/addmoney" className="button button-3d-outline w-100"><span>Modify Amount</span></NavLink>
                                                </div>
                                                <div className="col-md-6">
                                                    <button onClick={CreateCryptoOrder}
                                                        className="button button-3d-primary w-100"
                                                    >Continue</button>
                                                </div>
                                            </div>
                                        </>
                                }
                            </>
                        }
                        {
                            step == 2 &&
                            <>
                                <div className="crypto_order">
                                    <h2>Deposit <span style={{ fontSize: "20px" }}>{Order.Amount}</span> {Order.Pay_Currency} into below Address</h2>
                                    <p className="sub-heading">
                                        Scan the below QR or Deposit the {Order.Amount} {Order.Pay_Currency} into the below address and wait for 1 Confirmation in the network.
                                    </p>

                                    <div style={{ height: "auto", margin: "0 auto", padding: "20px", maxWidth: 204, width: "100%" }}>
                                        <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            value={Order.Pay_Address}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>
                                    <div className="crypto_address">
                                        <div className="address-wrap">
                                            <span>Address</span>
                                            <div className="address">
                                                <div style={{ width: 270, overflow: "hidden", textOverflow: "ellipsis" }}>
                                                    {Order.Pay_Address}
                                                </div>
                                                <ReactTooltip />
                                                <button data-tip="Copy Address" data-place="top"
                                                    onClick={() => {

                                                        navigator.clipboard.writeText(Order.Pay_Address);
                                                        toast.success('Address Copied', {
                                                            position: toast.POSITION.TOP_RIGHT
                                                        });

                                                    }}
                                                >
                                                    <DocumentDuplicateIcon />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mt-4">
                                        Note:<br />
                                        Sending Coin or token other than {Order.Pay_Currency} to this address may result in the loss of your deposit
                                    </p>
                                    <div className="row gy-3">
                                        <div className="col-md-6">
                                            <button className="button button-outline-bs w-100" onClick={() => {
                                                setOrder({});
                                                setStep(1);
                                                setConverted(null);
                                            }}><span>Change Coin</span></button>
                                        </div>
                                        <div className="col-md-6">
                                            <button className="button button-3d-primary w-100"
                                                onClick={() => {

                                                    setStep(3);
                                                    setActive4(true);
                                                }}>Amount Transferred</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            step == 3 &&
                            <>
                                <div className="text-center mt-5">
                                    <h4>Completed</h4>
                                    <p>
                                        If you have deposited the amount into the wallet address displayed in the
                                        previous step, you can close this window now. After one successful network
                                        confirmation, the amount will be automatically credited to your BI.AI Account in
                                        USD.

                                    </p>
                                    <Link className="button button-3d-primary w-100" to="/dashboard/transactions/deposits">Check Status</Link>
                                    <a className="button button-3d-primary d-none" href={`https://www.blockchain.com/${Order.Pay_Currency}/address/${Order.Pay_Address}`} target="_blank">View In Explorer</a>
                                </div>
                            </>
                        }
                    </div>

                </div>

            </div>
        </>
    );
}

export default AddMoneyCrypto;