import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import config from '../../Config';
import axios from 'axios';
import Logo from '../../assets/images/logo/gif-logo.gif';
import { toast, ToastContainer } from "react-toastify";
import { Form } from "react-bootstrap";
import dividerH from '../../assets/images/divider-h.svg';
import { generateRandomString } from "../Dashboard/General/Functions";
import { KeyIcon } from '@heroicons/react/24/outline';
const EmailVerify = () => {

  const AccessKey = localStorage.getItem("AccessKey");
  const User_Name = localStorage.getItem("User_Name");
  const Email = localStorage.getItem("Email_Address");
  const [captcha, setCaptcha] = useState("");
  const [Usercaptcha, setUsercaptcha] = useState("");
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    setCaptcha(generateRandomString(6));
  }, []);

  const handleSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();



    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    else {


      let host = window.location.hostname;

      if (host != 'localhost') {
        if (Usercaptcha != captcha) {


          toast.error('Incorrect Captcha', {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;

        }
      }



      setLoading(true);

      const data = {
        UserName: User_Name,
        SecurityCode: event.target.SecurityCode.value,

      };

      //// console.log(data);

      var api_config = {
        method: 'post',
        url: config.base_url + 'Members/VerifyEmailAddress',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      // console.log(data);


      axios(api_config)
        .then(function (response) {

          // console.log(response.data);

          if (response.data.status_code == 1) {

            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });

            if (AccessKey != null) {
              navigate("/dashboard/dashboard");
            }
            else {
              navigate("/");
            }

          }
          else if (response.data.status_code == 0) {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
          setLoading(false);

        })
        .catch(function (error) {
          setLoading(false);
          toast.error('Something went wrong..', {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    }

    setValidated(true);
  };
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);




  const ResendEmail = () => {
    const data = {

      UserName: User_Name,

    };

    //// console.log(data);

    var api_config = {
      method: 'post',
      url: config.base_url + 'Members/SendSignUpEmail',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    //// console.log(data);
    axios(api_config)
      .then(function (response) {

        if (response.data.status_code == 1) {
          toast.success('Email Verification Code Sent Successfully', {
            position: toast.POSITION.TOP_RIGHT
          });

          setIsButtonDisabled(true);
          setTimeout(() => {
            setIsButtonDisabled(false);
          }, 60000);
        }
        else {

          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }

        setLoading(false);


      })
      .catch(function (error) {
        setLoading(false);
        toast.error('Network Error..', {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  }
  const UpdateCaptcha = () => {
    setCaptcha(generateRandomString(6));
  }



  return (
    <>
      <ToastContainer />
      <main id='main' className="bg-black" style={{ backgroundImage: 'none' }}>

        <Form noValidate validated={validated} onSubmit={handleSubmit}>

          <div className="login_box">
            <div className="login_box_head">
              <img src={Logo} alt='brand' />
              <h4>Verify your email address.</h4>
              <p>Check your inbox,spam or junk folder at {Email}</p>
            </div>
            <div className="login_box_body form-default">
              <div className="icon-input mb-4">
                <input
                  name="SecurityCode"
                  placeholder='Enter verification code'
                  className="form-control"
                  required
                />
                <div className='icon'>
                  <KeyIcon />
                </div>
              </div>
              <div className="verify">
                <div className="Character" >
                  {captcha}
                </div>
                <div className="icon-input">
                  <input type="text" onChange={(e) => setUsercaptcha(e.target.value)} className="form-control" name="" placeholder="Enter Captcha" required />
                </div>
              </div>
              <div className="my-3 text-end">
                <NavLink style={{ fontSize: "13px" }} onClick={UpdateCaptcha}>Refresh The Captcha</NavLink>
              </div>
              <button type="submit" className="button button-3d-primary w-100"
                disabled={loading}>
                Verify Code {loading ? '...' : ''}
              </button>
            </div>
            <div style={{ marginTop: "-15px" }}>
              <img src={dividerH} />
            </div>
            <div className='text-center mt-2 d-flex align-content-center gap-3 justify-content-center'>
              <p>Did not received code?</p>
              <NavLink className='fs-6'
                onClick={ResendEmail}
                disabled={isButtonDisabled}
              > RESEND</NavLink>
            </div>
            <div style={{ marginTop: "-15px" }}>
              <img src={dividerH} />
            </div>
            <div className="mt-3 text-center" style={{
              fontSize: "12px",
            }}>
              <NavLink className='fs-6 text-primary' to="/dashboard/dashboard">Back to Home</NavLink>
            </div>
          </div>
        </Form>
      </main>
    </>
  );
};

export default EmailVerify;
