import React from "react";
import { NavLink } from "react-router-dom";
import { CurrencyDollarIcon, UserIcon, LifebuoyIcon, WalletIcon } from "@heroicons/react/24/solid";
import { HomeIcon } from "@heroicons/react/24/outline";


function BottomMenu() {



    return (
        <>
            <div className="bottom-menu">
                <ul className="left-buttons">
                    <li>
                        <NavLink to="/dashboard/network">
                            <LifebuoyIcon />
                            <span>Crew</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/dashboard/withdraw">
                            <WalletIcon />
                            <span>Payout</span>
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/dashboard/dashboard">
                            <HomeIcon/>
                            <span>Home</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/dashboard/addmoney">
                            <CurrencyDollarIcon />
                            <span>Refill</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/dashboard/profile/account">
                            <UserIcon />
                            <span>Profile</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default BottomMenu;