import React, { useState } from "react";
import config from "../../../Config";
import { Form } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ArrowRightIcon, CheckCircleIcon, EnvelopeIcon, ShieldCheckIcon, UserIcon, XCircleIcon } from "@heroicons/react/24/outline";
import LoginCheck from "../General/LoginCheck";

function Step1(props) {

    const AccessKey =(localStorage.getItem("AccessKey"));
   const UserID =(localStorage.getItem("ID"));
    const [ReceiverID, setReceiverID] = useState(null);
    const [key, setKey] = useState('home');
    // downline activation states
    const [user, setUser] = useState({});
    const [downlineVerified, setDownlineVerified] = useState(null);
    const [downlineLoading, setDownlineLoading] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);

    const VerifyUser = () => {
        if (ReceiverID != null) {

            setDownlineLoading(true);

            const data = {
                Email_Address: ReceiverID,
                AccessKey: AccessKey,
                UserID: UserID
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/GetBalanceTransferInfo',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {
                    //// console.log(response.data);
                    if (response.data.status_code == 1) {
                        setUser(response.data);
                        setDownlineVerified(true);

                    }
                    else {
                        setDownlineVerified(false);
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setDownlineLoading(false);


                })
                .catch(function (error) {
                    // console.log(error);
                });

        }
    }


    const checkUsername = () => {

        if (ReceiverID != null) {
            setDownlineLoading(true);


            const data = {
                UserName: ReceiverID
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/GetReferralUserByUserName',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {
                    // console.log(response.data);
                    if (response.data.status_code == 1) {
                        setUser(response.data);
                        setDownlineVerified(true);

                    }
                    else {
                        setDownlineVerified(false);
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setDownlineLoading(false);



                })
                .catch(function (error) {
                    // console.log(error);
                });
        }
    }

    const handleTabChange = (selectedKey) => {
        setKey(selectedKey);
        // Call your function here or perform any desired action
        setDownlineVerified(null);
        setIsValidEmail(false);
        setReceiverID(null);
    };

    const handleEmail = (e) => {
        //setReceiverID(e.target.value);
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        setIsValidEmail(emailRegex.test(ReceiverID));

        if (emailRegex.test(ReceiverID)) {
            VerifyUser();
        }
        else {
            setDownlineVerified(null);
        }

    }

    return (
        <>
            <LoginCheck />
            <Form onSubmit={props.handleSubmitEmail} className="verification-section">

                <div className="">
                    <input type="hidden" name="ReceiverID" value={ReceiverID} />
                    {props.hasError ? <Alert key="danger" variant="danger">{props.errorMessage}</Alert> : ""}



                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={handleTabChange}
                        className="menu-option"
                    >


                        <Tab eventKey="home" title="Email">
                            <div className="verification-info">
                                <div className="bonus_page_head mb-4">
                                    <h3>Email</h3>
                                    <p>Enter email address and Verify</p>
                                </div>
                                <div className="verification form-default">
                                    <div className="icon-input m-0">
                                        <input
                                            type="email"
                                            name="email"
                                            onChange={(e) => setReceiverID(e.target.value)}
                                            placeholder='Enter Email'
                                            className="form-control"
                                        />
                                        <div className="icon">
                                            <EnvelopeIcon />
                                        </div>
                                        <div className="icon-right">
                                            {

                                                downlineVerified == null ?
                                                    ""
                                                    :
                                                    downlineVerified ?
                                                        <CheckCircleIcon />
                                                        :
                                                        <XCircleIcon />
                                            }
                                        </div>
                                    </div>
                                    <div className="mt-3 mb-3">
                                        <button
                                            onClick={handleEmail}
                                            className="button button-3d-outline button-icon-bs w-100" type="button"
                                            disabled={ReceiverID == null ? true : false}>
                                            <span>{downlineLoading ? 'Loading..' : 'Verify'}</span>
                                            <ShieldCheckIcon />
                                        </button>
                                    </div>

                                </div>
                                {
                                    
                                    props.loading ?
                                        <div className="text-center"><Spinner animation="border" role="status"></Spinner> </div>
                                        :
                                        <button className="button-3d-primary w-100" disabled={!downlineVerified} >Continue</button>

                                }
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title="Username">
                            <div className="verification-info ">
                                <div className="bonus_page_head mb-4">
                                    <h3>Username</h3>
                                    <p>Enter Username and Verify</p>
                                </div>
                                <div className="verification form-default">
                                    <div className="icon-input">
                                        <input
                                            type="text"
                                            name="email"
                                            onChange={(e) => setReceiverID(e.target.value)}
                                            placeholder='Enter Username'
                                            className="form-control"
                                        />
                                        <div className="icon">
                                            <UserIcon />
                                        </div>

                                        <div className="icon-right">
                                            {

                                                downlineVerified == null ?
                                                    ""
                                                    :
                                                    downlineVerified ?
                                                        <CheckCircleIcon />
                                                        :
                                                        <XCircleIcon />
                                            }
                                        </div>
                                    </div>
                                    <div className="mt-3 mb-3">
                                        <button
                                            onClick={checkUsername}
                                            className="button button-3d-outline button-icon-bs w-100" type="button"
                                            disabled={ReceiverID == null ? true : false}>
                                            <span>{downlineLoading ? 'Loading..' : 'Verify'}</span>
                                            <ShieldCheckIcon />
                                        </button>
                                    </div>

                                </div>
                                {
                                    props.loading ?
                                        <div className="text-center"><Spinner animation="border" role="status"></Spinner> </div>
                                        :
                                        <button className="button-3d-primary w-100" disabled={!downlineVerified} >Continue</button>

                                }
                            </div>
                        </Tab>

                    </Tabs>

                </div>
            </Form>
        </>
    );
}

export default Step1;