import React, { useEffect, useRef, useState } from "react";
import config from "../../../Config";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Step1 from "./Step1";
import LoginCheck from "../General/LoginCheck";
import Step2 from "./Step2";
import { useNavigate } from "react-router-dom";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import moment from "moment";
import QRCode from "react-qr-code";
import Logo from "../../../assets/images/logo/logo-notext.png";
import html2canvas from "html2canvas";
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import { XMarkIcon } from "@heroicons/react/24/outline";


function BalanceTransfer(props) {

    const AccessKey = (localStorage.getItem("AccessKey"));
    const UserID = (localStorage.getItem("ID"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");
    const [Email_Address, setEmail_Address] = useState(localStorage.getItem("Email_Address"));
    const [MemberInfo, setMemberInfo] = useState({});
    const [transfer, settransfer] = useState({});
    const [successData, setSuccessData] = useState({});
    const [loadinginvoice, setloadinginvoice] = useState(false);

    let navigate = useNavigate();


    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        navigate(`/dashboard/dashboard`);
    };
    const HostUrl = window.location.origin + `/dashboard/transactions/funds-history/sent/img?id=${transfer.Payment_ID}`;



    useEffect(() => {
        const Parms = {
            ID: UserID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/GetMemberInfo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Parms
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    setMemberInfo(response.data);
                }
                else if (
                    (response.data.status_message === "Invalid Access Key.")
                ) {
                    navigate("/login");
                }


            })
            .catch(function (error) {
                // console.log(error);
            });


    }, []);


    const handleSubmitEmail = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            setEmail(event.target.ReceiverID.value);

            if (event.target.ReceiverID.value == Email_Address) {
                toast.error('You can not transfer balance to yourself.', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoading(false);
            }
            else if (event.target.ReceiverID.value == "") {
                toast.error('Please entery Email / Pay ID / Username', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoading(false);
            }
            else {




                const step1Data = {
                    Email_Address: event.target.ReceiverID.value,
                    AccessKey: AccessKey,
                    UserID: UserID
                };



                var api_config = {
                    method: 'post',
                    url: config.base_url + 'Members/GetBalanceTransferInfo',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: step1Data
                };


                axios(api_config)
                    .then(function (response) {
                        if (response.data.status_code == 1) {
                            setHasError(false);
                            setStep(2);
                            setUser(response.data);


                        }
                        else {
                            toast.error(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }

                        setLoading(false);


                    })
                    .catch(function (error) {
                        // console.log(error);
                    });

            }


        }

        setValidated(true);
    }



    const [validated2, setValidated2] = useState(false);
    const [step2Data, setStep2Data] = useState({});
    const [amountToTransfer, setAmountToTransfer] = useState(0);

    const handleSubmitAmount = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            let amount = event.target.amount.value;
            setAmountToTransfer(amount);

            if (amount > MemberInfo.Current_Balance) {
                toast.error('Insufficient Funds', {
                    position: toast.POSITION.TOP_RIGHT
                });

            }
            else {

                setLoading(true);
                const info2 = {
                    UserID: UserID,
                    AccessKey: AccessKey,
                    Email_Address: user.Email_Address,
                    Current_Balance: amount,
                    PINCode: event.target.pincode.value,
                    Member_Type: "balance",
                    Extra2: event.target.notes.value,
                    AuthorizationCode: event.target.AuthCode.value,
                };

                // console.log(info2);

                var api_config = {
                    method: 'post',
                    url: config.base_url + 'Members/BalanceTransfer',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: info2
                };

                axios(api_config)
                    .then(function (response) {
                        // console.log(response.data, 'response');
                        if (response.data.status_code == 1) {
                            toast.success(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setSuccessData(response.data);
                            setLoading(false);

                            handleViewInvoice(response.data.TransactionID);
                            // navigate('/dashboard/transactions/funds-history/sent');

                        }
                        else {
                            toast.error(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setLoading(false);
                        }

                    })
                    .catch(function (error) {
                        // console.log(error);
                    });
            }
        }

        setValidated2(true);
    }



    const renderStep = () => {
        if (step == 1) {
            return (
                <Step1 validated={validated}
                    hasError={hasError}
                    email={email}
                    errorMessage={errorMessage}
                    handleSubmitEmail={handleSubmitEmail}
                    loading={loading}
                />
            )
        }
        else if (step == 2) {

            return (
                <div className="col-12">
                    <Step2 validated2={validated2}
                        user={user}
                        hasError={hasError}
                        errorMessage={errorMessage}
                        toStep1={() => setStep(1)}
                        step2Data={step2Data}
                        handleSubmitAmount={handleSubmitAmount}
                        Commission={user.Commission_Percentage}
                        balance={MemberInfo.Current_Balance}
                        loading={loading} />
                </div>
            )
        }
    }

    const handleViewInvoice = (ID) => {
        setShow(true);
        setloadinginvoice(true);
        // console.log(ID);

        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            ID: ID,
        }

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/BalanceTrasnferByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        axios(api_config)
            .then(function (response) {
                // console.log(response.data)
                if (response.data.status_code == 1) {
                    settransfer(response.data.BalanceTrasnfer);

                    setloadinginvoice(false);

                }


            })
            .catch(function (error) {
                // console.log(error);
            });

    }

    const InvoiceRef = useRef();

    const handleCapture = () => {
        if (InvoiceRef.current !== null) {
            html2canvas(InvoiceRef.current).then((canvas) => {
                const imgData = canvas.toDataURL("image/jpeg");

                // Create a link element
                const link = document.createElement("a");
                link.href = imgData;
                link.download = "Transactions Invoice.jpg";

                // Append the link to the body and trigger the download
                document.body.appendChild(link);
                link.click();

                // Remove the link from the body
                document.body.removeChild(link);
            });
        }
    };


    return (
        <>
            <LoginCheck />
            <ToastContainer />
            <div className="section-title">
                Funds Transfer

            </div>
            <p className="text-center">Transfer funds through Email or Username.</p>
            <div className="mobile-container">
                {renderStep()}
            </div>
            <Modal centered show={show} onHide={handleClose} className="modal-theme transparent">
                <Modal.Header closeButton>
                    <Modal.Title>Transfer Invoice</Modal.Title>
                    <button className="btn-close" onClick={handleClose}>
                        <XMarkIcon />
                    </button>
                </Modal.Header>
                <Modal.Body>

                    {
                        loadinginvoice ?
                            <Spinner animation="border" size="sm" className='full-screen-loader' />
                            :
                            <div className="invoice-page black-invoice-page" ref={InvoiceRef}>
                                <div className="subpage">
                                    <div className="invoice-top">
                                        <img src={Logo} />
                                        <div className="title">Transfer Invoice</div>
                                        <span>{moment(transfer.Created_Date).format('DD/MM/YYYY - HH:mma')}</span>
                                        <div className="qr-code-wrap">
                                            <QRCode
                                                size={256}
                                                value={HostUrl}
                                                viewBox={`0 0 256 256`}
                                            />
                                        </div>
                                    </div>
                                    <div className="invoice-content">
                                        <ul>
                                            <li>
                                                <label>Sender</label>
                                                <span>{transfer.User_Name}</span>
                                            </li>
                                            <li>
                                                <label>Beneficiary</label>
                                                <span>{transfer.Beneficiary_Name}</span>
                                            </li>

                                            <li>
                                                <label>Payment Mode</label>
                                                <span>Internal Transfer</span>
                                            </li>
                                            <li>
                                                <label>TID</label>
                                                <span>{transfer.Payment_ID}</span>
                                            </li>
                                            <li>
                                                <label>Amount</label>
                                                <span>$ {transfer.Transaction_Amount}</span>
                                            </li>
                                            <li>
                                                <label>Fee</label>
                                                <span>$ {transfer.Commission_Amount}</span>
                                            </li>
                                            <li>
                                                <label>Receivable Amount</label>
                                                <span>$ {transfer.Receiveable_Amount}</span>
                                            </li>
                                            {/* <li>
                                                <img src={stamp} alt="mag_stamp" />
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                    }

                </Modal.Body>
                <Modal.Footer className='bg-black'>
                    <div className="button-row w-100 justify-content-center">
                        <Dropdown className="w-100">
                            <Dropdown.Toggle variant="success" className='button button-light w-100' id="dropdown-basic">
                                Share
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/">
                                    <EmailShareButton url={HostUrl}>
                                        Email
                                    </EmailShareButton>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/">
                                    <FacebookShareButton url={HostUrl}>
                                        Facebook
                                    </FacebookShareButton>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/">
                                    <TwitterShareButton url={HostUrl}>
                                        Twitter
                                    </TwitterShareButton>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/">
                                    <WhatsappShareButton url={HostUrl}>
                                        Whatsapp
                                    </WhatsappShareButton>
                                </Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                        <button className='button button-3d-primary w-100' onClick={handleCapture}>
                            Save to Gallery
                        </button>
                        <button className='button button-3d-outline w-100' onClick={() => navigate('/dashboard/transactions/funds-history/sent?activemenu=Transfers')}>
                            Move to Transaction
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default BalanceTransfer;