import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import Logo from '../../assets/images/logo/gif-logo.gif';
import { toast, ToastContainer } from "react-toastify";
import { Form, Modal, Spinner } from "react-bootstrap";
import { generateRandomString } from "../Dashboard/General/Functions";
import {
  EyeIcon,
  EyeSlashIcon,
  XCircleIcon,
  CheckCircleIcon,
  XMarkIcon,
  KeyIcon,
} from "@heroicons/react/24/outline";
import ReCAPTCHA from 'react-google-recaptcha';
import { LockClosedIcon, UserCircleIcon, UserIcon, EnvelopeIcon, UsersIcon } from '@heroicons/react/24/solid';

import dividerH from '../../assets/images/divider-h.svg';
import Services from "../Conditions/Services";
import PrivacyPolicy from "../Conditions/PrivacyPolicy";

const PartnerSignUp = () => {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [Username, setUsername] = useState("");
  const [UserNameExist, setUserNameExist] = useState(null);
  const [ReferrralExist, setReferrralExist] = useState(null);
  const [Referrral, setReferrral] = useState("");
  const [RefEmail, setRefEmail] = useState(null);
  const [ParentID, setParentID] = useState(0);
  const [captcha, setCaptcha] = useState("");
  const [VerificationCode, setVerificationCode] = useState("");
  const [Email, setEmail] = useState("");
  const [timer, setTimer] = useState(false);
  const [inputdisable, setinputdisable] = useState(false);
  const [inputloading, setinputloading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [inputstyle, setinputstyle] = useState(true);

  let navigate = useNavigate();
  let params = useParams();

  const [showterms, setShowterms] = useState(false);

  const handleCloseterms = () => setShowterms(false);
  const handleShowterms = () => setShowterms(true);



  const [showpolicy, setShowpolicy] = useState(false);
  const handleClosepolicy = () => setShowpolicy(false);
  const handleShowpolicy = () => setShowpolicy(true);


  const [Password, setPassword] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  }


  useEffect(() => {

    document.title = "Sign Up";

    if (params.id) {
      setParentID(params.id);

      const data = {
        UserName: params.id,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Members/GetReferralUserByUserName",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          // console.log(response.data);
          if (response.data.status_code == 1) {
            setRefEmail(response.data.User_Name);
            setReferrralExist(true);
          } else if (response.data.status_code == 0) {
            setReferrralExist(false);
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000
            });
          }
        })
        .catch(function (error) {
          toast.error("Something went wrong..", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000
          });
        });
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    CheckReferrral();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {



      if (captchaValue) {
        setLoading(true);
        const data = {
          Full_Name: event.target.Full_Name.value,
          Email_Address: Email,
          User_Name: Username,
          Referrer_Email: event.target.Referrer_Email.value,
          Password: Password,
          Email_Verification_Code: VerificationCode,
        };
        // console.log(data);

        var api_config = {
          method: "post",
          url: config.base_url + "Members/SignUp",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        // console.log(data);
        axios(api_config)
          .then(function (response) {
            // console.log('fahad', response.data);

            if (response.data.status_code == 1) {
              toast.success(response.data.status_message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000
              });

              setTimeout(() => {
                navigate("/login");
              }, 4000);
            } else if (response.data.status_code == 0) {
              toast.error(response.data.status_message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000
              });
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            toast.error("Something went wrong..", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000
            });
          });
      } else {
        alert("Please complete the CAPTCHA");
      }
    }

    setValidated(true);
  };
  const CheckReferrral = (e) => {
    if (Referrral.length > 0) {
      const data = {
        ReferralName: Referrral,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Members/CheckValidReferral",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          if (response.data.status_message == "Valid Referral") {
            setReferrralExist(true);
          } else {
            setReferrralExist(false);
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else if (Referrral.length == 0) {
      setReferrralExist(null);
    }
  };

  const checkUsername = () => {
    if (Username == " " || Username == "") {
      setUserNameExist(true);
      return false;
    }

    const data = {
      UserName: Username,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/CheckUserNameExists",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    // console.log(data);
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // "User Does Not Exists"
          setUserNameExist(false);
        } else {
          // "User Name Exists"
          setUserNameExist(true);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const handleUsernameChange = (event) => {
    const value = event.target.value.slice(0, 13);
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "");
    setUsername(sanitizedValue);

    const formattedUsername =
      sanitizedValue.charAt(0).toUpperCase() +
      sanitizedValue.slice(1).toLowerCase();
    setUsername(formattedUsername);
  };
  const handleReferralChange = (event) => {
    const value = event.target.value;
    //const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, ""); // Remove spaces
    setReferrral(value);
  };

  const handleEmailCheck = (event) => {

    if (Email != null && Email.length > 0) {

      setinputstyle(true);
      setinputloading(true);
      const data = {
        Email_Address: Email,
      };

      // console.log(data);

      var api_config = {
        method: 'post',
        url: config.base_url + 'Members/CheckEmailExists',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(api_config)
        .then(function (response) {

          // console.log(response.data);
          if (response.data.status_code == 1) {
            setinputloading(false);
            setinputdisable(true);
            setIsButtonDisabled(true);

            setTimeout(() => {
              setIsButtonDisabled(false);
            }, 60000);
            setTimer(60);

            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
          else {
            setinputloading(false);
            setinputstyle(false);
            setinputdisable(false);
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error('Network Error..', {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    }
    else {
      setinputstyle(false);
    }
  };


  return (
    <>
      <ToastContainer />

      <Modal centered show={showterms} onHide={handleCloseterms} className="modal-theme transparent">
        <Modal.Header >
          <Modal.Title>Terms and Conditions</Modal.Title>
          <button className="btn-close" onClick={handleCloseterms}>
            <XMarkIcon />
          </button>
        </Modal.Header>
        <Modal.Body><Services /></Modal.Body>
      </Modal>
      <Modal centered show={showpolicy} onHide={handleClosepolicy} className="modal-theme transparent">
        <Modal.Header >
          <Modal.Title>Privacy Policy</Modal.Title>
          <button className="btn-close" onClick={handleClosepolicy}>
            <XMarkIcon />
          </button>
        </Modal.Header>
        <Modal.Body><PrivacyPolicy /></Modal.Body>
      </Modal>

      <main id='main' className="bg-black" style={{ backgroundImage: 'none' }}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>

          <div className="login_box form-default">
            <div className="login_box_head">
              <img src={Logo} alt='brand' />
              <h4>Create New Account</h4>
            </div>
            <div className="login_box_body">
              <div className="icon-input mb-4">
                <input
                  name="Full_Name"
                  placeholder="Full Name"
                  className="form-control"
                  required
                />
                <div className="icon">
                  <UserCircleIcon />
                </div>
              </div>
              <div className="icon-input mb-4">
                <div className="icon" style={{ zIndex: '10' }}>
                  <EnvelopeIcon />
                </div>
                <div className="input-group">
                  <input
                    type="email"
                    placeholder="Email"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    required
                    disabled={inputdisable}
                    style={{ borderColor: inputstyle ? undefined : 'red' }}

                  />
                </div>
              </div>
              {
                inputloading ?
                  <div className="text-center mb-4">
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                  :
                  <button
                    onClick={handleEmailCheck}
                    className="button button-3d-secondary w-100 mb-4"
                    type="button"
                    disabled={isButtonDisabled}
                  >
                    Get code
                  </button>
              }
              <div className="icon-input  mb-4">
                <input
                  type="text"
                  onChange={(e) => setVerificationCode(e.target.value)}
                  name={VerificationCode}
                  placeholder="Email Verification Code"
                  className="form-control"
                  required
                />
                <div className="icon">
                  <KeyIcon />
                </div>
              </div>

              <div className="icon-input mb-4">
                <input
                  name="User_Name"
                  type="text"
                  placeholder="Username"
                  className="form-control"
                  required
                  onChange={(e) => handleUsernameChange(e)}
                  onBlur={checkUsername}
                  value={Username}
                />
                <div className="icon">
                  {UserNameExist == true ? (
                    <>
                      <XCircleIcon />
                    </>
                  ) : UserNameExist == false ? (
                    <>
                      <UserIcon />
                    </>
                  ) : (
                    <UserIcon />
                  )}
                </div>
              </div>
              {Username.length < 5 && Username.length > 0 && (
                <div className="">
                  <p
                    style={{
                      fontSize: "12px",
                      margin: "10px 0",
                      color: "red",
                    }}
                  >
                    Minimum 5 to 13 characters
                  </p>
                </div>
              )}

              {RefEmail == null ? (
                <div className="icon-input  mb-4">
                  <input
                    type="text"
                    name="Referrer_Email"
                    value={Referrral}
                    onBlur={CheckReferrral}
                    onChange={(e) => handleReferralChange(e)}
                    placeholder="Referral (optional)"
                    className="form-control"
                  />
                  <div className="icon">
                    <UsersIcon />

                  </div>
                  <div className="icon-right">
                    {ReferrralExist == true ? (
                      <CheckCircleIcon />
                    ) : ReferrralExist == false ? (
                      <XCircleIcon />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                <div className="icon-input  mb-4">
                  <input
                    type="text"
                    className="form-control disabled"
                    name="Referrer_Email"
                    value={RefEmail}
                    placeholder="Referral"

                    required
                    disabled
                  />
                  <div className="icon">
                    <UsersIcon />

                  </div>

                  <div className="icon-right">
                    {ReferrralExist == true ? (
                      <CheckCircleIcon />
                    ) : ReferrralExist == false ? (
                      <XCircleIcon />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
              <div className="icon-input  mb-4">
                <input
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  name={Password}
                  placeholder="Password"
                  className="form-control"
                  required
                />
                <div className="icon">
                  <LockClosedIcon />
                </div>
                <div className="toggle-password" onClick={togglePasswordVisibility}>
                  {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                </div>
              </div>


              <ReCAPTCHA
                theme="dark"
                sitekey="6LeSRSIqAAAAAFIQTB2ZfkEMeo6BQROgLLUyw4xB"
                onChange={handleCaptchaChange}
              />

              <p className="terms mt-4">
                I agree to the <span className="text-primary" onClick={handleShowterms}>Terms & Condition </span>
                <span className="text-primary" onClick={handleShowpolicy}> Privacy Policy </span>
              </p>
              <button
                type="submit"
                className="button-3d-primary w-100"
                disabled={loading || captchaValue == null}
              >
                Signup {loading ? '...' : ''}
              </button>

            </div>

            <div style={{ marginTop: "-15px" }}>
              <img src={dividerH} />
            </div>
            <div className='text-center mt-5'>
              <p>Already have an account?</p>
              <NavLink to={'/login'} className='mt-3 d-flex m-auto justify-content-center'>LOGIN</NavLink>
            </div>
          </div>
        </Form>
      </main>
    </>
  );
};

export default PartnerSignUp;
