import React, { useEffect, useState, useRef } from 'react';
import QRCode from "react-qr-code";
import Logo from "../../../assets/images/logo/logo-notext.png";
import html2canvas from "html2canvas";
import axios from "axios";
import moment from "moment";
import Config from '../../../Config';
const WithdrawInvoice = () => {

    const AccessKey =(localStorage.getItem("AccessKey"));
   const UserID =(localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);
    const ID = urlParams.get('id');
    const [withdrawByID, setwithdrawByID] = useState([]);

    const HostUrl = window.location.origin + `/dashboard/transactions/withdraw/pdf?id=${ID}`;


    const InvoiceRef = useRef();
    useEffect(() => {

        document.title = "Withdraw Invoice";

        setLoading(true);
        // console.log(ID);

        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            Payment_ID: ID,
        }

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetWithdrawlByTransactionID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        // console.log('data', data)

        axios(api_config)
            .then(function (response) {
                // console.log(response.data)
                if (response.data.status_code == 1) {
                    setwithdrawByID(response.data);
                }

            })
            .catch(function (error) {
                // console.log(error);
            });

    }, []);


    const handleCapture = () => {
        if (InvoiceRef.current !== null) {
            html2canvas(InvoiceRef.current).then((canvas) => {
                const imgData = canvas.toDataURL("image/jpeg");

                // Create a link element
                const link = document.createElement("a");
                link.href = imgData;
                link.download = "Withdraw Invoice.jpg";

                // Append the link to the body and trigger the download
                document.body.appendChild(link);
                link.click();

                // Remove the link from the body
                document.body.removeChild(link);
            });
        }
    };

    return (
        <>
            <div className="invoice-parent-page">
                <div className="invoice-page p-3" ref={InvoiceRef}>
                    <div className="subpage">
                        <div className="invoice-top">
                            <img src={Logo} />
                            <div className="title">Withdraw Invoice</div>
                            <span>{moment(withdrawByID.Dated).format('DD/MM/YYYY - HH:mma')}</span>
                            <div className="qr-code-wrap">
                                <QRCode
                                    size={256}
                                    value={`${HostUrl}`}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                        </div>
                        <div className="invoice-content">
                            <ul>
                                <li>
                                    <label>Beneficiary</label>
                                    <span>{withdrawByID.To}</span>
                                </li>
                                
                                <li>
                                    <label>Payment Mode</label>
                                    <span>{withdrawByID.PaymentMode}</span>
                                </li>
                                <li>
                                    <label>Address / Account No.</label>
                                    <span>
                                        {withdrawByID.WalletAddress && withdrawByID.WalletAddress.length > 15
                                            ? "****" + withdrawByID.WalletAddress.substring(withdrawByID.WalletAddress.length - 15)
                                            : withdrawByID.WalletAddress}
                                    </span>


                                </li>
                                <li>
                                    <label>TID</label>
                                    <span>{withdrawByID.PaymentID}</span>
                                </li>
                                <li>
                                    <label>Amount</label>
                                    <span>$ {withdrawByID.Amount}</span>
                                </li>
                                <li>
                                    <label>Fee</label>
                                    <span>$ {withdrawByID.Fee}</span>
                                </li>
                                <li>
                                    <label>Exchange Rate</label>
                                    <span>{withdrawByID.ExchangeRate} {withdrawByID.ReceiveableCurrency}</span>
                                </li>
                                <li>
                                    <label>Receivable Amount</label>
                                    <span>{withdrawByID.ReceiveableAmount} {withdrawByID.ReceiveableCurrency}</span>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div className="invoice-footer">
                    <button className='button button-3d-primary w-100' onClick={handleCapture}>
                        Save to Gallery
                    </button>
                </div>
            </div>
        </>
    )
}

export default WithdrawInvoice