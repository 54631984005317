import React from 'react'

const PrivacyPolicy = (props) => {
    return (
        <>
            <div className='simple-section'>
                <div className=''>
                    <h3>Introduction:</h3>
                    <p>
                        <b> BUSINESSI.AI</b> is dedicated to safeguarding your privacy and the security of your
                        personal information. This <b>Privacy Policy</b> delves deeper into the details of how we
                        collect, use, disclose, and safeguard your data when you interact with our website,
                        services, and products.
                    </p>
                    <ol>
                        <li>
                            <h3> Information We Collect:</h3>
                            <ul className='p-0'>
                                <li>
                                    <b>Contact Information:</b>
                                    We gather basic details like your name, email address,
                                    phone number, and physical address to facilitate communication and personalize
                                    your experience.

                                </li>
                                <li><b>Demographic Information:</b> Understanding your age, gender, and nationality
                                    allows us to tailor our content and services to your preferences.
                                </li>
                                <li><b>Usage Information:</b>We track your browsing history, IP address, and device
                                    information to analyze user behavior and improve our Services. This data
                                    remains anonymized unless needed for specific troubleshooting purposes.</li>
                                <li><b>Financial Information:</b>If you make a purchase through our Services, we
                                    securely collect your credit card information for transaction processing.</li>
                                <li><b>User Content:</b>Photos, videos, and comments you share on our platforms
                                    become part of our data collection, enriching the user experience.</li>
                                <li><b>Third-Party Sources:</b>We may supplement our information with publicly
                                    available data or insights from social media platforms, always adhering to their
                                    respective privacy policies.</li>
                            </ul>
                        </li>
                        <li>
                            <h3> How We Use Your Information:</h3>
                            <ul className='p-0'>
                                <li>
                                    <b>Operational Needs: </b>
                                    Your data fuels the functionality of our Services, allowing
                                    us to manage user accounts, process transactions, and provide technical
                                    support
                                </li>
                                <li>
                                    <b>Personalization:</b>
                                    We utilize your information to personalize your experience,
                                    recommending content and services that align with your interests and
                                    preferences.

                                </li>
                                <li>
                                    <b>Marketing and Promotions:</b>
                                    With your consent, we may send you
                                    promotional materials about our Services, keeping you informed about exciting
                                    updates and offers.
                                </li>
                                <li>
                                    <b>Communication: </b>
                                    We use your contact details to answer your inquiries, provide
                                    customer support, and keep you informed about important service
                                    announcements.
                                </li>
                                <li>
                                    <b>Service Improvement:</b>
                                    Analyzing your usage patterns helps us identify areas
                                    for improvement and optimize our Services to better meet your needs.
                                </li>
                                <li>
                                    <b>Legal Compliance:</b>
                                    We may be required to disclose your information to comply
                                    with legal and regulatory requirements, such as responding to subpoenas or
                                    court orders.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3>Sharing Your Information:</h3>
                            <ul className='p-0'>
                                <li>
                                    <b>Service Providers:</b>
                                    We may share your data with trusted partners who assist
                                    us in operating our Services, such as web hosting providers, marketing agencies,
                                    and data analysis companies. These partnerships are governed by strict
                                    confidentiality agreements to ensure your data remains protected.

                                </li>
                                <li>
                                    <b>Business Partners:</b>
                                    When we offer co-branded products or services, your
                                    information may be shared with our partners to facilitate the collaboration. We
                                    carefully select partners who share our commitment to data privacy.
                                </li>
                                <li>
                                    <b>Government Authorities: </b>
                                    In response to legal requests, we may be required
                                    to disclose your information to government authorities, ensuring transparency
                                    and upholding legal obligations.
                                </li>
                                <li>
                                    <b>Transparency and Consent:</b>
                                    We will never sell or rent your personal
                                    information to any third party without your explicit consent. We value your trust
                                    and respect your right to control your data.

                                </li>
                                <li>
                                    <b></b>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3>Data Retention:</h3>
                            <p>We retain your personal information for as long as necessary to fulfill the purposes
                                outlined in this Privacy Policy. This includes providing you with our Services, complying
                                with legal requirements, and resolving potential disputes. Once the retention period
                                expires, we securely delete your data, unless legal obligations necessitate its further
                                storage.
                            </p>
                        </li>
                        <li>
                            <h3>Your Choices and Data Control:</h3>
                            <p>We empower you to make informed choices about your personal information.
                            </p>
                            <ul className='p-0'>
                                <li>
                                    <b>Opting Out of Marketing: </b>
                                    You can unsubscribe from receiving marketing
                                    materials at any time by clicking the "unsubscribe" link in our emails or by
                                    contacting us directly.
                                </li>
                                <li>
                                    <b>Accessing and Updating Your Data:</b>
                                    You have the right to access and
                                    update your personal information through your account settings on our website.

                                </li>
                                <li>
                                    <b>Data Deletion Request:</b>
                                    You can request the deletion of your personal
                                    information by contacting us at [email protected]. We will promptly comply with
                                    your request, subject to any legal or contractual obligations that necessitate data
                                    retention.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3>Security:
                            </h3>
                            <p>We implement robust security measures to safeguard your personal information from
                                unauthorized access, disclosure, alteration, or destruction. These include encryption
                                technologies, secure server infrastructure, and access control protocols. However,
                                please note that no internet transmission is completely secure, and we encourage you
                                to practice safe internet habits.
                            </p>
                        </li>
                        <li>
                            <h3>Cookies and Tracking Technologies:
                            </h3>
                            <p>We implement robust security measures to safeguard your personal information from
                                unauthorized access, disclosure, alteration, or destruction. These include encryption
                                technologies, secure server infrastructure, and access control protocols. However,
                                please note that no internet transmission is completely secure, and we encourage you
                                to practice safe internet habits.
                            </p>
                            <ul className='p-0'>
                                <li>
                                    <b></b>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3>International Transfers:
                            </h3>
                            <p>While BUSINESSI.AI may operate services globally, some data processing might
                                occur in countries with less stringent data protection laws than yours. By using their
                                services, you consent to this potential transfer. However, BUSINESSI.AI should
                                clarify:

                            </p>
                            <ul className='p-0'>
                                <li>
                                    Which countries might your data be transferred to?
                                </li>
                                <li>
                                    What safeguards are in place to protect your data during international transfer?
                                </li>
                                <li>
                                    Do you have the right to request information about where your data is stored or
                                    processed?
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3>Children's Privacy:
                            </h3>
                            <p>BUSINESSI.AI clearly states they don't target children under 13 and won't knowingly
                                collect their data. However, they could further strengthen this section by:

                            </p>
                            <ul className='p-0'>
                                <li>
                                    Highlighting age verification mechanisms used, if any.
                                </li>
                                <li>
                                    Explaining procedures for parents to request deletion of their child's data.
                                </li>
                                <li>
                                    Providing resources for parents and children about online safety.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3>Changes to the Privacy Policy:

                            </h3>
                            <p>While informing users about potential updates is standard, BUSINESSI.AI could
                                enhance transparency by:

                            </p>
                            <ul className='p-0'>
                                <li>
                                    Specifying the types of changes that might trigger an update
                                </li>
                                <li>
                                    Offering different notification methods beyond just website posting, such as email
                                    or in-app alerts.
                                </li>
                                <li>
                                    Providing a clear version history of the policy for reference.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3 >Contact Us:</h3>
                            <p>Providing diverse contact options beyond just an email address would make it easier for
                                users to reach BUSINESSI.AI with privacy concerns.</p>
                            <p>Consider adding:</p>
                            <ul className='p-0'>
                                <li>
                                    A phone number or contact form on the website
                                </li>
                                <li>
                                    Links to social media platforms, if used for customer support.
                                </li>
                                <li>
                                    Information about data protection officers (DPO), if applicable.
                                </li>
                            </ul>
                        </li>
                        <br />
                        <b>To get other information and more details about BUSINESSI.AI, Visit the following
                            link: </b>
                            <a href="https://www.businessi.ai">https://www.businessi.ai</a>
                    </ol>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy