import React, { useEffect, useState } from "react";
import logoCrown from "../../../assets/images/crown.svg";
import { NavLink, useNavigate } from "react-router-dom";
import {
    Bars3BottomRightIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";

import {
    CreditCardIcon,
    UsersIcon,
    ArrowPathIcon,
    ArrowLeftStartOnRectangleIcon,
    ChartPieIcon
} from "@heroicons/react/24/solid";

import Config from "../../../Config";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Notifications from "./Notifications";


function BusinessHeader(props) {
    const Name = localStorage.getItem("Full_Name");
    const Member_Type = localStorage.getItem("Member_Type");
    const MemberPhoto = localStorage.getItem("Img_File");



    let navigate = useNavigate();


    useEffect(() => {
        const handleScroll = () => {
            const header = document.getElementsByClassName('.header-dashboard');
            if (window.scrollY > 300) {
                header.classList.add('sticky');
            } else {
                header.classList.remove('sticky');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const SignOut = () => {
        localStorage.clear();
        navigate("/");
    };



    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);







    return (

        <>


            <Offcanvas className="canvas-menu" show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>MENU</Offcanvas.Title>
                    <button className="btn-close-menu" onClick={handleClose}>
                        <XMarkIcon />
                    </button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul className="canvas-menu-list">
                        {/* <li>
                            <NavLink onClick={() => setShow(false)} to="/dashboard/dashboard">
                                <Squares2X2Icon />
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                       */}
                        <li>
                            <NavLink onClick={() => setShow(false)} to="/dashboard/packages">
                                <CreditCardIcon />
                                <span>Funds</span>
                            </NavLink>
                            <div className="divider"></div>
                        </li>
                      
                        {/* <li>
                            <NavLink onClick={() => setShow(false)} to="/dashboard/addmoney">
                                <CurrencyDollarIcon />
                                <span>Refill</span>
                            </NavLink>
                        </li>
                       */}
                        <li>
                            <NavLink onClick={() => setShow(false)} to="/dashboard/dashboard">
                                <UsersIcon />
                                <span>Crew Details</span>
                            </NavLink>
                            <div className="divider"></div>
                        </li>
                      
                        <li>
                            <NavLink onClick={() => setShow(false)} to="/dashboard/collect-profit">
                                <ChartPieIcon />
                                <span>Collect Share</span>
                            </NavLink>
                            <div className="divider"></div>
                        </li>
                      
                        <li>
                            <NavLink onClick={() => setShow(false)} to="/dashboard/transactions/packages">
                                <ArrowPathIcon />
                                <span>Transactions</span>
                            </NavLink>
                            <div className="divider"></div>
                        </li>
                      
                        {/* <li>
                            <NavLink onClick={() => setShow(false)} to="/dashboard/dashboard">
                                <ArrowDownOnSquareIcon />
                                <span>Withdrawal</span>
                            </NavLink>
                        </li>
                       */}
                        <li>
                            <a type="buttton" onClick={SignOut}>
                                <ArrowLeftStartOnRectangleIcon />
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>

            <header className="header-dashboard" style={{ position: 'fixed', top: '0' }}>
                <div className="header-left">
                    <NavLink to="/dashboard/profile/account">
                        <img src={Config.img_path + MemberPhoto}
                            onError={(e) => { e.target.src = logoCrown; }}
                            alt="Member"
                        />
                    </NavLink>
                    <div className="user-logged-in">
                        <div className="info">
                            <h4>{Name}</h4>
                            <p>{Member_Type == "Investor" ? "Paid" : "Free"}</p>
                        </div>
                    </div>
                </div>
                <div className="header-right">
                    <Notifications />
                    <button className="menu-toggle" onClick={handleShow}>
                        <Bars3BottomRightIcon />
                    </button>
                </div>
            </header>
        </>

    );
}

export default BusinessHeader;