import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import moment from "moment-timezone";

function ServerClock() {
  const [Email] = useState(localStorage.getItem("Email_Address"));
  const [loading, setLoading] = useState(true);
  const [serverTime, setServerTime] = useState(null);

  useEffect(() => {
    const Params = {
      Email_Address: Email,
    };

    const api_config = {
      method: 'post',
      url: `${config.base_url}Country/GetServerTime`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: Params
    };

    axios(api_config)
      .then(function (response) {
        const serverTime = moment(response.data.simpleTime);
        setServerTime(serverTime);
        setLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
        setLoading(false);
      });
  }, [Email]);

  useEffect(() => {
    if (!loading && serverTime) {
      const intervalId = setInterval(() => {
        setServerTime(prevTime => moment(prevTime).add(1, "s"));
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [loading, serverTime]);

  return (
    <>
      {loading || !serverTime ? 'Loading...' : `${serverTime.tz('America/New_York').format('ddd, DD MMM YYYY h:mm:ss A')} EDT`}
    </>
  );
}

export default ServerClock;
