import React, { useEffect, useRef } from "react";
import {NavLink } from "react-router-dom";
import {HomeIcon } from "@heroicons/react/24/outline";
import LoginCheck from "../General/LoginCheck";
import Tick from "../../Common/Tick.json";
import Lottie from "lottie-react";
import "@lottiefiles/lottie-player";


const ProfitSuccess = (props) => {


    const playerRef = useRef();

    useEffect(() => {
        playerRef.current?.playFromBeginning();
    }, [])


    return (
        <>
            <LoginCheck />
            <div className="mobile-container">
            <div className="section-title mb-3">Self Reward</div>
                <div className="theme-card mt-3 p-4">
                    <div className="d-flex justify-content-center">
                        <Lottie loop={true} animationData={Tick} className="w-75" />
                    </div>
                    <h3 className="text-white text-center">Congratulations!</h3>
                    <p className="text-center">
                        you have successfully collected your Self Reward for today!
                    </p>
                </div>
                <div className="mt-2 d-flex justify-content-center">
                    <NavLink to={`/dashboard/dashboard`} className="button button-icon-primary align-items-end">
                        <HomeIcon /> Back to Home
                    </NavLink>
                </div>
            </div>
        </>
    )
}

export default ProfitSuccess