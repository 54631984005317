import React, { useEffect, useState } from "react";
import config from "../../../Config";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { CheckCircleIcon, KeyIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import LoginCheck from "../General/LoginCheck";
import Services from "../../Conditions/Services";

const PackagePurchase = (props) => {


    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const PackageData = props.PackageData;

    const [showterms, setShowterms] = useState(false);

    const handleCloseterms = () => setShowterms(false);
    const handleShowterms = () => setShowterms(true);



    const [packagePurchased, setpackagePurchased] = useState(false);
    const [ListofPackages, setListofPackages] = useState([]);
    const [balance, setBalance] = useState(0);
    // loading states
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const [AutoRenew, setAutoRenew] = useState(false);
    const [Compounding, setCompounding] = useState(false);
    const [VoucherID, setVoucherID] = useState(0);
    const [user, setUser] = useState({});

    const [downlineLoading, setDownlineLoading] = useState(false);
    const [downlineUserName, setDownlineUsername] = useState(null);
    const [downlineVerified, setDownlineVerified] = useState(false);

    const [OTP, setOTP] = useState(null);

    const [Verified, setVerified] = useState(null);
    const navigate = useNavigate();


    // loadings

    const [SelectActive, setSelectActive] = useState('Self');

    let params = useParams();
    const id = params;



    useEffect(() => {
        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Package/GetAllPackages',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };

        axios(api_config)
            .then(function (response) {
                // console.log("Modal", response.data);
                if (response.data.status_code == 1) {
                    setBalance(response.data);
                    setListofPackages(response.data.ListofPackages);
                }
                else if (
                    (response.data.status_message === "Invalid Access Key.")
                ) {
                    navigate("/login");
                }
                else {

                    setLoading(false);
                }

            })
            .catch(function (error) {
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000
                    //onClose: () => window.alert('Called when I close')
                });
            });

    }, [packagePurchased]);


    const [Conditions, setConditions] = useState(false);

    const VerifyUser = () => {
        if (downlineUserName != null) {

            setDownlineLoading(true);

            const downlineData = {
                Email_Address: downlineUserName,
                AccessKey: AccessKey,
                UserID: UserID
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/GetBalanceTransferInfo',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: downlineData
            };
            // console.log(downlineData);
            axios(api_config)
                .then(function (response) {
                    // console.log(response.data);
                    if (response.data.status_code == 1) {
                        setUser(response.data);
                        setDownlineVerified(true);
                        setDownlineLoading(false);

                    }
                    else {
                        setDownlineVerified(false);
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setDownlineLoading(false);
                    }

                })
                .catch(function (error) {
                    // console.log(error);
                });

        }
    }

    const handleConfirmPurchase = () => {

        setLoadingButton(true);

        if (SelectActive != 'Others') {
            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                PinCode: OTP,
                AutoRenew: AutoRenew,
                Compounding: Compounding,
                PackageID: params.id,
                VoucherID: VoucherID,
                Amount: props.amount

            };
            // console.log("self-json", data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Package/PurchasePackage',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {

                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setpackagePurchased(true);
                        // window.location = '/dashboard/dashboard';
                        navigate("/dashboard/packages/success");
                        setLoadingButton(false);
                        setTimeout(() => {
                            props.handleClose();
                        }, 2000);
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoadingButton(false);
                    setpackagePurchased(false);

                })
                .catch(function (error) {
                    setLoadingButton(false);

                });
        }
        else {
            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                PinCode: OTP,
                Email_Address: downlineUserName,
                PackageID: params.id,
                Amount: props.amount

            };
            // console.log("Others-json", data);
            var api_config = {
                method: 'post',
                url: config.base_url + 'Package/PurchasePackageDownline',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {

                    // console.log("self", response.data)
                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setpackagePurchased(true);
                        window.location = '/dashboard/dashboard';
                        props.handleClose();
                        setLoadingButton(false);
                    }
                    else {
                        setLoadingButton(false);
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoadingButton(false);
                    setpackagePurchased(false);

                })
                .catch(function (error) {

                });
        }

    }

    const ValueNull = () => {
        setDownlineUsername(null);
        setOTP(null);
        setConditions(false);
        setDownlineVerified(false);
    }

    return (
        <>
            <Modal centered show={showterms} onHide={handleCloseterms} className="modal-theme transparent">
                <Modal.Header >
                    <Modal.Title>Terms and Conditions</Modal.Title>
                    <button className="btn-close" onClick={handleCloseterms}>
                        <XMarkIcon />
                    </button>
                </Modal.Header>
                <Modal.Body><Services /></Modal.Body>
            </Modal>

            <LoginCheck />
            <Modal centered show={props.show} onHide={props.handleClose} onExit={ValueNull} className="modal-theme transparent purchase-now-card form-default">
                <Modal.Header>
                    <Modal.Title>Add Funds</Modal.Title>
                    <button className="btn-close" onClick={props.handleClose}>
                        <XMarkIcon />
                    </button>
                </Modal.Header>


                <Modal.Body style={{ background: '#000' }}>

                    <div className="package-card-view w-100">
                        <div className="package-card w-100 m-0" style={{ backgroundImage: `url(${config.root + "Images/Packages/" + PackageData.Image})` }}>
                            <div className="content">
                                <div className="package-header">
                                    <h4>{PackageData.Name}</h4>
                                </div>
                                <div className="detail">
                                    <span className="price">${PackageData.MinAmount} - ${PackageData.MaxAmount}</span>
                                </div>
                            </div>
                            <div className="content">
                                <div className="package-header">
                                    <p className="m-0 text-white">Selected for Funding</p>
                                </div>
                                <div className="detail">
                                    <span className="price">$ {props.amount}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 
                    <ul className="menu-option mb-3 mx-auto">
                        <li>
                            <a className={`nav-link ${SelectActive == "Self" ? "active" : ""}`} onClick={(e) => setSelectActive("Self", e)} href="#">Self Purchase</a>
                        </li>
                        <li>
                            <a className={`nav-link ${SelectActive == "Others" ? "active" : ""}`} onClick={(e) => setSelectActive("Others", e)} href="#">Purchase for Others</a>
                        </li>
                    </ul>

                    {SelectActive === 'Others' ?
                        <>
                            <div className="condition">
                                <div className="verify">
                                    <div className="icon-input m-0">
                                        <input type="text"
                                            value={downlineUserName}
                                            onChange={(e) => {
                                                setDownlineUsername(e.target.value);
                                                setDownlineVerified(false);
                                            }}
                                            placeholder="Enter Username/Email"
                                            autoComplete="off"
                                            className="form-control"
                                        />
                                        <div className="icon">
                                            {
                                                downlineVerified ?
                                                    <CheckCircleIcon />
                                                    :
                                                    ''

                                            }
                                        </div>
                                    </div>
                                    <button
                                        onClick={VerifyUser}
                                        className="button button-3d-outline button-icon-bs mt-3" type="button"
                                        disabled={downlineVerified}
                                    >
                                        <span>{downlineLoading ? 'Loading..' : 'Validate'}</span>
                                        <ShieldCheckIcon />
                                    </button>
                                </div>
                            </div>
                            <div className="balance">
                                <label>Safe Balance</label>
                                <span>USD {NumberFormat(balance.member_balance)}</span>
                            </div>
                            <div className="help">
                                <label htmlFor="">Insufficient Balance?</label>

                                <NavLink to={`/dashboard/addmoney`} className="button button-icon-secondary">
                                    Refill <ArrowPathRoundedSquareIcon />
                                </NavLink>



                            </div>
                        </>
                        :
                        <>
                            <div className="balance">
                                <label>Safe Balance</label>
                                <span>USD {NumberFormat(balance.member_balance)}</span>
                            </div>
                            <div className="help">
                                <label htmlFor="">Insufficient Balance?</label>
                                <NavLink to={`/dashboard/addmoney`} className="button button-icon-secondary">
                                    <ArrowPathRoundedSquareIcon /> Refill
                                </NavLink>
                            </div>

                        </>
                    } */}


                    <div className="icon-input w-100">
                        <input
                            type="number"
                            onChange={(e) => {
                                if (e.target.value.length <= 6) {
                                    setOTP(e.target.value);
                                }
                            }}
                            value={OTP}
                            className="text-center form-control"
                            placeholder="Enter Transaction Pin"
                            maxLength={6}
                            required
                        />
                        <div className="icon">
                            {

                                Verified == null ?
                                    <KeyIcon />
                                    :
                                    Verified ?
                                        <CheckCircleIcon />
                                        :
                                        <XCircleIcon />
                            }
                        </div>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value={Conditions} id="flexCheckChecked" onChange={() => setConditions(!Conditions)} />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                            I Accept <span className="text-primary" onClick={handleShowterms}>Terms & Condition </span>
                        </label>
                    </div>
                    <div className="row align-items-center gy-3">
                        <div className="col-12">
                            <button
                                className="button button-3d-primary w-100"
                                onClick={handleConfirmPurchase}
                                disabled={!Conditions || loadingButton || OTP == null || OTP.length <= 0 || (SelectActive === 'Others' ? (downlineVerified ? false : true) : false)}
                            >
                                Add Fund {loadingButton ? '...' : ''}
                            </button>

                        </div>
                        <div className="col-12">
                            <button className="button button-3d-outline w-100" onClick={props.handleClose}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default PackagePurchase