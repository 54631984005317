import React, { useEffect, useState } from 'react'
import ProfileMenu from './ProfileMenu'
import { NavLink, useNavigate } from 'react-router-dom'
import LoginCheck from '../General/LoginCheck'
import Config from '../../../Config'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'

const ProfileKyc = () => {
    const AccessKey = (localStorage.getItem("AccessKey"));
    const UserID = (localStorage.getItem("ID"));

    const [Kycstatus, setKycstatus] = useState('');
    const [loadingKyc, setLoadingKyc] = useState(false);
    let navigate = useNavigate();
    useEffect(() => {

        setLoadingKyc(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'MemberKYC/GetMemberKYC',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    if (response.data.CurrentStatus === "Under Review") {
                        setLoadingKyc(false);
                        setKycstatus("Under Review");
                    }
                    else if (response.data.CurrentStatus === "Approved") {
                        setLoadingKyc(false);
                        setKycstatus("Approved");
                    }
                    else {
                        setLoadingKyc(false);
                    }
                }

                else if (
                    (response.data.status_message === "Invalid Access Key.")
                ) {
                    navigate("/login");
                } else {
                    setLoadingKyc(false);
                }
            })
            .catch(function (error) {
                toast.error("Network Error..", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000
                    //onClose: () => window.alert('Called when I close')
                });
            });
    }, []);


    return (
        <>
            <LoginCheck />
            <ProfileMenu />
            <div className="profile-bg-card">
                <div className="row pt-3">
                    <div className="col-12">
                        {loadingKyc ?
                            <div className="text-center">
                                <Spinner animation="border" role="status" className="full-screen-loading"></Spinner>
                            </div>
                            :
                            <>
                                {Kycstatus == 'Under Review' ?
                                    <div className="kyc-box">
                                        <h1>
                                            KYC Submission Completed
                                        </h1>
                                        <p>
                                            Your document is successfully submitted and is under review. It
                                            may take 24-48 hours. Thank you for your patience.
                                        </p>
                                        <NavLink className={'button button-3d-primary'} to={`/dashboard/kyc/submitted`}>VIEW DETAILS</NavLink>
                                    </div>
                                    :
                                    Kycstatus == 'Approved' ?
                                        <div className="kyc-box">
                                            <h1>KYC Approved</h1>
                                            <p>
                                                <b className='text-primary'>Congratulations!</b> You are a verified member of Business Intelligence. You can now use BI.AI’s resources to reach your financial goals.
                                            </p>
                                            <NavLink className={'button button-3d-primary'} to={`/dashboard/kyc/congratulations`}>VIEW DETAILS</NavLink>
                                        </div>
                                        :
                                        <div className="kyc-box">
                                            <h1>KYC Submit</h1>
                                            <p>
                                                Please submit your KYC to get full access on your account.
                                            </p>
                                            <NavLink className={'button button-3d-primary'} to={`/dashboard/kyc`}>Add </NavLink>
                                        </div>
                                }
                            </>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileKyc