import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../../../Config';
import LoginCheck from '../General/LoginCheck';

function CoinSuccess(props) {

    const AccessKey =(localStorage.getItem("AccessKey"));
   const UserID =(localStorage.getItem("ID"));


    useEffect(() => {

        document.title = "Coin Payment Success";
    }, []);


    return (
        <>
            <LoginCheck />
            <div className='row'>
                <div className='col-md-4 offset-md-4'>
                    <div className='card card_lg'>
                        <h4>Payment has been completed.</h4>
                        <p>
                            Your payment transaction has been successfully completed. We would like to take this opportunity to thank you for choosing our services.
                        </p>
                        <NavLink to="/dashboard/transactions/deposits">View all requests</NavLink>
                    </div>
                </div>

            </div>
        </>
    );
}

export default CoinSuccess;