import React, { useEffect, useState } from "react";
import config from "../../../Config";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import {
  ArrowLeftIcon,
  CheckBadgeIcon,
  FaceFrownIcon,
  KeyIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { toast, ToastContainer } from "react-toastify";
import LoginCheck from "../General/LoginCheck";
import moment from "moment";
import Tick from "../../Common/Tick.json";
import Lottie from "lottie-react";
import "@lottiefiles/lottie-player";

const MyPackages = () => {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");

  // packages

  const [Loading, setLoading] = useState(false);
  const [isDeleted, setisDeleted] = useState(false);
  const [PackageID, setPackageID] = useState("");
  const [PinCode, setPinCode] = useState("");
  const [price, setPrice] = useState("");
  const [PackagePrice, setPackagePrice] = useState("");
  const [PurchasedPackage, setPurchasedPackage] = useState([]);
  const [show, setShow] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Package/GetMemberPackages",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setPurchasedPackage(response.data.ListofPackages);
          setLoading(false);
        }
        else if (
          (response.data.status_message === "Invalid Access Key.")
        ) {
          navigate("/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setLoading(false);
        }
      })
      .catch(function (error) { });
  }, [isDeleted]);



  const renderPackages = () => {
    if (PurchasedPackage != null && PurchasedPackage.length > 0) {
      return PurchasedPackage.map((item, index) => {
        return (
          <div className="col-md-12" key={index}>
            <div
              className={`package-card ${item.selectedPackage.ID} ${item.selectedPackage.ID === 1
                ? "primary"
                : item.selectedPackage.ID === 2
                  ? "secondary"
                  : item.selectedPackage.ID === 3
                    ? "blue"
                    : item.selectedPackage.ID === 4
                      ? "red"
                      : "blue"
                }`}
              style={{
                backgroundImage: `url(${config.root + "Images/Packages/" + item.selectedPackage.Image
                  })`,
              }}
            >
              <div className="content">
                <div className="package-header">
                  <h4>{item.selectedPackage.Name}</h4>
                </div>
                <div className="detail">
                  <span
                    className={`price ${item.selectedPackage.ID === 1
                      ? "primary"
                      : item.selectedPackage.ID === 2
                        ? "secondary-text"
                        : item.selectedPackage.ID === 3
                          ? "blue-text"
                          : item.selectedPackage.ID === 4
                            ? "red-text"
                            : "blue-text"
                      }`}
                  >
                    Funding : $ {item.PackagePrice} <br/>
                    Remaining: $ {item.TwoXPending / 2}
                  </span>
                </div>
                {/* <div className={`package-icon top-0 ${item.selectedPackage.ID === 1 ? 'icon-primary' : item.selectedPackage.ID === 2 ? 'icon-secondary' : item.selectedPackage.ID === 3 ? 'icon-blue' : item.selectedPackage.ID === 4 ? 'icon-red' : 'icon-blue'}`}>
                  <span>  {item.IsClosed && <CheckBadgeIcon style={{ width: '30px' }} />}</span>
                </div> */}
                <h5 className="text-white mt-3 mb-1 fs-5">Funds Detail</h5>
                <ul className="list">
                  <li>
                    <label>Funding Date</label>
                    <span>
                      {moment(item.BuyingDate).format("DD MMMM YYYY")}
                    </span>
                  </li>
                  <li>
                    <label>Gains:</label>
                    <span>$ {item.TwoXAmount}</span>
                  </li>
                  <li>
                    <label>Received:</label>
                    <span>$ {item.TwoXReceived}</span>
                  </li>
                  <li>
                    <label>Pending:</label>
                    <span>$ {item.TwoXPending}</span>
                  </li>
                </ul>
                <div className="package-footer completed">
                  {item.IsClosed && <Lottie loop={true} animationData={Tick} />}
                  <div
                    className={`
                       ${item.selectedPackage.ID === 1
                        ? "primary-text"
                        : item.selectedPackage.ID === 2
                          ? "secondary-text"
                          : item.selectedPackage.ID === 3
                            ? "blue-text"
                            : item.selectedPackage.ID === 4
                              ? "red-text"
                              : "blue-text"
                      }`}
                  >
                    {item.IsClosed && <>
                      <span className="fw-bold fs-4 mb-2 d-inline-block">Congratulations!</span> <br /> your 2x Funding Gain has been achieved.
                    </>}
                    {item.IsClosed != true && <>
                      <span className="fw-bold fs-4 d-inline-block secondary-text">Active</span>
                    </>}

                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="else-emoji mt-5">
          <FaceFrownIcon />
          <h4>No Data Found</h4>
        </div>
      );
    }
  };
  const handleCancle = () => {
    setisDeleted(false);
    var data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: PackageID,
      PinCode: PinCode,
    };
    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Package/CancelPackage",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        // console.log(response.data);

        if (response.data.status_code == 1) {
          setShow(false);
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setisDeleted(true);
          setTimeout(() => {
            navigate("/dashboard/dashboard");
          }, 3000);
        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setisDeleted(false);
        }
      })
      .catch(function (error) { });
  };

  const handleClose = () => setShow(false);
  const handleShow = (id, price) => {
    setPackageID(id);
    setShow(true);
    setPrice(price);
    setPackagePrice((price * 0.3).toFixed(2));
  };

  // console.log(Loading, 'Loading')
  return (
    <>
      <LoginCheck />
      <ToastContainer />
      <Modal
        centered
        show={show}
        onHide={handleClose}
        className="modal-theme transparent form-default"
      >
        <Modal.Header closeButton>
          <Modal.Title>Revert Funds</Modal.Title>
          <button className="btn-close" onClick={handleClose}>
            <XMarkIcon />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div
            className="theme-card p-3 mt-3 d-flex justify-content-between align-center"
            style={{ backgroundColor: "#161e26" }}
          >
            <p className="mb-0">Funds Amount</p>
            <h3 className="m-0 text-primary">${price}</h3>
          </div>
          <div
            className="theme-card p-3 mt-3 d-flex justify-content-between align-center"
            style={{ backgroundColor: "#161e26" }}
          >
            <p className="mb-0">30% Deduction</p>
            <h3 className="m-0 text-primary">(${PackagePrice})</h3>
          </div>
          <div
            className="theme-card p-3 my-3 d-flex justify-content-between align-center"
            style={{ backgroundColor: "#161e26" }}
          >
            <p className="mb-0">Remaining Amount</p>
            <h3 className="m-0 text-primary">
              ${(price - PackagePrice).toFixed(2)}
            </h3>
          </div>

          <div className="icon-input w-100">
            <input
              type="number"
              onChange={(e) => {
                if (e.target.value.length <= 6) {
                  setPinCode(e.target.value);
                }
              }}
              value={PinCode}
              className="form-control"
              placeholder="Enter Pin Code"
              maxLength={6}
              required
            />
            <div className="icon">
              <KeyIcon />
            </div>
          </div>
          <div className="text-center">
            <button
              className="button button-3d-primary mt-3 text-center w-100"
              onClick={handleCancle}
            >
              Revert Funds
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <div className="row">
        <div className="button-row justify-content-between px-4">
          <div className="button-arrow-back">
            <Link to={`/dashboard/packages`}>
              <ArrowLeftIcon />
              Back
            </Link>
          </div>
          <h2 className="section-title">My Funds</h2>
        </div>
        {Loading ? (
          <Spinner
            animation="border"
            role="status"
            className="full-screen-loading"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          renderPackages()
        )}
      </div>
    </>
  );
};

export default MyPackages;
