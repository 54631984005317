import React, { useEffect, useState } from "react";

import NetworkMember from "./NetworkMember";
import axios from "axios";
import config from "../../../Config";
import { Spinner } from "react-bootstrap";
import BsPagination from "../BsPagination";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Network(props) {

  let navigate = useNavigate();

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);

  const [members, setMembers] = useState([]);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPaid, settotalPaid] = useState(0);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    document.title = "Network";
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/ViewNetworkPage",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setMembers(response.data.ListofMembers);
          setTotalRecords(response.data.totalRecords);
          setLoading(false);
          setHasData(true);
        }
        else if (
          (response.data.status_message === "Invalid Access Key.")
        ) {
          navigate("/login");
        } else {
          setLoading(false);
          setHasData(false);
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      })
      .catch(function (error) { });
  }, [pageNo, pageSize]);

  const renderMembers = () => {
    if (members != null && members.length > 0) {
      return members.map((item, index) => {
        return (
          <NetworkMember
            key={index}
            photo={config.img_path + item.Img_File}
            name={item.Full_Name}
            email={item.Email_Address}
            type={item.Member_Type}
            amount={item.Investment_Balance}
            level={item.Hiera_IN_xx_NodeLevel}
            ROI_Balance={item.ROI_Balance}
            Profit_Received={item.Profit_Received}
            TeamCount={item.TeamCount}
          />
        );
      });
    } else {
      return (
        <div className="else-emoji mt-5">
          <FaceFrownIcon />
          <h4>No Data Found</h4>
        </div>
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <h3 className="section-title">Crew</h3>

      {loading ? (
        <Spinner
          animation="border"
          role="status"
          className="full-screen-loading"
        ></Spinner>
      ) : (
        <>
          <div className="network-list">
            {hasData ? <ul>{renderMembers()}</ul> : ""}
          </div>
          <div className="pagination-wrap">
            {totalRecords > 10 && (
              <BsPagination
                length={members.length}
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={(page) => setPageNo(page)}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                  setPageNo(1);
                }}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Network;
