import React, { useState, useEffect, useRef } from "react"
import { ArrowsRightLeftIcon, ArrowUpRightIcon, ArrowUturnLeftIcon, BanknotesIcon, CreditCardIcon } from "@heroicons/react/24/outline"
import { NavLink, useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import Config from "../../Config";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import LoginCheck from "./General/LoginCheck";
import Winner from "../Common/winner.json";
import ReactTooltip from "react-tooltip";
import dividerH from '../../assets/images/divider-h.svg';
import btnRefill from '../../assets/images/buttons/refil.svg';
import btnPayout from "../../assets/images/buttons/payout.svg";
import btnTransfer from "../../assets/images/buttons/transfer.svg";
import teambox1 from "../../assets/images/teambox1.svg";
import teambox2 from "../../assets/images/teambox2.svg";
import promotion from "../../assets/images/promotion.jpg";
import dividends from "../../assets/images/animation/dividends.json";
import "@lottiefiles/lottie-player";
import { Player } from '@lordicon/react';
import UserList from "./UserList";
import WithdrawalList from "./WithdrawalList";
import RefLink from "./RefLink";
import NewMembersCount from "./NewMembersCount";
import CountUp from 'react-countup';
import moment from "moment";
import Countdown from "../Common/Countdown";
import Lottie from "lottie-react";
import support from "../../assets/images/support.svg";
import AssistiveButton from "../Common/AssistiveButton";

const BusinessDashboard = (props) => {

    let navigate = useNavigate();

    const AccessKey = (localStorage.getItem("AccessKey"));
    const UserID = (localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [MemberInfo, setMemberInfo] = useState({});
    const [DashData, setDashData] = useState({});
    const [placementUpdated, setPlacementUpdated] = useState(false);

    const [promoEndTime, setpromoEndTime] = useState("");

    const playerRef = useRef();
    const totalEarningsRef = useRef();





    useEffect(() => {
        if (!loading) {
            playerRef.current?.playFromBeginning();
            totalEarningsRef.current?.playFromBeginning();
        }
    }, [loading])

    useEffect(() => {

        setLoading(true);
        setPlacementUpdated(false);
        const Parms = {
            AccessKey: AccessKey,
            UserID: UserID,

        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetDashboardData',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Parms
        };
        // console.log(Parms);
        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {


                    setMemberInfo(response.data.Member);
                    setDashData(response.data);
                    setpromoEndTime(response.data.promoEndTime);

                    // disable access to dashboard if email not verified
                    // if (response.data.Member?.Email_Address_Verified == true) {

                    // }
                    // else {
                    //     navigate('/signup/verify-email');
                    // }


                    setLoading(false);
                } else if (
                    (response.data.status_message === "Invalid Access Key.")
                ) {
                    navigate("/login");
                }


            })
            .catch(function (error) {
                // console.log(error);
            });



    }, []);




    return (
        <>
            <LoginCheck />
            <ReactTooltip />
            <ToastContainer />
            {/* <Loading /> */}
            {
                loading ?
                    <Spinner animation="border" role="status" className="full-screen-loading">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    :
                    <>
                        <AssistiveButton />
                        <div style={{ marginTop: '-10px' }}>
                            <div className="theme-card balance-card  bg-safe">
                                <div className="balance">
                                    <p>Safe</p>
                                    <h2>$ <CountUp end={MemberInfo.Current_Balance} decimals={2} /></h2>
                                </div>
                                <ul className="user-history">
                                    <li>
                                        <h6>IP Address</h6>
                                        <span>{DashData.lastLoginIP}</span>
                                    </li>
                                    <li>
                                        <h6>Last Login Time</h6>
                                        <span>{moment(DashData.lastLoginTime).format('l, h:mm:ss a')}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="mobile-container">
                            <div className="inline-buttons mt-3">
                                <NavLink to="/dashboard/addmoney">
                                    <img src={btnRefill} />
                                </NavLink>
                                <NavLink to="/dashboard/withdraw">
                                    <img src={btnPayout} />
                                </NavLink>
                                <NavLink to="/dashboard/balance-transfer">
                                    <img src={btnTransfer} />
                                </NavLink>
                            </div>
                        </div>

                        <div className="future-safe mt-3">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <div className="future-safe-balance">
                                        <NavLink to="/dashboard/transactions/packages?activemenu=Funds">
                                            <p>Future Safe</p>
                                            <h3>$ <CountUp end={DashData.remainingFunding} decimals={2} /></h3>
                                        </NavLink>
                                        <NavLink to="/dashboard/packages/my-packages" className="button button-line p-0 text-white">
                                            <ArrowUturnLeftIcon />
                                            My Funds
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="buy-more-wrap">
                                        <NavLink to="/dashboard/packages">
                                            <ArrowUpRightIcon />
                                            <strong>Funds</strong>
                                            <span>Add Funds</span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rewards">

                            <div className="theme-card pt-3" style={{ borderRadius: 0 }}>
                                <div className="section-title">Rewards</div>
                                <div className="rewards-card">
                                    <div className="reward-title">
                                        <p>Direct Reward</p>
                                        <h2>$ <CountUp end={DashData.DirectBonus} decimals={2} /></h2>
                                    </div>
                                    <div className="reward-head">
                                        <p>Tier</p>
                                        <p>Current %</p>
                                    </div>
                                    <ul className="reward-list">
                                        <li>

                                            <NavLink to="/dashboard/transactions/level/one?activemenu=L1 Direct Reward"></NavLink>
                                            <div className="left">
                                                <div className="count">
                                                    1
                                                </div>
                                                <h3>$ <CountUp end={DashData.DirectBonusL1} decimals={2} /></h3>
                                            </div>
                                            <h4>{DashData.D1Percent}%</h4>
                                        </li>
                                        <li>
                                            <NavLink to="/dashboard/transactions/level/two?activemenu=L2 Direct Reward"></NavLink>
                                            <div className="left">
                                                <div className="count">
                                                    2
                                                </div>
                                                <h3>$ <CountUp end={DashData.DirectBonusL2} decimals={2} /></h3>
                                            </div>
                                            <h4>{DashData.D2Percent}%</h4>
                                        </li>
                                        <li>
                                            <NavLink to="/dashboard/transactions/level/three?activemenu=L3 Direct Reward"></NavLink>
                                            <div className="left">
                                                <div className="count">
                                                    3
                                                </div>
                                                <h3>$ <CountUp end={DashData.DirectBonusL3} decimals={2} /></h3>
                                            </div>
                                            <h4>{DashData.D3Percent}%</h4>
                                        </li>
                                    </ul>
                                </div>
                                <div className="rewards-card secondary">
                                    <div className="reward-title">
                                        <NavLink className='text-white' to={`/dashboard/transactions/profit-wallet/roi/crew?activemenu=Crew Reward`}>Crew Reward</NavLink>
                                        <h2>$ <CountUp end={DashData.Member?.ActiveWallet} decimals={2} /></h2>
                                    </div>
                                    <div className="reward-head">
                                        <p>Tier</p>
                                        <p>Current %</p>
                                    </div>
                                    <ul className="reward-list">
                                        <li>
                                            <NavLink to="/dashboard/transactions/roi/first?activemenu=L1 Crew Reward"></NavLink>
                                            <div className="left">
                                                <div className="count">
                                                    1
                                                </div>
                                                <h3>$ <CountUp end={DashData.TeamRewardL1} decimals={2} /></h3>
                                            </div>
                                            <h4>{DashData.T1Percent}%</h4>
                                        </li>
                                        <li>
                                            <NavLink to="/dashboard/transactions/roi/second?activemenu=L2 Crew Reward"></NavLink>
                                            <div className="left">
                                                <div className="count">
                                                    2
                                                </div>
                                                <h3>$ <CountUp end={DashData.TeamRewardL2} decimals={2} /></h3>
                                            </div>
                                            <h4>{DashData.T2Percent}%</h4>
                                        </li>
                                        <li>
                                            <NavLink to="/dashboard/transactions/roi/third?activemenu=L3 Crew Reward"></NavLink>
                                            <div className="left">
                                                <div className="count">
                                                    3
                                                </div>
                                                <h3>$ <CountUp end={DashData.TeamRewardL3} decimals={2} /></h3>
                                            </div>
                                            <h4>{DashData.T3Percent}%</h4>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="total-earnings">
                            <div className="info">
                                <p>Total Earnings</p>
                            </div>
                            <div className="icon">
                                <Player
                                    size={80}
                                    ref={totalEarningsRef}
                                    icon={dividends}
                                    onComplete={() => totalEarningsRef.current?.playFromBeginning()}
                                />
                                <h4>$ <CountUp end={DashData.TotalEarnings} /></h4>
                            </div>
                        </div>


                        <div className="mobile-container">
                            <div className="row g-2 mt-3">
                                <div className="col-4">
                                    <NavLink to="/dashboard/transactions/withdraw?activemenu=Payout" className="dash-icon-box">
                                        <div className="icon">
                                            <CreditCardIcon />
                                        </div>
                                        <p>Total <br />Payout</p>
                                        <h3>$ {DashData.TotalWithdraw}</h3>
                                    </NavLink>
                                </div>
                                <div className="col-4">
                                    <NavLink to="/dashboard/transactions/deposits?activemenu=Refill" className="dash-icon-box yellow">
                                        <div className="icon">
                                            <BanknotesIcon />
                                        </div>
                                        <p>Total <br />Refill</p>
                                        <h3>$ {DashData.TotalDeposit}</h3>
                                    </NavLink>
                                </div>
                                <div className="col-4">
                                    <NavLink to="/dashboard/transactions/funds-history/sent?activemenu=Transfers" className="dash-icon-box red">
                                        <div className="icon">
                                            <ArrowsRightLeftIcon />
                                        </div>
                                        <p>Total <br />Transfers</p>
                                        <h3>$ {DashData.TotalTrasnfers}</h3>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="mobile-container">



                            <div className="card-shadow mt-3 primary">
                                <div className="p-4 pb-0">
                                    <h3 className="theme-shadow-title primary text-center">Recent Crew</h3>
                                    {/* <p className="text-center mb-0">Recent Crew</p> */}
                                </div>

                                <div className="recent-team-members">
                                    <img src={dividerH} />

                                    <div id="carouselExample" className="carousel slide">
                                        <div className="carousel-inner">

                                            {DashData.RecentMembersList != null && DashData.RecentMembersList.length > 0 && DashData.RecentMembersList.slice(0, 8).map((item, index) =>
                                                <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                    <div className="member-item" key={index} data-tip={item.Full_Name} data-place="top" >

                                                        <div className="photo">
                                                            <img style={{ borderRadius: "50%", border: "2px solid #FFBE18" }} src={Config.path + 'Personal/MemberImages/' + item.Img_File} alt="User Avatar" />
                                                        </div>
                                                        <div className="content">
                                                            <h4>{item.Full_Name}</h4>
                                                            <p>{item.Email_Address}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}





                                        </div>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>


                                    {/* <Carousel activeIndex={index} onSelect={handleSelect}>


                            <ul>
                                {DashData.RecentMembersList != null && DashData.RecentMembersList.length > 0 && DashData.RecentMembersList.slice(0, 8).map((item, index) =>
                                    <Carousel.Item>
                                        <li key={index} data-tip={item.Full_Name} data-place="top" >

                                {DashData.RecentMembersList != null && DashData.RecentMembersList.length > 0 && DashData.RecentMembersList.slice(0, 4).map((item, index) =>
                                    <div class="carousel-item">
                                        <div className="recent-team-members">
                                            <div className="photo">
                                                <img style={{ borderRadius: "50%", border: "2px solid #FFBE18" }} src={Config.path + 'Personal/MemberImages/' + item.Img_File} alt="User Avatar" />
                                            </div>
                                            <div className="content">
                                                <p>{item.Full_Name}</p>
                                                <h4>{item.Email_Address}</h4>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </ul>
                        </Carousel> */}
                                    <img src={dividerH} />
                                </div>

                                <ul className="team-count">
                                    <li>
                                        <img src={teambox1} />
                                        <div className="count-content">
                                            <p>Total Crew</p>
                                            <h3>{DashData.TotalTeam}</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={teambox2} />
                                        <div className="count-content">
                                            <p>First Tier</p>
                                            <h3>{DashData.TotalDirect}</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={teambox1} />
                                        <div className="count-content">
                                            <p>Second Tier</p>
                                            <h3>{DashData.SecondLevel}</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={teambox2} />
                                        <div className="count-content">
                                            <p>Third Tier</p>
                                            <h3>{DashData.ThirdLevel}</h3>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <RefLink User_Name={MemberInfo.User_Name} />
                        </div >
                        <NewMembersCount />

                        {
                            moment(DashData.promoEndTime).isAfter(moment()) ? (
                                <>
                                    <div className="promotion position-relative" style={{ backgroundColor: '#111828' }}>

                                        {
                                            DashData.promoPercentage == '100.00' ?
                                                <>
                                                    <div className="">
                                                        <div className="d-flex justify-content-center">
                                                            <Lottie loop={true} animationData={Winner} className="w-50" />
                                                        </div>
                                                        <h2 className="text-white text-center"
                                                            style={{
                                                                fontSize: 18,
                                                                fontWeight: "normal",
                                                                maxWidth: 300,
                                                                margin: "auto",
                                                                lineHight: "25px",
                                                                marginTop: '-41px'
                                                            }}>
                                                            Congratulations You have successfully collected your Crew bonus.
                                                        </h2>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <img src={promotion} className="img-fluid" />
                                                    <div className={`task ${DashData.promoPercentage == '100' ? 'completed' : ''}`}>
                                                        <div className="rank-progress">
                                                            <div className="bar" style={{ '--progress-width': `${DashData.promoPercentage}%` }}></div>
                                                            <div className="bar-bg"></div>
                                                        </div>

                                                        <div className="content mt-2 d-flex align-content-center justify-content-between">
                                                            <label className="text-white">Your Progress</label>
                                                            <b>{DashData.promoPercentage}%</b>
                                                        </div>
                                                    </div>

                                                    <h4 className="text-center my-4 text-white">Remaining Time</h4>
                                                    <div className="dash-countdown">

                                                        <Countdown EndDate={promoEndTime} class='countdown-timer' />
                                                    </div>
                                                </>
                                        }

                                    </div>
                                </>
                            ) : (
                                ""
                            )
                        }


                        <div className="subscribers-box">
                            <div className="">
                                <h3 className="theme-shadow-title secondary text-center pt-4 mb-4">Recent Subscribers </h3>
                                <div className="slider-marquee vertical-marquee">
                                    <div className="slider-marquee__track">
                                        <ul className="slider-marquee__grid recent-members-list">
                                            <UserList data={DashData.ListofSubscriber} />
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <WithdrawalList data={DashData.ListofPayouts} />
                    </>
            }
        </>
    )
}

export default BusinessDashboard