import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "./assets/css/style.css";
// import "./assets/css/circle.css";

// slick slider

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import BusinessPackages from "./components/business/Packages/BusinessPackages.js";
import PackagesDetails from "./components/business/Packages/PackagesDetails.js";
import BusinessMain from "./components/business/BusinessMain.js";
import BusinessDashboard from "./components/business/BusinessDashboard.js";
import Login from "./components/account/Login.js";
import PartnerSignUp from "./components/account/PartnerSignUp.js";
import Transactions from "./components/business/Transactions/Transactions.js";
import CryptoDeposits from "./components/business/Transactions/CryptoDeposits.js";
import KycNew from "./components/business/BusinessKyc/KycNew.js";
import KycSubmitted from "./components/business/BusinessKyc/KycSubmitted.js";
import WithdrawalRequests from "./components/business/Transactions/WithdrawalRequests.js";
import BalanceTransfer from "./components/business/Transactions/BalanceTransfer.js";
import TransferHistory from "./components/business/Transactions/TransferHistory.js";
import TransferHistoryReceived from "./components/business/Transactions/TransferHistoryReceived.js";
import SentHistory from "./components/business/Transactions/SentHistory.js";
import AddMoney from "./components/business/AddMoney/AddMoney.js";
import AddMoneyCrypto from "./components/business/AddMoney/AddMoneyCrypto.js";
import MoneyBankDeposit from "./components/business/AddMoney/MoneyBankDeposit.js";
import Kyc from "./components/business/BusinessKyc/Kyc.js";
import KycApproved from "./components/business/BusinessKyc/KycApproved.js";
import Account from "./components/business/Profile/Account.js";
import ProfileKyc from "./components/business/Profile/ProfileKyc.js";
import AccountSecurity from "./components/business/Profile/AccountSecurity.js";
import BusinessWithdraw from "./components/business/Withdraw/BusinessWithdraw.js";
import Forgot from "./components/account/Forgot.js";
import ResetPassword from "./components/account/ResetPassword.js";
import VerifyEmail from "./components/account/VerifyEmail.js";
import EmailNotVerified from "./components/account/EmailNotVerified.js";
import CoinSuccess from "./components/business/AddMoney/CoinSuccess.js";
import CoinCancel from "./components/business/AddMoney/CoinCancel.js";
import EmailVerify from "./components/account/EmailVerify.js";
import TransferInvoice from "./components/business/Transactions/TransferInvoice.js";
import WithdrawInvoice from "./components/business/Transactions/WithDrawInvoice.js";

import TransROISelf from "./components/business/Transactions/TransROISelf.js";
import TransROIFirst from "./components/business/Transactions/TransROIFirst.js";
import TransROISecond from "./components/business/Transactions/TransROISecond.js";
import TransROIThird from "./components/business/Transactions/TransROIThird.js";
import InstantRewards from "./components/business/Transactions/InstantReward.js";
import TeamRewards from "./components/business/Transactions/TeamRewards.js";
import L1DirectReward from "./components/business/Transactions/L1DirectReward.js";
import L2DirectReward from "./components/business/Transactions/L2DirectReward.js";
import L3DirectReward from "./components/business/Transactions/L3DirectReward.js";
import Network from "./components/business/Network/Network.js";
import CollectProfit from "./components/business/CollectProfit/CollectProfit.js";
import CollectTeamProfit from "./components/business/CollectProfit/CollectTeamProfit.js";
import FutureTransfer from "./components/business/Transfer/FutureTransfer.js";
import FeeDeductions from "./components/business/Transactions/FeeDeductions.js";
import PackageSuccess from "./components/business/Packages/PackageSuccess.js";
import MyPackages from "./components/business/Packages/MyPackages.js";
import BankDeposit from "./components/business/AddMoney/BankDeposit.js";
import ProfitSuccess from "./components/business/CollectProfit/ProfitSuccess.js";
import TeamProfitSuccess from "./components/business/CollectProfit/TeamProfitSuccess.js";
import PrivacyPolicy from "./components/Conditions/PrivacyPolicy.js";
import Services from "./components/Conditions/Services.js";
import ReceivedHistory from "./components/business/Transactions/ReceivedHistory.js";
import ManageWallets from "./components/business/Wallets/ManageWallets.js";
import CrewReward from "./components/business/Transactions/CrewReward.js";
import Support from "./components/business/Support.js";





function Index() {

  if (window.location.host == "localhost:3000" || window.location.host == "localhost:3001"
    || window.location.host == "test.businessi.ai"
    || window.location.host == "businessi.ai" || window.location.host == "www.businessi.ai") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<PartnerSignUp />} />
          <Route path="/signup/:id" element={<PartnerSignUp />} />
          <Route path="/signup/verify-email" element={<EmailVerify />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/email-not-verified" element={<EmailNotVerified />} />


          <Route
            path="/dashboard/transactions/funds-history/sent/img"
            element={<TransferInvoice />}
          />
          <Route
            path="/dashboard/transactions/withdraw/img"
            element={<WithdrawInvoice />}
          />



          <Route path="/dashboard" element={<BusinessMain />}>
            <Route exact path="/dashboard/dashboard" element={<BusinessDashboard />} />
            <Route path="/dashboard/packages" element={<BusinessPackages />} />
            <Route path="/dashboard/packages/my-packages" element={<MyPackages />} />
            <Route path="/dashboard/packages/view/:id" element={<PackagesDetails />} />
            <Route path="/dashboard/packages/success" element={<PackageSuccess />} />



            <Route path="/dashboard/withdraw" element={<BusinessWithdraw />} />
            <Route path="/dashboard/support" element={<Support />} />



            {/* KYC */}
            <Route
              path="/dashboard/kyc-new"
              element={<KycNew />}
            />
            <Route
              path="/dashboard/kyc/submitted"
              element={<KycSubmitted />}
            />
            <Route
              path="/dashboard/kyc/congratulations"
              element={<KycApproved />}
            />


            <Route
              path="/dashboard/kyc"
              element={<Kyc />}
            />



            <Route
              path="/dashboard/profile/wallets"
              element={<ManageWallets />}
            />

            {/* add money */}

            <Route path="/dashboard/addmoney" element={<AddMoney />} />
            <Route
              path="/dashboard/addmoney/:amount"
              element={<AddMoneyCrypto />}
            />

            <Route
              path="/dashboard/bank-deposit/:amount"
              element={<MoneyBankDeposit />}
            />

            <Route path="/dashboard/bank-deposit" element={<BankDeposit />} />

            {/* Transactions */}

            <Route
              path="/dashboard/transactions/membership"
              element={<Transactions />}
            />

            <Route
              path="/dashboard/transactions/packages"
              element={<Transactions />}
            />


            {/* ROI */}

            <Route
              path="/dashboard/transactions/profit-wallet/roi/crew"
              element={<CrewReward />}
            />
            <Route
              path="/dashboard/transactions/profit-wallet/roi/self"
              element={<TransROISelf />}
            />
            <Route
              path="/dashboard/transactions/roi/first"
              element={<TransROIFirst />}
            />
            <Route
              path="/dashboard/transactions/roi/second"
              element={<TransROISecond />}
            />
            <Route
              path="/dashboard/transactions/roi/third"
              element={<TransROIThird />}
            />
            <Route
              path="/dashboard/transactions/instantrewards"
              element={<InstantRewards />}
            />
            <Route
              path="/dashboard/transactions/teamrewards"
              element={<TeamRewards />}
            />
            <Route
              path="/dashboard/transactions/level/one"
              element={<L1DirectReward />}
            />
            <Route
              path="/dashboard/transactions/level/two"
              element={<L2DirectReward />}
            />
            <Route
              path="/dashboard/transactions/level/three"
              element={<L3DirectReward />}
            />

            {/* deposits */}
            <Route
              path="/dashboard/transactions/deposits"
              element={<CryptoDeposits />}
            />


            <Route path="/dashboard/coinpayments/success" element={<CoinSuccess />} />
            <Route path="/dashboard/coinpayments/cancel" element={<CoinCancel />} />


            {/* withdraw */}
            <Route
              path="/dashboard/transactions/withdraw"
              element={<WithdrawalRequests />}
            />

            <Route
              path="/dashboard/balance-transfer"
              element={<BalanceTransfer />}
            />

            <Route
              path="/dashboard/transactions/funds-history/sent"
              element={<TransferHistory />}
            />



            <Route
              path="/dashboard/transactions/funds-history/received"
              element={<TransferHistoryReceived />}
            />
            {/* Activation History */}

            <Route
              path="/dashboard/transactions/activation-history/history/sent"
              element={<SentHistory />}
            />
            <Route
              path="/dashboard/transactions/activation-history/history/received"
              element={<ReceivedHistory />}
            />
            <Route
              path="/dashboard/transactions/fee-deductions"
              element={<FeeDeductions />}
            />


            {/* network */}
            <Route path="/dashboard/network" element={<Network />} />
            {/* statement */}


            <Route
              path="/dashboard/collect-profit"
              element={<CollectProfit />}
            />

            <Route
              path="/dashboard/collect-profit/success"
              element={<ProfitSuccess />}
            />

            <Route
              path="/dashboard/future-transfer"
              element={<FutureTransfer />}
            />

            <Route
              path="/dashboard/collect-team-profit"
              element={<CollectTeamProfit />}
            />
            <Route
              path="/dashboard/collect-team-profit/success"
              element={<TeamProfitSuccess />}
            />


            {/* profile */}

            <Route path="/dashboard/profile/account" element={<Account />} />
            <Route path="/dashboard/profile-kyc" element={<ProfileKyc />} />
            <Route path="/dashboard/profile/security" element={<AccountSecurity />} />
          </Route>

          {/* terms */}


          <Route path="/en/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/en/terms-of-service/" element={<Services />} />

        </Routes>
      </BrowserRouter>
    );
  }
  else if (
    window.location.host == "admin.businessi.ai" || window.location.host == "testadmin.businessi.ai") {
    return (
      <BrowserRouter>
        <Routes>




        </Routes>
      </BrowserRouter>
    )
  }
}

//<Route path="*" element={authenticate ? <DashboardHome /> : <Login />} />

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);