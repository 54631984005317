// UserList.js
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Config from '../../Config';


const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  centerMode: true,
  fade: true,
  autoplaySpeed: 4500,
  centerPadding: '0px',
  pauseOnHover: false,
  arrows: false,
};



const getFirstChar = (str) => {
  const firstChars = str
    .split(" ")
    .map((word) => word[0])
    .join("");
  return firstChars;
};


const WithdrawalList = (props) => {

  const [users, setUsers] = useState(props.data);
  const [imageErrors, setImageErrors] = useState({});

  const handleImageError = (userId) => {
    setImageErrors((prev) => ({ ...prev, [userId]: true }));
  };


  useEffect(() => {
    if (props.data) {
      setUsers(props.data);
    }
  }, [props.data]);



  return (
    <>



      <div className="recent-withdrawals bg-black">
        <h3 className='theme-shadow-title secondary text-center pt-2'>Recent Payouts </h3>
        <div className="mt-3">
          <div className="mobile-container">
            <Slider {...settings} className="recent-withdrawals-slider">
              {users != null && users.map((user, index) => (
                <>
                  <div className='member-info '>

                  <div className="photo">
                      <img src={`${Config.root}images/flags/${user.Country_Code}`} className="flag" />
                    </div>

                   
                    <div className="content">
                      <p>{user.Name}</p>
                      <h4>$ {user.Withdrawal}</h4>
                    </div>
                    <div className="photo">
                      {imageErrors[user.ID] ? (
                        <span className="avatar">{getFirstChar(user.Name)}</span>
                      ) : (
                        <img
                          src={`${Config.root}images/avatar/${user.ID}.png`}
                          onError={() => handleImageError(user.ID)}
                          className="avatar"
                          alt="User Avatar"
                        />
                      )}
                    </div>
                  </div>
                </>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default WithdrawalList;
