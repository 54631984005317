import React, { useState, useEffect, useRef } from "react";
import config from "../../../Config";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import AddMoneyWizard from "./AddMoneyWizard";
import { ArrowRightCircleIcon, BanknotesIcon, CurrencyDollarIcon, WalletIcon } from "@heroicons/react/24/outline";
import LoginCheck from "../General/LoginCheck";

function AddMoney(props) {


    const AccessKey =(localStorage.getItem("AccessKey"));
   const UserID =(localStorage.getItem("ID"));



    const [amount, setAmount] = useState(0);
    const [rates, setRates] = useState([]);
    const formRef = useRef(null);
    const [perfectID, setPerfectID] = useState(null);
    const [AmountInUSD, setAmountInUSD] = useState(0);
    const [InvoiceNo, setInvoiceNo] = useState(0);
    const [LoadingCurrency, setLoadingCurrency] = useState(false);

    const [PaymentMode, setPaymentMode] = useState(null);

    useEffect(() => {
        if (perfectID) {
            formRef.current.submit();
        }
    }, [perfectID]);

    useEffect(() => {
        if (InvoiceNo !== 0) {
            formRef.current.submit();
        }
    }, [InvoiceNo]);

    useEffect(() => {
        setLoadingCurrency(true);
        var api_config = {
            method: "get",
            url: "https://api.metalpriceapi.com/v1/latest?api_key=8da24ec81b918bbe12effe29fb3a1b25&base=USD&currencies=PKR,AED,EUR,USD",
            headers: {
                "Content-Type": "application/json",
                // 'X-API-KEY': 'a3ae13b739a5177f04f978f8d00b3786'
            },
            //data: data
        };
        ////// console.log(api_config);

        axios(api_config)
            .then(function (response) {
                //// console.log(response.data);
                setRates(response.data.rates);
                setLoadingCurrency(false);
            })
            .catch(function (error) {
                //// console.log(error);
            });
    }, []);



    const handleSubmit = async (e) => {
        e.preventDefault();

        const Params = {
            AccessKey: AccessKey,
            UserID: UserID,
            OrderAmount: amount
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'CoinPayments/AddCPOrder',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Params
        };

        try {
            const response = await axios(api_config);

            if (response.data.status_code === 1) {
                setInvoiceNo(response.data.invoice);
                submitForm();
            } else {
                // console.log("Error: API response status_code is not 1");
            }
        } catch (error) {
            // console.log("Error while calling the API:", error);
        }
    };

    const submitForm = () => {
        //formRef.current.submit(); // Submit the form after receiving the invoice number
        if (InvoiceNo !== 0) {
            formRef.current.submit(); // Submit the form after receiving the invoice number
        } else {
            // console.log("InvoiceNo is not available yet. Waiting for API response.");
        }
    };

    // const handleAmountChange = (e) => {
    //     const newValue = e.target.value;

    //     // Regular expression pattern to match numbers greater than or equal to 50
    //     const pattern = /^[5-9]\d*$/;

    //     if (pattern.test(newValue) || newValue === '') {
    //         setAmount(newValue);
    //     }
    // }

    const handleAmount = (e) => {
        setAmount(e.target.value);
        const USD = rates.USD;
        setAmountInUSD(USD * e.target.value);
    }


    const handleAmountButton = (Amount) => {
        setAmount(Amount);
        const USD = rates.USD;
        setAmountInUSD(USD * Amount);
    }

    const handlePaymetMode = (Mode) => {
        setPaymentMode(Mode);
    }

    return (
        <>

            <ToastContainer />
            <LoginCheck />
            <div className="section-title">Refill Safe</div>

            <div className="mobile-container">

                <AddMoneyWizard active1={true} />

                <h4 className="text-center mt-5 mb-3 text-uppercase">Payment Mode</h4>
                <div className="mobile-container p-0">
                    <ul className="payment_modes">
                        <li>
                            <button className="button button-icon-bs button-icon-bs" onClick={(e) => handlePaymetMode("Crypto")}>
                                <span>BI.AI Safe</span>
                                <WalletIcon />
                            </button>
                        </li>
                        <li>
                            <button className="button button-icon-bs button-icon-bs" onClick={(e) => handlePaymetMode("CoinPayments")}>
                                <span>
                                    Coin Payments
                                </span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M10.5 22C5.25329 22 1 17.5228 1 12C1 6.47715 5.25329 2 10.5 2C15.7467 2 20 6.47715 20 12C20 17.5228 15.7467 22 10.5 22Z"
                                        stroke="#FFF"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M13 8.8C12.3635 8.2839 11.5694 8.00154 10.75 8C8.679 8 7 9.79 7 12C7 14.21 8.679 16 10.75 16C11.594 16 12.373 15.702 13 15.2"
                                        stroke="#FFF"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M10 2C14.333 2 23 3 23 12C23 21 14.333 22 10 22"
                                        stroke="#FFF"
                                        strokeWidth={2}
                                    />
                                </svg>

                            </button>
                        </li>
                    </ul>
                </div>

                {
                    PaymentMode != null &&
                    <div className="bt_box">
                        {
                            PaymentMode == "BankWire" ?
                                <>
                                    <h1>Deposit Amount in PKR</h1>
                                    <p>Follow the provided instructions to add funds to your account </p>
                                    <ul className="bt_amounts">
                                        <li><span onClick={() => handleAmountButton(10000)}>+ 10,000</span></li>
                                        <li><span onClick={() => handleAmountButton(50000)}>+ 50,000</span></li>
                                        <li><span onClick={() => handleAmountButton(100000)}>+ 100,000</span></li>
                                        <li><span onClick={() => handleAmountButton(200000)}>+ 200,000</span></li>
                                        <li><span onClick={() => handleAmountButton(500000)}>+ 500,000</span></li>
                                        <li><span onClick={() => handleAmountButton(1000000)}>+ 1000,000</span></li>

                                    </ul>
                                    {
                                        amount < 10000 &&
                                        <p className="Resend" style={{ color: "red", marginTop: "16px" }}> Minimum deposit: PKR 10000</p>
                                    }

                                    <div className="bt_input_group">
                                        <input type="number"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                            className="form-control" />
                                        <div className="icon-left">
                                            <BanknotesIcon />
                                        </div>
                                        <div className="icon-right">
                                            PKR
                                        </div>
                                    </div>
                                    <div className="text-center mt-3">
                                        <NavLink className={`button button-3d-primary button-icon-bs w-100 ${amount < 10000 ? 'disabled' : ''}`} to={`/dashboard/bank-deposit/${amount}`}>
                                            <span>NEXT</span>
                                            <ArrowRightCircleIcon />
                                        </NavLink>
                                    </div>
                                </>

                                :

                                PaymentMode == "Crypto" ?

                                    <>
                                        <h3>Deposit Amount in USD</h3>
                                        <ul style={{ margin: "0 0 0 10px", padding: 0, fontSize: 14 }}>
                                            <li>Follow the provided instructions to add funds to your account</li>
                                            <li>2% Fee will be deducted on deposit of USDT & TRX by Payment Gateway</li>
                                            <li>3% Fee will be deducted on deposit of BTC by Payment Gateway</li>
                                            <li>Deposit Gateway fee accordingly to get full deposit amount to your BI.AI Safe</li>
                                            <li>BI.AI Wallet is recommended for large  payments</li>

                                        </ul>

                                        <ul className="bt_amounts">
                                            <li><span onClick={() => handleAmountButton(20)}>+ 20</span></li>
                                            <li><span onClick={() => handleAmountButton(50)}>+ 50</span></li>
                                            <li><span onClick={() => handleAmountButton(100)}>+ 100</span></li>
                                            <li><span onClick={() => handleAmountButton(500)}>+ 500</span></li>
                                            <li><span onClick={() => handleAmountButton(1000)}>+ 1000</span></li>
                                            <li><span onClick={() => handleAmountButton(2000)}>+ 2000</span></li>

                                        </ul>

                                        {
                                            amount < 20 &&
                                            <p className="Resend" style={{ color: "white", marginTop: "16px" }}> Minimum deposit: $ 20</p>
                                        }


                                        <div className="bt_input_group">
                                            <input type="number"
                                                min={20}
                                                value={amount}
                                                onChange={handleAmount} className="form-control" />
                                            <div className="icon-left">
                                                <CurrencyDollarIcon />
                                            </div>
                                            <div className="icon-right">
                                                <span className="clear" onClick={() => setAmount(0)}>
                                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.5 9.75L14.75 12M14.75 12L17 14.25M14.75 12L17 9.75M14.75 12L12.5 14.25M9.91999 19.17L3.54599 12.795C3.33531 12.5841 3.21698 12.2981 3.21698 12C3.21698 11.7019 3.33531 11.4159 3.54599 11.205L9.91999 4.83C10.13 4.619 10.417 4.5 10.715 4.5H20C20.5967 4.5 21.169 4.73705 21.591 5.15901C22.0129 5.58097 22.25 6.15326 22.25 6.75V17.25C22.25 17.8467 22.0129 18.419 21.591 18.841C21.169 19.2629 20.5967 19.5 20 19.5H10.716C10.418 19.5 10.13 19.381 9.91999 19.17Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="bt_input_group">
                                            <input type="number"
                                                value={LoadingCurrency ? "Loading..." : AmountInUSD.toFixed(2)}
                                                className="form-control" disabled />
                                            <div className="icon-left">
                                                <BanknotesIcon />
                                            </div>
                                            <div className="mt-3">
                                                Estimated Receivable in USD
                                            </div>
                                        </div>
                                        <div className="text-center mt-3">
                                            <NavLink className={`button button-3d-primary ${amount < 20 ? 'disabled' : ''}`} to={`/dashboard/addmoney/${amount}`}>
                                                NEXT
                                               
                                            </NavLink>
                                        </div>

                                    </>
                                    :
                                    <>
                                        <h1>Deposit Amount in USD</h1>
                                        <ul style={{ margin: "0 0 0 10px", padding: 0, fontSize: 14 }}>


                                            <li>Follow the provided instructions to add funds to your account </li>
                                            <li>
                                                1% Fee will be deducted on deposit of USDT & TRX by Payment Gateway
                                            </li>
                                            <li>
                                                3% Fee will be deducted on deposit of BTC by Payment Gateway
                                            </li>
                                            <li>
                                                Deposit Gateway fee accordingly to get full deposit amount to your BI.AI Safe
                                            </li>
                                            <li>
                                                Coinpayments is recommended for small payments
                                            </li>
                                        </ul>

                                        <ul className="bt_amounts">
                                            <li><span onClick={() => handleAmountButton(20)}>+ 20</span></li>
                                            <li><span onClick={() => handleAmountButton(50)}>+ 50</span></li>
                                            <li><span onClick={() => handleAmountButton(100)}>+ 100</span></li>
                                            <li><span onClick={() => handleAmountButton(500)}>+ 500</span></li>
                                            <li><span onClick={() => handleAmountButton(1000)}>+ 1000</span></li>
                                            <li><span onClick={() => handleAmountButton(2000)}>+ 2000</span></li>

                                        </ul>
                                        {
                                            amount < 20 &&
                                            <p className="Resend" style={{ color: "white", marginTop: "16px" }}> Minimum deposit: $20</p>
                                        }

                                        <div className="bt_input_group">
                                            <input type="number"
                                                min={20}
                                                value={amount}
                                                onChange={handleAmount} className="form-control" />
                                            <div className="icon-left">
                                                <CurrencyDollarIcon />
                                            </div>
                                            <div className="icon-right">
                                                <span className="clear" onClick={() => setAmount(0)}>
                                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.5 9.75L14.75 12M14.75 12L17 14.25M14.75 12L17 9.75M14.75 12L12.5 14.25M9.91999 19.17L3.54599 12.795C3.33531 12.5841 3.21698 12.2981 3.21698 12C3.21698 11.7019 3.33531 11.4159 3.54599 11.205L9.91999 4.83C10.13 4.619 10.417 4.5 10.715 4.5H20C20.5967 4.5 21.169 4.73705 21.591 5.15901C22.0129 5.58097 22.25 6.15326 22.25 6.75V17.25C22.25 17.8467 22.0129 18.419 21.591 18.841C21.169 19.2629 20.5967 19.5 20 19.5H10.716C10.418 19.5 10.13 19.381 9.91999 19.17Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>

                                                </span>
                                            </div>
                                        </div>
                                        <div className="bt_input_group">
                                            <input type="number"
                                                value={LoadingCurrency ? "Loading..." : AmountInUSD.toFixed(2)}
                                                className="form-control" disabled />
                                            <div className="icon-left">
                                                <BanknotesIcon />
                                            </div>
                                            <div className="mt-3">
                                                Estimated Receivable in USD
                                            </div>
                                        </div>
                                        <div className="text-center mt-3">
                                            <NavLink className={`button button-3d-primary button-icon-bs ${amount < 20 ? 'disabled' : ''}`} onClick={handleSubmit}>
                                                <span>NEXT</span>
                                                <ArrowRightCircleIcon />
                                            </NavLink>
                                        </div>
                                    </>


                        }
                    </div>
                }





            </div>

            < form ref={formRef} target="_blank" action="https://www.coinpayments.net/index.php" method="post" >
                <input type="hidden" name="cmd" value="_pay_simple" />
                <input type="hidden" name="reset" value="1" />
                <input type="hidden" name="merchant" value="60acf64fdc7d655375eb76aea0c9ebfc" />
                <input type="hidden" name="item_name" value="Package" />
                <input type="hidden" name="item_desc" value="Package purchased" />
                <input type="hidden" name="item_number" value="1" />
                <input type="hidden" name="invoice" value={InvoiceNo} />
                <input type="hidden" name="currency" value="USD" />
                <input type="hidden" name="amountf" value={amount} />
                <input type="hidden" name="want_shipping" value="0" />
                <input type="hidden" name="success_url" value="https://partner.businessi.ai/CoinPayments/Success" />
                <input type="hidden" name="cancel_url" value="https://partner.businessi.ai/CoinPayments/Cancel" />
                <input type="hidden" name="ipn_url" value="https://partnerapi.businessi.ai/api/CoinPayments/PaymentIPN" />

            </form >
        </>
    );
}

export default AddMoney;