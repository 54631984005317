import React from 'react';
import { NavLink } from 'react-router-dom';

function HistoryMenu(props) {
    return (
        <>
            <ul className="menu-option mt-3">
                <li>
                    <NavLink className="nav-link" to="/dashboard/transactions/funds-history/sent?activemenu=Transfers">Transfer History</NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" to="/dashboard/transactions/funds-history/received?activemenu=Transfers" >Received History</NavLink>
                </li>
            </ul>
        </>
    );
}

export default HistoryMenu;