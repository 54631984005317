import React from 'react';



function NetworkMember(props) {

    let percent = 0;
    let remaining = 0;

    const calculatedPercentage = (props.Profit_Received / props.amount) * 100;
    if (isNaN(calculatedPercentage)) {
        percent = 0;
    }
    else {
        percent = calculatedPercentage.toFixed(2);
    }

    remaining = props.amount - props.Profit_Received;

    if (isNaN(remaining)) {
        remaining = 0;
    }



    return (
        <>
            <li className={`list-item level-${props.level - 1}`}>
                <div className='tree-card-wrap'>
                    <div className="profile">
                        <img src={props.photo} alt="" />
                        <div className="info">
                            <h5>{props.name}</h5>
                            <p>{props.email}</p>
                        </div>
                    </div>
                    <div className='tree-card-right '>
                        <ul>
                            <li>
                                <div className='tree-icon-info'>
                                    <div className="amount">
                                        <p>Status</p>
                                        <h5>{props.type}</h5>
                                    </div>
                                    <div className="amount">
                                        <p>Total Members</p>
                                        <h5>{props.TeamCount}</h5>
                                    </div>
                                    <div className="amount">
                                        <p>Fundings</p>
                                        <h5>$ {props.amount}</h5>
                                    </div>

                                    <div className="amount">
                                        <p>Total Profit</p>
                                        <h5>$ {props.Profit_Received}</h5>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className='level'>
                                    <span>{props.level - 1}</span>
                                    <p>Level</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <Button type="default">View</Button> */}
            </li>
        </>
    );
}

export default NetworkMember;