import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import Spinner from 'react-bootstrap/Spinner';
import Flag from "../../../assets/images/flag-thumb.png";
import Codes from "./Codes";

import { EnvelopeIcon, PaperAirplaneIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import moment from "moment";
import LoginCheck from "../General/LoginCheck";

function BirthDetails(props) {

    const AccessKey =(localStorage.getItem("AccessKey"));
   const UserID =(localStorage.getItem("ID"));
    const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));

    const [country, setCountry] = useState({});
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [countries, setCountries] = useState([]);
    const [countriesOld, setCountriesOld] = useState([]);
    const [countryName, setCountryName] = useState(null);
    const [countryName1, setCountryName1] = useState(null);
    const [validated, setValidated] = useState(false);
    const [InitialCountryFlag, setInitialCountryFlag] = useState("");
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);


    // kyc data
    const [FirstName, setFirstName] = useState(null);
    const [LastName, setLastName] = useState(null);
    const [DateOfBirth, setDateOfBirth] = useState(null);
    const [CountryID, setCountryID] = useState(0);
    const [HasKyc, setHasKyc] = useState(false);
    const [CurrentStatus, setCurrentStatus] = useState("");
    const [finalCong, setFinalCong] = useState("")
    const [MobileNo, setMobileNo] = useState("")
    const [CountyCode, setCountyCode] = useState("44")
    const [LoadingKyc, setLoadingKyc] = useState(false);


    const handleMobileNo = (e) => {
        // const value = e.target.value;
        // const formattedValue = value.startsWith('0') ? value.substring(1) : value;
        // const slicedValue = formattedValue.substring(0, 13);
        //setMobileNo(slicedValue);
        setMobileNo(e.target.value);
    };

    useEffect(() => {

        setLoadingKyc(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'MemberKYC/GetMemberKYC',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };

        axios(api_config)
            .then(function (response) {

                //// console.log(response.data);
                if (response.data.status_code == 1) {


                    setFirstName(response.data.FirstName);
                    setLastName(response.data.LastName);
                    setCountryName(response.data.BirthCountry);
                    setCountryName1(response.data.BirthCountry);
                    setCountryID(response.data.BirthCountryID);
                    setMobileNo(response.data.MobileNo);
                    setCurrentStatus(response.data.CurrentStatus);
                    setFinalCong(response.data.CurrentStatus)
                    // setHasKyc(true);
                    setDateOfBirth(moment(response.data.DateOfBirth).format(config.date_format_input));
                    setInitialCountryFlag(getFlagImageByName(response.data.BirthCountry));
                    setLoadingKyc(false);


                    if (response.data.CurrentStatus === "Submitted") {

                        toast.error('KYC Already In Process', {
                            position: toast.POSITION.TOP_RIGHT, 
autoClose: 1000
                        });
                        navigate('/dashboard/kyc/submitted')

                    }
                    else if (response.data.CurrentStatus === "Approved") {

                        navigate('/dashboard/kyc/congratulations');
                    }

                }
                else if (response.data.status_code === 0 && response.data.status_message === "No KYC Exists Please add first.") {
                    setLoadingKyc(false);
                }
                else if (
                    (response.data.status_message === "Invalid Access Key.")
                ) {
                    navigate("/login");
                }
                else {
                    // toast.error(response.data.status_message, {
                    //     position: toast.POSITION.TOP_RIGHT, 
                    // });
                }



            })
            .catch(function (error) {
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT, 
autoClose: 1000
                    //onClose: () => window.alert('Called when I close')
                });
            });
    }, []);




    useEffect(() => {


        const data = {
            Email_Address: Email
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Country/GetCountryList',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                setCountries(response.data.listofCountries);
                setCountriesOld(response.data.listofCountries);


            })
            .catch(function (error) {
                // console.log(error);
            });


    }, []);


    const renderCountries = () => {
        if (countries != "") {
            return (
                countries.map((item) => {
                    return (
                        <li key={item.ID}
                            onClick={(e) => handleCountry(e, item)}>
                            <img src={`${config.root}images/flags/${item.Flage_Image_Name}`} className="flag" /> {item.Country_Name}
                        </li>
                    )
                })
            )
        }
    }

    const handleCountry = (e, item) => {

        // console.log(item);
        setCountry(item);
        setCountryName(item.Country_Name);
        setShow(false);
        setCountries(countriesOld);


    }


    useEffect(() => {
        document.addEventListener("click", ClickOutsideOfSearchbox);
    }, []);


    const ClickOutsideOfSearchbox = (event) => {

        const innerDiv = document.getElementById("countries-box");


        // Check if the click target is inside the outerContainer or its descendants
        if (!innerDiv.contains(event.target)) {
            setShow(false);
        }
    }



    const handleSubmit = (event) => {

        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {




            if (countryName === null) {
                toast.error("Please select a Country", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

            else {

                setLoading(true);
                // let day = event.target.day.value;
                // let month = event.target.month.value;
                // let year = event.target.year.value;

                // let date = year + "-" + month + "-" + day;

                // let formateddate = moment(date).format("YYYY-MM-DD");

                const data = {
                    UserID: UserID,
                    AccessKey: AccessKey,
                    FirstName: event.target.firstname.value,
                    LastName: event.target.lastname.value,
                    DateOfBirth: DateOfBirth,
                    BirthCountry: countryName,
                    BirthCountryID: country.ID,
                    MobileNo: '+' + CountyCode + MobileNo,
                };

                // console.log(data);


                var api_config = {
                    method: 'post',
                    url: config.base_url + 'MemberKYC/UpdatePersonalKYC',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios(api_config)
                    .then(function (response) {



                        if (response.data.status_code == 1) {
                            toast.success(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT, 
autoClose: 1000
                            });
                        }
                        else if (response.data.status_code === 0 && response.data.status_message === "Invalid Access Key."
                            || response.data.status_code === 2) {

                            navigate("/login")

                        }
                        else {
                            toast.error(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT, 
autoClose: 1000
                            });
                        }


                        setLoading(false);


                    })
                    .catch(function (error) {
                        setLoading(false);
                        toast.error('Network Error..', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    });
            }



        }

        setValidated(true);
    };


    const getFlagImageByName = (countryName) => {
        const country = countries.find(
            (country) => country.Country_Name === countryName
        );
        if (country && country.Flage_Image_Name) {
            return `${config.root}images/flags/${country.Flage_Image_Name}`;
        } else {
            return Flag; // Replace with your default image path
        }
    };


    const filterCountries = (e) => {
        setCountryName(e.target.value);

        let value = e.target.value;

        const filteredCountryList = countries.filter((country) =>
            country.Country_Name.toLowerCase().includes(value.toLowerCase())
        );

        if (value == "") {
            setCountries(countriesOld);
            setCountryName(null);
        }
        else {
            setCountries(filteredCountryList);
        }


    }

    const handleCodeChange = (e) => {
        setCountyCode(e.target.value);
        // console.log(e.target.value);
    }


    return (
        <>
            <LoginCheck />
            <ToastContainer />
            {
                LoadingKyc ?
                    <Spinner animation="border" role="status"></Spinner>
                    :
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="form_default">

                            <div className="row g-3 align-items-center">
                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label>First name</label>
                                        <div className="icon-input">
                                            <input type="text" className="form-control" value={FirstName} name="firstname" placeholder="First Name" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label>Last name</label>
                                        <div className="icon-input">
                                            <input type="text" className="form-control" value={LastName} name="lastname" placeholder="Last Name" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label>Enter your birth date</label>
                                        <div className="icon-input">
                                            <input type="date"
                                                max="9999-12-31"
                                                className="form-control"
                                                value={DateOfBirth}
                                                onChange={(e) => setDateOfBirth(e.target.value)} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="icon-form-group">
                                        <label htmlFor="">Country</label>
                                        <div className="icon-input">
                                            <input type="text" id="countries-box"
                                                className="flag-input"
                                                onFocus={handleShow}
                                                //onBlur={() => setShow(false)}
                                                value={countryName}
                                                onChange={(e) => filterCountries(e)}
                                                placeholder="Select Country"
                                            />

                                            {
                                                countryName != null && (
                                                    <img
                                                        src={getFlagImageByName(countryName)}
                                                        onError={(e) => {
                                                            e.target.src = Flag;
                                                        }}
                                                    />
                                                )
                                            }

                                            <div className="icon">
                                                {
                                                    show == true ?
                                                        <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8 -3.98606e-07L0 7.63251L1.43333 9L8 2.70318L14.5667 8.9682L16 7.60071L8 -3.98606e-07Z" fill="#727272" />
                                                        </svg>

                                                        :
                                                        <svg width="16" height="9" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 14.15L0 2.15L2.15 0L12 9.9L21.85 0.05L24 2.2L12 14.15Z" fill="#727272" />
                                                        </svg>
                                                }

                                            </div>

                                            {
                                                show == true &&
                                                <div className="card-gradient card_list_of_countries" style={{ top: "51px" }}>
                                                    <div className="card-inner p-3">
                                                        <ul className="countries_list_offcanvas">
                                                            {renderCountries()}
                                                        </ul>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="icon-form-group">
                                        <label>Phone</label>
                                        <div className="d-flex">
                                            <div className="icon-input" style={{ width: "176px" }}>
                                                <Codes
                                                    handleCodeChange={handleCodeChange} />
                                            </div>
                                            <div className="icon-input">
                                                <input type="number"
                                                    placeholder="Enter Mobile No"
                                                    className="form-control"
                                                    onChange={handleMobileNo}
                                                    value={MobileNo}
                                                    required
                                                    maxLength={"13"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 text-end">
                                    {
                                        loading ?

                                            <Spinner animation="border" role="status"></Spinner>
                                            :
                                            <button
                                                className="button button-bs w-100 mt-2">Save Personal Details  <span><PaperAirplaneIcon /></span></button>
                                    }

                                </div>
                            </div>
                        </div>
                    </Form>
            }

        </>
    );
}

export default BirthDetails;