import React, { useState, useEffect } from "react";
import TransactionMenu from "./TransactionMenu";
import axios from "axios";
import config from "../../../Config";
import BsPagination from "../BsPagination";
import { Spinner } from "react-bootstrap";
import LoginCheck from "../General/LoginCheck";
import moment from "moment";
import { FaceFrownIcon } from "@heroicons/react/24/outline";

const InstantRewards = () => {
  const AccessKey =(localStorage.getItem("AccessKey"));
 const UserID =(localStorage.getItem("ID"));
  const [loading, setLoading] = useState(false);
  const [listOfTransactions, setlistOfTransactions] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    document.title = "Rewards Transaction";

    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Wallet/GetDirectBonus",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };


    axios(api_config)
      .then(function (response) {
        // console.log("response",response.data);
        setlistOfTransactions(response.data.ListofLedger);
        setTotalRecords(response.data.totalRecords);
        setLoading(false);
      })
      .catch(function (error) {
        //// console.log(error);
      });
  }, [pageNo, pageSize]);

 
  const renderLedger = () => {

    if (listOfTransactions != null && listOfTransactions.length > 0) {
        return (
            listOfTransactions.map((item, index) => {

                return (
                    <tr key={index}>
                        <td data-label="Transaction ID">
                            {index++ + pageSize * (pageNo - 1) + 1}
                        </td>
                        <td data-label="Date"> {moment(item.Transaction_Date).format('DD/MM/YYYY HH:mm')}</td>
                        <td data-label="Reference No">{item.Reference_No}</td>
                        <td data-label="Reward Amount">{item.RewardAmount}</td>
                        <td data-label="Reward Percent">{item.RewardPercent}</td>
                        <td data-label="Details">{item.Details}</td>

                    </tr>
                )
            })
        )
    }
    else {
        return (
            <tr>
                <td className="else" colSpan="6">
                    <FaceFrownIcon />
                    <h4>No Data Found</h4>
                </td>
            </tr>
        )
    }

}

return (
    <>
        <LoginCheck />
        <TransactionMenu />
        <div className="partner-table">
            <div className="table-wrap">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Transaction Date</th>
                            <th>Reference No</th>
                            <th>Reward Amount</th>
                            <th>Reward Percent</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                <tr>
                                    <td className="text-center" colSpan="7">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </td>
                                </tr>
                                :
                                renderLedger()
                        }


                    </tbody>
                </table>
                {
                    totalRecords > 10 &&

                    <BsPagination
                        length={listOfTransactions.length}
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={(page) => setPageNo(page)}
                        onPageSizeChange={(newPageSize) => {
                            setPageSize(newPageSize);
                            setPageNo(1)
                        }}
                    />
                }
            </div>
        </div>
    </>
);
};

export default InstantRewards;
