import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ProfileMenu from "./ProfileMenu";
import LoginCheck from "../General/LoginCheck";
import Accordion from 'react-bootstrap/Accordion';
import axios from "axios";
import SendEmail from "../../Common/SendEmail";
import Config from "../../../Config";
import ChangePassword from "./ChangePassword";
import EmailVerificationLogin from "./EmailVerificationLogin";
import { KeyIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";



function AccountSecurity(props) {

    let navigate = useNavigate();

    const AccessKey = (localStorage.getItem("AccessKey"));
    const UserID = (localStorage.getItem("ID"));
    const [loading, setLoading] = useState(false);
    const [loadingUpdateSmartpass, setloadingUpdateSmartpass] = useState(false);

    // canvas Email Verification at Login

    const [showCanvasEmail, setShowCanvasEmail] = useState(false);
    const handleCloseCanvasEmail = () => setShowCanvasEmail(false);
    const [SmartPassStatus, setSmartPassStatus] = useState(false);


    const [OTP, setOTP] = useState("");
    const [OTP1, setOTP1] = useState('');
    const [AuthCode, setAuthCode] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,

        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetSmartPassInfo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    setSmartPassStatus(response.data.Status);
                    setLoading(false);
                }
                else if (
                    (response.data.status_message === "Invalid Access Key.")
                ) {
                    navigate("/login");
                }

            })
            .catch(function (error) {
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000
                });
            });

    }, []);


    const handleUpdateSmartPass = () => {


        if (OTP == OTP1) {

            if (SmartPassStatus == true) {
                if (AuthCode == "") {
                    toast.error('Please enter verification code', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000
                    });
                    return false;
                }
            }

            setloadingUpdateSmartpass(true);



            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                NewPin: OTP,
                AuthorizationCode: AuthCode,
            };

            var api_config = {
                method: 'post',
                url: Config.base_url + 'Members/ChangeSmartPass',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {
                    if (response.data.status_code == 1) {
                        toast.success('Transaction Pin Updated Successfully', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000
                        });
                        setTimeout(() => {
                            navigate('/dashboard/dashboard');
                        }, 3000)
                        setloadingUpdateSmartpass(false);
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000
                        });
                        setloadingUpdateSmartpass(false);
                    }

                })
                .catch(function (error) {
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000
                    });
                });
        }
        else {
            toast.error("Transaction Pin didn't match", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000
            });
        }


    }

    const [timer, setTimer] = useState(60); // 60 seconds

    const handleSendCode = () => {
        const props = {
            TransactionType: 'SmartPassChange',
        };

        SendEmail(props);

        setIsButtonDisabled(true);

        setTimeout(() => {
            setIsButtonDisabled(false);
            setTimer(60);
        }, 30000);

        setTimer(60);
    };

    useEffect(() => {
        let interval;
        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isButtonDisabled]);





    return (
        <>
            <LoginCheck />
            <ToastContainer />
            <EmailVerificationLogin show={showCanvasEmail} handleClose={handleCloseCanvasEmail} />
            <ProfileMenu />
            <div className="profile-bg-card form-default">
                <Accordion className="border-free-accordion" defaultActiveKey="0" flush>

                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Transaction Pin</Accordion.Header>
                        <Accordion.Body>
                            {
                                loading ?
                                    "Loading.."
                                    :
                                    <>
                                        {
                                            SmartPassStatus === false ?
                                                <>
                                                    <ul className="profile-info-list">
                                                        <li>
                                                            <div className="icon-form-group">
                                                                <label htmlFor="">Transaction Pin</label>
                                                                <div className="icon-input">
                                                                    <input maxLength={6}
                                                                        type="number"
                                                                        placeholder="Enter Transaction  PIN"
                                                                        onChange={(e) => {
                                                                            if (e.target.value.length <= 6) {
                                                                                setOTP(e.target.value);
                                                                            }
                                                                        }}
                                                                        className="form-control"
                                                                        value={OTP}
                                                                        required />
                                                                    <div className="icon">
                                                                        <KeyIcon />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="icon-form-group">
                                                                <label htmlFor="">Confirm Transaction Pin</label>
                                                                <div className="icon-input">
                                                                    <input maxLength={6}
                                                                        placeholder="Enter Confirm Transaction  PIN"
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            if (e.target.value.length <= 6) {
                                                                                setOTP1(e.target.value);
                                                                            }
                                                                        }}
                                                                        value={OTP1}
                                                                        className="form-control"
                                                                        required />
                                                                    <div className="icon">
                                                                        <KeyIcon />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button
                                                                onClick={handleUpdateSmartPass}
                                                                disabled={OTP1.length === 0 || OTP.length === 0}
                                                                className="button button-3d-primary w-100"
                                                            >
                                                                Update {loadingUpdateSmartpass ? '...' : ''}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </>
                                                :
                                                <>
                                                    <ul className="profile-info-list">
                                                        <li>
                                                            <div className="icon-form-group">
                                                                <label htmlFor="">Enter Verification Code</label>
                                                                <div className="icon-input">
                                                                    <input placeholder="Enter Code"
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            if (e.target.value.length <= 6) {
                                                                                setAuthCode(e.target.value);
                                                                            }
                                                                        }}
                                                                        className="form-control"
                                                                        value={AuthCode}
                                                                        required
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="icon">
                                                                        <KeyIcon />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button type="button"
                                                                onClick={handleSendCode}
                                                                className="button button-3d-outline w-100"
                                                                disabled={isButtonDisabled}><span>Get Code</span>
                                                            </button>

                                                        </li>
                                                        <li>
                                                            <div className="icon-form-group">
                                                                <label htmlFor="">New Transaction Pin</label>
                                                                <div className="icon-input">
                                                                    <input maxLength={6}
                                                                        placeholder="Enter New Transaction  PIN"
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            if (e.target.value.length <= 6) {
                                                                                setOTP(e.target.value);
                                                                            }
                                                                        }}
                                                                        value={OTP}
                                                                        className="form-control"
                                                                        required />
                                                                    <div className="icon">
                                                                        <KeyIcon />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="icon-form-group">
                                                                <label htmlFor="">Confirm Transaction Pin</label>
                                                                <div className="icon-input">
                                                                    <input maxLength={6}
                                                                        placeholder="Enter Confirm Transaction  PIN"
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            if (e.target.value.length <= 6) {
                                                                                setOTP1(e.target.value);
                                                                            }
                                                                        }}
                                                                        value={OTP1}
                                                                        className="form-control"
                                                                        required />
                                                                    <div className="icon">
                                                                        <KeyIcon />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button onClick={handleUpdateSmartPass}
                                                                disabled={OTP1.length === 0 || OTP.length === 0 || AuthCode.length === 0}
                                                                className="button button-3d-primary w-100">Save {loadingUpdateSmartpass ? '...' : ''}</button>

                                                        </li>
                                                    </ul>
                                                </>
                                        }
                                    </>
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Password</Accordion.Header>
                        <Accordion.Body>
                            <ChangePassword />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
}

export default AccountSecurity;