import React from "react";
import BusinessHeader from "./include/BusinessHeader";
import { useNavigate, Outlet } from "react-router-dom";
import BottomMenu from "./include/BottomMenu";

function BusinessMain() {

    const AccessKey = localStorage.getItem("AccessKey");

    let navigate = useNavigate();


    if (!AccessKey) {
        navigate('/login');
    }



    return (
        <>
            <main id="main">
                <BusinessHeader />
                <div className="business-layout-wrap">
                    <div className="business-content">
                        <Outlet></Outlet>
                    </div>
                </div>
                <BottomMenu />
            </main>
        </>
    );
}

export default BusinessMain;