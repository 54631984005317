import React, { useState, useEffect, useRef } from "react";
import config from "../../../Config";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import AddMoneyWizard from "./AddMoneyWizard";
import { BuildingLibraryIcon, CurrencyDollarIcon, WalletIcon } from "@heroicons/react/24/outline";
import LoginCheck from "../General/LoginCheck";

function BankDeposit(props) {


    const AccessKey =(localStorage.getItem("AccessKey"));
   const UserID =(localStorage.getItem("ID"));



    const [amount, setAmount] = useState(0);

    const formRef = useRef(null);
    const [perfectID, setPerfectID] = useState(null);

    const [InvoiceNo, setInvoiceNo] = useState(0);


    useEffect(() => {
        if (perfectID) {
            formRef.current.submit();
        }
    }, [perfectID]);

    useEffect(() => {
        if (InvoiceNo !== 0) {
            formRef.current.submit();
        }
    }, [InvoiceNo]);



    const handleSubmit = async (e) => {
        e.preventDefault();

        const Params = {
            AccessKey: AccessKey,
            UserID: UserID,
            OrderAmount: amount
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'CoinPayments/AddCPOrder',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Params
        };

        try {
            const response = await axios(api_config);

            if (response.data.status_code === 1) {
                setInvoiceNo(response.data.invoice);
                submitForm();
            } else {
                // console.log("Error: API response status_code is not 1");
            }
        } catch (error) {
            // console.log("Error while calling the API:", error);
        }
    };

    const submitForm = () => {
        //formRef.current.submit(); // Submit the form after receiving the invoice number
        if (InvoiceNo !== 0) {
            formRef.current.submit(); // Submit the form after receiving the invoice number
        } else {
            // console.log("InvoiceNo is not available yet. Waiting for API response.");
        }
    };

    // const handleAmountChange = (e) => {
    //     const newValue = e.target.value;

    //     // Regular expression pattern to match numbers greater than or equal to 50
    //     const pattern = /^[5-9]\d*$/;

    //     if (pattern.test(newValue) || newValue === '') {
    //         setAmount(newValue);
    //     }
    // }


    return (
        <>

            <ToastContainer />
            <LoginCheck />
            <div className="section-title">Add Money</div>
            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <div className="dash-bg-card card-bg-addmoney mb-4">
                        <div className="row">
                            <div className="col-md-3">
                                <AddMoneyWizard active1={true} />
                            </div>
                            <div className="col-md-9">
                                <div className="bt_box">
                                    <h1>Deposit Amount</h1>
                                    <p>Follow the provided instructions to add funds to your account </p>
                                    <ul className="bt_amounts">
                                        <li><span onClick={() => setAmount(parseInt(amount) + parseInt(100))}>+ 100</span></li>
                                        <li><span onClick={() => setAmount(parseInt(amount) + parseInt(500))}>+ 500</span></li>
                                        <li><span onClick={() => setAmount(parseInt(amount) + parseInt(1000))}>+ 1000</span></li>
                                        <li><span onClick={() => setAmount(parseInt(amount) + parseInt(5000))}>+ 5000</span></li>
                                        <li><span onClick={() => setAmount(parseInt(amount) + parseInt(10000))}>+ 10000</span></li>
                                        <li><span onClick={() => setAmount(parseInt(amount) + parseInt(20000))}>+ 20000</span></li>

                                    </ul>

                                    <div className="row">
                                        <div className="col-md-10 offset-md-1">

                                            <div className="bt_input_group">
                                                <input type="number"
                                                    min={50}
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)} className="form-control" />
                                                <div className="icon-left">
                                                    <CurrencyDollarIcon />
                                                </div>
                                                <div className="icon-right">
                                                    <span className="clear" onClick={() => setAmount(0)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                            <path d="M12.5 9.75L14.75 12M14.75 12L17 14.25M14.75 12L17 9.75M14.75 12L12.5 14.25M9.91999 19.17L3.54599 12.795C3.33531 12.5841 3.21698 12.2981 3.21698 12C3.21698 11.7019 3.33531 11.4159 3.54599 11.205L9.91999 4.83C10.13 4.619 10.417 4.5 10.715 4.5H20C20.5967 4.5 21.169 4.73705 21.591 5.15901C22.0129 5.58097 22.25 6.15326 22.25 6.75V17.25C22.25 17.8467 22.0129 18.419 21.591 18.841C21.169 19.2629 20.5967 19.5 20 19.5H10.716C10.418 19.5 10.13 19.381 9.91999 19.17Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>

                                            {
                                                amount < 50 &&
                                                <p className="Resend" style={{ color: "red", marginTop: "6px" }}> Minimum deposit: $50</p>
                                            }
                                        </div>
                                    </div>

                                    <h4 className="text-center mt-5 mb-3 text-uppercase">Payment Mode</h4>
                                    <ul className="payment_modes">
                                        <li>
                                            <NavLink className={`button button-bs button-icon-bs ${amount < 50 ? 'disabled' : ''}`} to={`/dashboard/addmoney/${amount}`}>
                                                <span>Bank Wire</span>
                                                <BuildingLibraryIcon />
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className={`button button-bs button-icon-bs ${amount < 50 ? 'disabled' : ''}`} to={`/dashboard/addmoney/${amount}`}>
                                                <span>BI.AI Wallet</span>
                                                <WalletIcon />
                                            </NavLink>
                                        </li>
                                        <li>
                                            <a className={`button button-bs button-icon-bs ${amount < 50 ? 'disabled' : ''}`} style={{ cursor: "pointer" }} onClick={handleSubmit}>
                                                <span>
                                                    CoinPayments
                                                </span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M10.5 22C5.25329 22 1 17.5228 1 12C1 6.47715 5.25329 2 10.5 2C15.7467 2 20 6.47715 20 12C20 17.5228 15.7467 22 10.5 22Z"
                                                        stroke="white"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M13 8.8C12.3635 8.2839 11.5694 8.00154 10.75 8C8.679 8 7 9.79 7 12C7 14.21 8.679 16 10.75 16C11.594 16 12.373 15.702 13 15.2"
                                                        stroke="white"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M10 2C14.333 2 23 3 23 12C23 21 14.333 22 10 22"
                                                        stroke="white"
                                                        strokeWidth={2}
                                                    />
                                                </svg>

                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            < form ref={formRef} target="_blank" action="https://www.coinpayments.net/index.php" method="post" >
                <input type="hidden" name="cmd" value="_pay_simple" />
                <input type="hidden" name="reset" value="1" />
                <input type="hidden" name="merchant" value="dc63ebc0e5fb75a56b017be47bea0efb" />
                <input type="hidden" name="item_name" value="Package" />
                <input type="hidden" name="item_desc" value="Package purchased" />
                <input type="hidden" name="item_number" value="1" />
                <input type="hidden" name="invoice" value={InvoiceNo} />
                <input type="hidden" name="currency" value="USD" />
                <input type="hidden" name="amountf" value={amount} />
                <input type="hidden" name="want_shipping" value="0" />
                <input type="hidden" name="success_url" value="https://partner.businessi.ai/CoinPayments/Success" />
                <input type="hidden" name="cancel_url" value="https://partner.businessi.ai/CoinPayments/Cancel" />
                <input type="hidden" name="ipn_url" value="https://partnerapi.businessi.ai/api/CoinPayments/PaymentIPN" />
            </form >
        </>
    );
}

export default BankDeposit;