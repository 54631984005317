import React, { useEffect, useState, useRef } from "react";
import { Form, NavLink, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { NumberFormat } from "../General/Functions";
import ReactTooltip from "react-tooltip";
import SendEmail from "../../Common/SendEmail";
import moment from "moment";
import Config from "../../../Config";
import { ArrowLongRightIcon, ArrowUpCircleIcon, CreditCardIcon, CurrencyDollarIcon, DocumentDuplicateIcon, FaceFrownIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import LoginCheck from "../General/LoginCheck";

function BusinessWithdraw(props) {


    let navigate = useNavigate();

    const AccessKey = (localStorage.getItem("AccessKey"));
    const UserID = (localStorage.getItem("ID"));
    const [amount, setAmount] = useState("");
    const [OTP, setOTP] = useState("");
    const [loading, setLoading] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [remainingBalance, setRemainingBalance] = useState(0);
    const [CurrentBalance, setCurrentBalance] = useState(0);
    const [wallet, setWallet] = useState({});
    const [finalAmount, setFinalAmount] = useState(0);
    const [pamount, setpAmount] = useState(0);
    const [validated, setValidated] = useState(false);
    const [noWallet, setnoWallet] = useState(false);
    const WalletIDRef = useRef(null);
    const [rates, setRates] = useState([]);
    const [CryptoRates, setCryptoRates] = useState([]);
    const [dData, setDashboardData] = useState({});
    const [ActiveClass, setActiveClass] = useState(null);
    const [withdrawFeePercentage, setwithdrawFeePercentage] = useState(12.00);


    const [timer, setTimer] = useState(60);


    const [BTC, setBTC] = useState(0);
    const [ETH, setETH] = useState(0);
    const [USDT, setUSDT] = useState(0);
    const [TRX, setTRX] = useState(0);
    const [PKR, setPKR] = useState(0);

    const [AuthCode, setAuthCode] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [Amounterror, setAmounterror] = useState(false);
    const [iswithdrawdisable, setiswithdrawdisable] = useState(false);

    const [hasWallet, setHasWallet] = useState(false);


    useEffect(() => {

        setLoading(true);
        const Parms = {
            AccessKey: AccessKey,
            UserID: UserID,

        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetDashboardData',
            headers: {
                'Content-Type': 'application/json'
            },
            data: Parms
        };

        axios(api_config)
            .then(function (response) {
                //// console.log(response.data);
                if (response.data.status_code == 1) {


                    if (response.data.Member?.Email_Address_Verified == false) {
                        navigate('/signup/verify-email');
                    }

                    setLoading(false);

                }
                else if (
                    (response.data.status_message === "Invalid Access Key.")
                ) {
                    navigate("/login");
                }else{
                    
                }


            })
            .catch(function (error) {
                // console.log(error);
            });



    }, []);


    useEffect(() => {


        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
        };

        var api_config = {
            method: "post",
            url: Config.base_url + "MemberWallet/GetWallets",
            headers: {
                "Content-Type": "application/json",
            },
            data: dashboardParms,
        };

        axios(api_config)
            .then(function (response) {
                // console.log("dashboardParms", response.data);
                if (response.data.status_code == 1) {
                    setWallets(response.data.ListofWallets);
                } else if (response.data.status_message == "Invalid Access Key.") {
                    localStorage.clear();
                    window.location.href = "/login";
                }
            })
            .catch(function (error) {
                toast.error("Network Error..", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000
                    //onClose: () => window.alert('Called when I close')
                });
            });
    }, []);

    useEffect(() => {
        var api_config = {
            method: "get",
            url: "https://api.metalpriceapi.com/v1/latest?api_key=7da24ec81b918bbe12effe29fb3a1b25&base=USD&currencies=INR,PKR,AED,EUR,BTC,USD",
            headers: {
                "Content-Type": "application/json",
                // 'X-API-KEY': 'a3ae13b739a5177f04f978f8d00b3786'
            },
            //data: data
        };
        // console.log(api_config);

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                setRates(response.data.rates);
                //setPKR(response.data.rates[3]);
                setPKR(response.data.rates.USD);
                // console.log(response.data.rates.PKR);
            })
            .catch(function (error) {
                // console.log(error);
            });
    }, []);

    useEffect(() => {
        var api_config = {
            method: "get",
            url: "https://rest.coinapi.io/v1/exchangerate/USD",
            headers: {
                //'Content-Type': 'application/json',
                "X-CoinAPI-Key": "BE3F6519-612E-4CA3-BA6D-6752E98C73BB",
            },
            //data: data
        };
        axios(api_config)
            .then(function (response) {
                //  // console.log(response.data);
                setCryptoRates(response.data.rates);
                let rates = response.data.rates;

                let btc = rates.filter((usdt) => usdt.asset_id_quote == "BTC");
                let eth = rates.filter((usdt) => usdt.asset_id_quote == "ETH");
                let usdt = rates.filter((usdt) => usdt.asset_id_quote == "USDT");
                let trx = rates.filter((usdt) => usdt.asset_id_quote == "TRX");
                setUSDT(usdt[0].rate);
                setBTC(btc[0].rate);
                setETH(eth[0].rate);
                setTRX(trx[0].rate);
                // console.log("USDT",usdt[0].rate);
                // console.log("BTC",btc[0].rate);
                // console.log("eth",eth[0].rate);
                // console.log("TRX",trx[0].rate);
            })
            .catch(function (error) {
                // console.log(error);
            });
    }, []);

    useEffect(() => {

        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID
        };

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Members/GetDashboardData',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };

        axios(api_config)
            .then(function (response) {
                // console.log('setDashboardData', response.data);
                setDashboardData(response.data);
                setRemainingBalance(response.data.Member.Current_Balance);
                setCurrentBalance(response.data.Member.Current_Balance);
            })
            .catch(function (error) {
                // console.log(error);
            });


    }, []);

    const handleSubmitWithdraw = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();



        } else {
            if (OTP.length > 0) {



                setLoading(true);
                let CurrencyRate = 0;
                if (wallet == "Bank Account") {
                    let bank = wallets.filter(
                        (item) =>
                            item.WalletType == "Bank Account" && item.IsDefault == true
                    );

                    //// console.log(bank.length);

                    if (bank.length > 0) {
                        let item = bank[0];
                        if (item.AccountCurrency == "PKR") {
                            //CurrencyRate = rates.PKR;
                            CurrencyRate = 1;
                        }
                        // else if (item.AccountCurrency == "INR") {
                        //     CurrencyRate = rates.INR;

                        // }
                        else if (item.AccountCurrency == "AED") {
                            CurrencyRate = rates.AED;
                        } else if (item.AccountCurrency == "EUR") {
                            CurrencyRate = rates.EUR;
                        }
                    }
                } else if (wallet == "Cash") {
                    CurrencyRate = '1';
                }
                else if (wallet == "BTC") {
                    // console.log(BTC);


                    CurrencyRate = BTC;

                } else if (wallet == "ETH") {

                    CurrencyRate = ETH;


                } else if (wallet == "TRX") {

                    CurrencyRate = TRX;


                } else if (wallet == "USDT") {
                    CurrencyRate = USDT;
                }

                const data = {
                    UserID: UserID,
                    AccessKey: AccessKey,
                    PinCode: OTP,
                    Amount: event.target.amount.value,
                    WalletID: WalletIDRef.current.value,
                    PaymentMode: wallet,
                    SelectedCurrencyRate: CurrencyRate,
                    SelectedCurrency: wallet,
                    AuthorizationCode: AuthCode,
                };


                // console.log(data)

                var api_config = {
                    method: "post",
                    url: Config.base_url + "Members/AddWithdrawlRequest",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                };


                const day = moment().format('dddd');
                if (day === "Sunday") {
                    setiswithdrawdisable(true);
                    setLoading(false);
                }
                else {
                    axios(api_config)
                        .then(function (response) {
                            // console.log(response.data)
                            if (response.data.status_code == 1) {
                                toast.success(response.data.status_message, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 1000
                                });
                                navigate('/dashboard/transactions/withdraw?activemenu=Payout');
                                // window.location.reload();
                            } else {
                                toast.error(response.data.status_message, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 1000
                                });
                            }
                            setLoading(false);
                        })
                        .catch(function (error) {
                            setLoading(false);
                            toast.error("Network Error..", {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000
                            });
                        });
                }

            } else {
                toast.error("Please enter Transaction Pin", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000
                });
            }
        }

        setValidated(true);
    };

    const CalcPercentage = (e) => {
        let amount = e.target.value;

        if (amount.length < 8) {
            // console.log(setpAmount);
            setAmount(amount);
            if (amount > dData.Member?.Current_Balance) {
                toast.error('Amount should be less than balance', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setAmount("");
                return false;
            }
            if (amount.length == 0 || amount == 0 || amount == "") {
                //setRemainingBalance(props.data.Current_Balance);
                setFinalAmount(0);
                setpAmount(0);
                setRemainingBalance(dData?.Member.Current_Balance);
            } else {

                //shafat

                if (amount < 60) {
                    setAmounterror(true);

                }
                else {
                    setAmounterror(false)
                }

                setRemainingBalance(CurrentBalance - amount);


                // fahad
                if (wallet == 'Bank Account') {
                    let final1 = ((amount / 100) * 4).toFixed(2);
                    let final = amount - final1;

                    setFinalAmount(final);
                    setpAmount(final1);


                } else if (wallet == 'Cash') {

                    let final1 = amount;
                    let final = amount - final1;

                    setFinalAmount(final);
                    setpAmount(final1);


                } else if (wallet == 'BTC' || wallet == 'TRX' || wallet == 'USDT') {

                    let final1 = ((amount / 100) * withdrawFeePercentage).toFixed(2);
                    let final = amount - final1;

                    setFinalAmount(final);
                    setpAmount(final1);

                }
                //setRemainingBalance(props.data.Current_Balance - final);


            }

        }




    };
    const isMultipleOf5Or10 = (amount) => {
        return amount % 5 === 0 || amount % 10 === 0;
    };

    const handleSendCode = () => {
        const props = {
            TransactionType: "WithdrawCash",
        };

        SendEmail(props);

        setIsButtonDisabled(true);

        // Enable the button after 1 minute
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 60000);
        setTimer(60);
    };

    const renderFeeBox = () => {
        if (wallet == "Bank Account") {
            let bank = wallets.filter(
                (item) => item.WalletType == "Bank Account" && item.IsDefault == true
            );



            if (bank.length > 0) {
                let item = bank[0];
                // console.log(item);    
                // let convertedAmount = 0;
                // let convertedR = 0;

                // if (item.AccountCurrency == "PKR") {
                //     convertedAmount = pamount * rates.PKR;
                //     convertedR = pamount;
                // }
                // else if (item.AccountCurrency == "AED") {
                //     convertedAmount = pamount * rates.AED;
                //     convertedR = finalAmount * rates.AED;
                // } else if (item.AccountCurrency == "EUR") {
                //     convertedAmount = pamount * rates.EUR;
                //     convertedR = finalAmount * rates.EUR;
                // }

                return (
                    <>
                        <div className="icon-input mb-3">
                            <input type="text" placeholder="Fee in USD (4%)"
                                className="disabled form-control ps-0"
                                readOnly={true} />
                            <div className="icon">
                                <CreditCardIcon />
                            </div>
                            <div className="icon-right">
                                <b>{pamount}</b>
                            </div>
                        </div>
                        <div className="icon-input mb-3">
                            <input type="text" placeholder={`Receivable Amount in PKR`}
                                className="disabled form-control ps-0"
                                readOnly={true} />
                            <div className="icon-right">
                                <b>{finalAmount}</b>
                            </div>
                        </div>
                    </>
                );
            }
        } else if (wallet == "BTC") {
            let account = wallets.filter(
                (item) =>
                    item.WalletType == "Crypto" &&
                    item.Network == "BTC" &&
                    item.IsDefault == true
            );

            if (account.length > 0) {
                let item = account[0];

                let convertedAmount = 0;
                let convertedR = 0;
                //// console.log(finalAmount);

                convertedR = finalAmount / PKR * BTC;

                return (
                    <>
                        <div className="icon-input mb-3">
                            <input type="text" placeholder="Fee in USD (12%)"
                                className="disabled form-control ps-0"
                                readOnly={true} />
                            <div className="icon">
                                <CreditCardIcon />
                            </div>
                            <div className="icon-right">
                                <b>{pamount}</b>
                            </div>
                        </div>
                        <div className="icon-input mb-3">
                            <input type="text" placeholder={`Receivable in ${item.Network}`}
                                className="disabled form-control ps-0"
                                readOnly={true} />

                            <div className="icon">
                                <CreditCardIcon />
                            </div>

                            <div className="icon-right">
                                <b>{convertedR.toFixed(8)}</b>
                            </div>
                        </div>
                    </>
                );
            }
        } else if (wallet == "ETH") {
            let account = wallets.filter(
                (item) =>
                    item.WalletType == "Crypto" &&
                    item.Network == "ETH" &&
                    item.IsDefault == true
            );

            if (account.length > 0) {
                let item = account[0];

                let convertedAmount = 0;
                let convertedR = 0;

                // console.log(CryptoRates.ETH);
                convertedR = finalAmount / PKR * ETH;

                return (
                    <>
                        <div className="icon-input mb-3">
                            <input type="text" placeholder="Fee in USD (12%)"
                                className="disabled form-control ps-0"
                                readOnly={true} />
                            <div className="icon-right">
                                <b>{pamount}</b>
                            </div>
                        </div>
                        <div className="icon-input mb-3">
                            <input type="text" placeholder={`Receivable in ${item.Network}`}
                                className="disabled form-control ps-0"
                                readOnly={true} />
                            <div className="icon-right">
                                <b>{convertedR.toFixed(8)}</b>
                            </div>
                        </div>
                    </>
                );
            }
        } else if (wallet == "TRX") {
            let account = wallets.filter(
                (item) =>
                    item.WalletType == "Crypto" &&
                    item.Network == "TRX" &&
                    item.IsDefault == true
            );

            // console.log(account);

            if (account.length > 0) {
                let item = account[0];

                let convertedAmount = 0;
                let convertedR = 0;
                // console.log(finalAmount);
                //  // console.log(1 / CryptoRates.TRX);
                convertedR = finalAmount / PKR * TRX;
                // console.log(convertedR);
                return (
                    <>
                        <div className="icon-input mb-3">
                            <input type="text" placeholder="Fee in USD (12%)"
                                className="disabled form-control ps-0"
                                readOnly={true} />
                            <div className="icon-right">
                                <b>{pamount}</b>
                            </div>
                        </div>
                        <div className="icon-input mb-3">
                            <input type="text" placeholder={`Receivable in ${item.Network}`}
                                className="disabled form-control ps-0"
                                readOnly={true} />
                            <div className="icon-right">
                                <b>{convertedR.toFixed(8)}</b>
                            </div>
                        </div>
                    </>
                );
            }
        } else if (wallet == "USDT") {
            let account = wallets.filter(
                (item) =>
                    item.WalletType == "Crypto" &&
                    item.Network == "USDT" &&
                    item.IsDefault == true
            );

            if (account.length > 0) {
                let item = account[0];
                let convertedAmount = 0;
                let convertedR = 0;
                convertedR = finalAmount / PKR * USDT;
                return (
                    <>
                        <div className="icon-input mb-3">
                            <input type="text" placeholder="Fee in USD (12%)"
                                className="disabled form-control ps-0"
                                readOnly={true} />
                            <div className="icon-right">
                                <b>{pamount}</b>
                            </div>
                        </div>
                        <div className="icon-input mb-3">
                            <input type="text" placeholder="Total Deduction"
                                className="disabled form-control ps-0"
                                readOnly={true} />
                            <div className="icon-right">
                                <b>{parseFloat(amount) + parseFloat(pamount)}</b>
                            </div>
                        </div>
                        <div className="icon-input mb-3">
                            <input type="text" placeholder={`Receivable in ${item.Network}`}
                                className="disabled form-control ps-0"
                                readOnly={true} />
                            <div className="icon-right">
                                <b>{amount}</b>
                            </div>
                        </div>
                    </>
                );
            }
        } else {
            return (
                <>

                    {/* <div className="icon-input mb-3">
                        <input type="text" placeholder="Fee in USD (0%)"
                           className="disabled form-control ps-0"
                            readOnly={true} />
                        <div className="icon-right">
                            <b>{pamount > 0 ? pamount : ""}</b>
                        </div>
                    </div> */}
                    <div className="icon-input mb-3">
                        <input type="text" placeholder="Receivable Amount"
                            className="disabled form-control ps-0"
                            readOnly={true} />
                        <div className="icon-right">
                            <b>{pamount > 0 ? pamount : ""}</b>
                        </div>
                    </div>
                </>
            );
        }
    };

    const renderSelectedWallet = () => {
        // console.log(wallets);
        if (wallet == "Bank Account") {
            let bank = wallets.filter(
                (item) => item.WalletType == "Bank Account" && item.IsDefault == true
            );
            if (bank.length > 0) {
                let item = bank[0];
                return (
                    <>
                        <input type="hidden" value={item.ID} ref={WalletIDRef} />
                        <div className="icon-input mb-3">
                            <input type="text" placeholder="Account Title"
                                className="disabled form-control ps-0"
                                readOnly={true} />

                            <div className="icon-right">
                                <b>{item.AccountTitle}</b>
                            </div>
                        </div>
                        <div className="icon-input mb-3">
                            <input type="text" placeholder="IBAN"
                                className="disabled form-control ps-0"
                                readOnly={true} />
                            <div className="icon-right">
                                <b>{item.Address}</b>
                            </div>
                        </div>
                        <div className="icon-form-group">
                            <label>Bank Name</label>
                            <div className="icon-input mb-3">
                                <input type="text" placeholder={item.BankName}
                                    className="disabled form-control ps-0"
                                    readOnly={true} />
                                <div className="icon-right">
                                    <b>{item.AccountCurrency}</b>
                                </div>
                            </div>
                        </div>
                    </>
                );
            } else {
                return (
                    <div className="else-emoji mb-3">
                        <FaceFrownIcon />
                        <h4>No Wallet Found</h4>
                    </div>
                );
            }
        }
        else if (wallet == "Cash") {
            // console.log(wallet)
            let crypto = wallets.filter(
                (item) =>
                    item.WalletType == "Cash" &&
                    item.Network == "PKR" &&
                    item.IsDefault == true

            );
            if (crypto.length > 0) {
                let item = crypto[0];
                return (
                    <>
                        <input type="hidden" value={item.ID} ref={WalletIDRef} />
                        <div className="icon-form-group">
                            <label>Address</label>
                            <div className="icon-input mb-3">
                                <input type="text" placeholder={item.Address}
                                    className="disabled form-control ps-0"
                                    readOnly={true} />
                                <div className="icon">
                                    <DocumentDuplicateIcon
                                        onClick={() => {
                                            navigator.clipboard.writeText(`${item.Address}`);
                                            toast.success('Address Copied', {
                                                position: toast.POSITION.TOP_RIGHT
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                );
            } else {
                return (
                    <div className="else-emoji mb-3">
                        <FaceFrownIcon />
                        <h4>No Wallet Found</h4>
                    </div>
                );
            }
        } else if (wallet == "BTC") {
            let crypto = wallets.filter(
                (item) =>
                    item.WalletType == "Crypto" &&
                    item.Network == "BTC" &&
                    item.IsDefault == true
            );
            if (crypto.length > 0) {
                let item = crypto[0];
                return (
                    <>
                        <input type="hidden" value={item.ID} ref={WalletIDRef} />
                        <div className="icon-form-group">
                            <label>Address</label>
                            <div className="icon-input mb-3">
                                <input type="text" placeholder={item.Address}
                                    className="disabled form-control ps-0"
                                    readOnly={true} />
                                <div className="icon">
                                    <DocumentDuplicateIcon
                                        onClick={() => {
                                            navigator.clipboard.writeText(`${item.Address}`);
                                            toast.success('Address Copied', {
                                                position: toast.POSITION.TOP_RIGHT
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                );
            } else {

                return (
                    <div className="else-emoji mb-3">
                        <FaceFrownIcon />
                        <h4>No Wallet Found</h4>
                    </div>
                );
            }
        } else if (wallet == "ETH") {
            let crypto = wallets.filter(
                (item) =>
                    item.WalletType == "Crypto" &&
                    item.Network == "ETH" &&
                    item.IsDefault == true
            );
            if (crypto.length > 0) {
                let item = crypto[0];
                return (
                    <>
                        <input type="hidden" value={item.ID} ref={WalletIDRef} />
                        <div className="icon-form-group">
                            <label>Address</label>
                            <div className="icon-input mb-3">
                                <input type="text" placeholder={item.Address}
                                    className="disabled form-control ps-0"
                                    readOnly={true} />
                                <div className="icon">
                                    <DocumentDuplicateIcon
                                        onClick={() => {
                                            navigator.clipboard.writeText(`${item.Address}`);
                                            toast.success('Address Copied', {
                                                position: toast.POSITION.TOP_RIGHT
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                );
            } else {
                return (
                    <div className="else-emoji mb-3">
                        <FaceFrownIcon />
                        <h4>No Wallet Found</h4>
                    </div>
                );
            }
        } else if (wallet == "USDT") {
            let crypto = wallets.filter(
                (item) =>
                    item.WalletType == "Crypto" &&
                    item.Network == "USDT" &&
                    item.IsDefault == true
            );
            if (crypto.length > 0) {
                let item = crypto[0];
                return (
                    <>
                        <input type="hidden" value={item.ID} ref={WalletIDRef} />
                        <div className="icon-form-group">
                            <label>Address</label>
                            <div className="icon-input mb-3">
                                <input type="text" placeholder={item.Address}
                                    className="disabled form-control"
                                    readOnly={true} />
                                <div className="icon">
                                    <DocumentDuplicateIcon
                                        onClick={() => {
                                            navigator.clipboard.writeText(`${item.Address}`);
                                            toast.success('Address Copied', {
                                                position: toast.POSITION.TOP_RIGHT
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                );
            } else {
                return (
                    <div className="else-emoji mb-3">
                        <FaceFrownIcon />
                        <h4>No Wallet Found</h4>
                    </div>
                );
            }
        } else if (wallet == "TRX") {
            let crypto = wallets.filter(
                (item) =>
                    item.WalletType == "Crypto" &&
                    item.Network == "TRX" &&
                    item.IsDefault == true
            );
            if (crypto.length > 0) {
                let item = crypto[0];
                return (
                    <>
                        <input type="hidden" value={item.ID} ref={WalletIDRef} />
                        <div className="icon-form-group">
                            <label>Address</label>
                            <div className="icon-input mb-3">
                                <input type="text" placeholder={item.Address}
                                    className="disabled form-control"
                                    readOnly={true} />
                                <div className="icon">
                                    <DocumentDuplicateIcon
                                        onClick={() => {
                                            navigator.clipboard.writeText(`${item.Address}`);
                                            toast.success('Address Copied', {
                                                position: toast.POSITION.TOP_RIGHT
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                );
            } else {
                return (
                    <div className="else-emoji mb-3">
                        <FaceFrownIcon />
                        <h4>No Wallet Found</h4>
                    </div>
                );
            }
        }
    };
    const checkWallet = (WalletType) => {
        let selected = WalletType;
        setWallet(WalletType);
        setActiveClass(WalletType);
        setAmount('');
        setAmounterror(false);
        if (selected == "Bank Account") {
            let bank = wallets.filter(
                (item) => item.WalletType == "Bank Account" && item.IsDefault == true
            );
            if (bank.length > 0) {
                setnoWallet(true);
            } else {
                setnoWallet(false);
            }
        } else if (selected == "Cash") {

            let Cash = wallets.filter(
                (item) =>
                    item.WalletType == "Cash" &&
                    item.Network == "PKR" &&
                    item.IsDefault == true
            );
            if (Cash.length > 0) {
                setnoWallet(true);
            } else {
                setnoWallet(false);
            }
        } else if (selected == "BTC") {
            // let currentRate = CryptoRates.filter((c) => c.asset_id_quote == "BTC");

            // console.log(currentRate);

            // let BTC = currentRate[0].rate;

            let crypto = wallets.filter(
                (item) =>
                    item.WalletType == "Crypto" &&
                    item.Network == "BTC" &&
                    item.IsDefault == true
            );
            if (crypto.length > 0) {
                setnoWallet(true);
            } else {
                setnoWallet(false);
            }
        } else if (selected == "ETH") {
            let crypto = wallets.filter(
                (item) =>
                    item.WalletType == "Crypto" &&
                    item.Network == "ETH" &&
                    item.IsDefault == true
            );
            if (crypto.length > 0) {
                setnoWallet(true);
            } else {
                setnoWallet(false);
            }
        } else if (selected == "USDT") {
            let crypto = wallets.filter(
                (item) =>
                    item.WalletType == "Crypto" &&
                    item.Network == "USDT" &&
                    item.IsDefault == true
            );
            if (crypto.length > 0) {
                setnoWallet(true);
            } else {
                setnoWallet(false);
            }
        } else if (selected == "TRX") {
            let crypto = wallets.filter(
                (item) =>
                    item.WalletType == "Crypto" &&
                    item.Network == "TRX" &&
                    item.IsDefault == true
            );
            if (crypto.length > 0) {
                setnoWallet(true);
            } else {
                setnoWallet(false);
            }
        } else if (selected == "BUSD") {
            let crypto = wallets.filter(
                (item) =>
                    item.WalletType == "Crypto" &&
                    item.Network == "BUSD" &&
                    item.IsDefault == true
            );
            if (crypto.length > 0) {
                setnoWallet(true);
            } else {
                setnoWallet(false);
            }
        }
    };

    useEffect(() => {
        let interval;

        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isButtonDisabled]);




    return (
        <>
            <ReactTooltip />
            <ToastContainer />
            <LoginCheck />
            <div className="mobile-container">
                <div className="row align-items-center mb-3">
                    <div className="col-6">
                        <div className="section-title text-start">Payout</div>
                    </div>
                    <div className="col-6 text-end">

                        <Link to={`/dashboard/profile/wallets`} style={{ fontSize: 12, padding: "10px 12px" }} className="button button-3d-primary">

                            <span>Manage Wallets</span>

                        </Link>
                    </div>
                </div>
            </div>

            {
                loading ?
                    <Spinner animation="border" role="status" className="full-screen-loading">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    :
                    <div className="dash-bg-card mb-3">
                        <div className="row">
                            <div className="col-12 wallet-wrap form-default">
                                <h5 className="text-center mb-4">SELECT PAYOUT MODE</h5>

                                <Form
                                    noValidate
                                    validated={validated}
                                    onSubmit={handleSubmitWithdraw}
                                >
                                    <div className="wallet-head">
                                        <div className='active-link-menu'>
                                            <ul className="nav justify-content-center">
                                                <li className='nav-item' onClick={(e) => checkWallet("BTC")}>
                                                    <a className={`nav-link ${ActiveClass === "BTC" ? "active" : ""}`}>
                                                        <span>Bitcoin - BTC</span>
                                                    </a>
                                                </li>
                                                <li className='nav-item' onClick={(e) => checkWallet("USDT")}>
                                                    <a className={`nav-link ${ActiveClass === "USDT" ? "active" : ""}`}>
                                                        <span>TRC20 - USDT</span>
                                                    </a>
                                                </li>
                                                <li className='nav-item' onClick={(e) => checkWallet("TRX")}>
                                                    <a className={`nav-link ${ActiveClass === "TRX" ? "active" : ""}`}>
                                                        <span>TRON - TRX</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {

                                        ActiveClass != null && ActiveClass != '' && ActiveClass.length > 0 ?
                                            <>
                                                {
                                                    noWallet ?
                                                        <>
                                                            <div className="available-balance">
                                                                <label>Available Balance </label>
                                                                {/* <span>PKR {NumberFormat(dData.Member?.Current_Balance)}</span> */}
                                                                <span>USD {NumberFormat(remainingBalance)}</span>
                                                            </div>

                                                            <div className="wallet-body pt-0">
                                                                <div className="icon-input mb-3">
                                                                    <input
                                                                        placeholder="Amount in USD"
                                                                        type="number"
                                                                        className={`form-control ${dData.Member?.Current_Balance > 0 ? '' : 'disable'}`}
                                                                        value={amount}
                                                                        maxLength="7"
                                                                        name="amount"
                                                                        disabled={
                                                                            dData.Member?.Current_Balance > 0 ? false : true
                                                                        }
                                                                        onChange={(e) => CalcPercentage(e)}
                                                                        required
                                                                    />
                                                                    <div className="icon">
                                                                        <CurrencyDollarIcon />
                                                                    </div>
                                                                    {/* <div className="icon-right">
                                                                        <b>{finalAmount > 0 ? finalAmount : ""}</b>
                                                                    </div> */}
                                                                </div>

                                                                <ul className="add-condition mt-3">
                                                                    {Amounterror && <li> <p className="Resend m-0"> Minimum amount: USD 60</p></li>}
                                                                </ul>

                                                                {renderSelectedWallet()}
                                                                {renderFeeBox()}
                                                                <div className="code pb-2 pt-3 mb-3">
                                                                    <div className="row align-items-top">
                                                                        <div className="col-12">
                                                                            <div className="icon-form-group">
                                                                                <div className="icon-input mb-3">
                                                                                    <input type="text"
                                                                                        value={AuthCode}
                                                                                        onChange={(e) => {
                                                                                            const value = e.target.value;
                                                                                            if (/^\d{0,6}$/.test(value)) {
                                                                                                setAuthCode(value);
                                                                                            }
                                                                                        }}
                                                                                        className="form-control"
                                                                                        placeholder='Email Verification Code' name="AuthCode" autoComplete="disable" required />

                                                                                    <div className="icon">
                                                                                        <LockClosedIcon />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <button
                                                                                className="button button-3d-outline w-100 text-nowrap mt-3"
                                                                                onClick={handleSendCode}
                                                                                disabled={isButtonDisabled}
                                                                            >
                                                                                GET CODE
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 d-none">
                                                                    <div className="form-group mb-3">
                                                                        <label>2FA </label>
                                                                        <div className="inputwrap">
                                                                            <input type="text" className="form-control" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="icon-form-group">
                                                                    <label>Transaction Pin</label>
                                                                    <div className="form-group mb-3">
                                                                        <input type="number"
                                                                            placeholder='XXXXXX' value={OTP}
                                                                            onChange={(e) => {
                                                                                if (e.target.value.length <= 6) {
                                                                                    setOTP(e.target.value);
                                                                                }
                                                                            }}
                                                                            maxLength={6} autoComplete="disable" required className="text-center form-control" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="wallet-footer">
                                                                <button className={`button button-3d-primary button-icon-bs justify-content-center w-100`}
                                                                    type="submit"
                                                                    disabled={amount == 0 || !AuthCode || OTP.length == 0 || !noWallet || dData.Member?.Current_Balance == 0 || Amounterror == true || loading
                                                                    }
                                                                >
                                                                    <span>Withdraw {loading ? "..." : ""}</span><ArrowLongRightIcon />
                                                                </button>
                                                            </div>

                                                            <p className="text-center mt-3" style={{ fontSize: "14px", color: "#fff" }}>The Payout is paid in working days (Monday to Saturday).</p>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="else-emoji mb-3 mt-5">
                                                                <FaceFrownIcon />
                                                                <h4>No Wallet Found</h4>
                                                                <p className="text-center mt-4">
                                                                    Go to Manage Wallets page<br /> to add new wallet.
                                                                </p>

                                                                <div className="text-center">

                                                                    <Link to={`/dashboard/profile/wallets`} style={{ fontSize: 12, padding: "10px 12px" }} className="button button-3d-primary">

                                                                        <span>Manage Wallets</span>

                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </>


                                                }

                                            </>
                                            :
                                            <>
                                                <div className="withdraw-first-step mt-4">
                                                    <ArrowUpCircleIcon />
                                                    <p>First Select Payout Mode</p>
                                                </div>
                                            </>

                                    }

                                </Form>
                            </div>
                        </div>
                    </div>
            }

        </>
    );
}

export default BusinessWithdraw