import axios from 'axios';
import config from '../../Config';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";



async function sendEmail(props) {
    const AccessKey = localStorage.getItem('AccessKey');
    const UserID = localStorage.getItem('ID');

    const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        TransactionType: props.TransactionType
    };
    // console.log(data);

    var api_config = {
        method: 'post',
        url: config.base_url + 'Security/SendAuthenticationCode',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    try {
        const response = await axios(api_config);
        if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
                position: toast.POSITION.TOP_RIGHT, 
autoClose: 1000
                //onClose: () => window.alert('Called when I close')
            });
        }
        else {
            toast.error(response.data.status_message, {
                position: toast.POSITION.TOP_RIGHT, 
autoClose: 1000
                //onClose: () => window.alert('Called when I close')
            });
        }
        // Additional logic or handling of the response
    } catch (error) {
        // Handle the error
    }
}

export default sendEmail;
