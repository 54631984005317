import React, { useEffect, useState } from 'react';
import Config from '../../Config';


const UserList = (props) => {
  const [users, setUsers] = useState(props.data);
  const [imageErrors, setImageErrors] = useState({});

  const handleImageError = (userId) => {
    setImageErrors((prev) => ({ ...prev, [userId]: true }));
  };


  useEffect(() => {
    if (props.data) {
      setUsers(props.data);
    }
  }, [props.data]);

  const getFirstChar = (str) => {
    const firstChars = str
      .split(" ")
      .map((word) => word[0])
      .join("");
    return firstChars;
  };



  return (
    <>
      {users != null && users.map((user, index) => (
        <li key={index}>
          <div className="member-info">
            <div className="user-info">
              <div className="photo">
                {imageErrors[user.ID] ? (
                  <span className="avatar">{getFirstChar(user.Name)}</span>
                ) : (
                  <img
                    src={`${Config.root}images/avatar/${user.ID}.png`}
                    onError={() => handleImageError(user.ID)}
                    className="avatar"
                    alt="User Avatar"
                  />
                )}
              </div>
              <div className="content">
                <p>{user.Name}</p>
                <span>{user.Country}</span>
              </div>
            </div>
            <h4>$ {user.Investment}</h4>
          </div>
          <div className="divider"></div>
        </li>
      ))}
    </>
  );
}

export default UserList;