import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

function Countdown(props) {
    const [countdownDate, setCountdownDate] = useState(null);
    const [state, setState] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        //// console.log('props.EndDate', props.EndDate);
        if (props.EndDate) {
            // Set the countdown date to the prop value as a moment.js object
            const momentDate = moment.tz(props.EndDate, 'America/New_York');
            // Set the countdown date state value to the milliseconds since the Unix Epoch
            setCountdownDate(momentDate.valueOf());
        }
    }, [props.EndDate]);

    useEffect(() => {
        // Update the countdown timer every second
        const interval = setInterval(() => setNewTime(), 1000);
        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, [countdownDate]);

   const setNewTime = () => {
    if (countdownDate) {
        // Get the current time as a moment.js object in the USA timezone
        const currentTime = moment().tz('America/New_York');
        // Get the duration between the current time and the countdown date
        const duration = moment.duration(countdownDate - currentTime.valueOf());

        // Ensure the countdown doesn't go negative
        if (duration.asMilliseconds() < 0) {
            setState({
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            });
        } else {
            // Set the state values for days, hours, minutes, and seconds
            setState({
                days: Math.floor(duration.asDays()),
                hours: duration.hours(),
                minutes: duration.minutes(),
                seconds: duration.seconds(),
            });
        }
    }
};


    return (
        <>
            <div id="clockdiv" className={props.class}>
                <div className={`wrapper ${props.days}`}>
                    <div className='time'>
                        <span className="days">{state.days}</span>
                    </div>
                    <div className="smalltext">Days</div>
                </div>
                <div className={`wrapper ${props.hours}`}>
                    <div className='time'>
                        <span className="hours">{state.hours.toString().padStart(2, '0')}</span>
                    </div>
                    <div className="smalltext">Hours</div>
                </div>
                <div className={`wrapper ${props.minutes}`}>
                    <div className='time'>
                        <span className="minutes">{state.minutes.toString().padStart(2, '0')}</span>
                    </div>
                    <div className="smalltext">Minutes</div>

                </div>
                <div className={`wrapper ${props.seconds}`}>
                    <div className='time'>
                        <span className="seconds">{state.seconds.toString().padStart(2, '0')}</span>
                    </div>
                    <div className="smalltext">Seconds</div>
                </div>
            </div>
        </>
    );
}

export default Countdown;
