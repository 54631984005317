
import React, { useState, useEffect, useRef } from 'react';


import "@lottiefiles/lottie-player";
import { Player } from '@lordicon/react';


import team from "../../assets/images/animation/team.json";


function NewMembersCount(props) {


    const playerRef = useRef();

    useEffect(() => {
        playerRef.current?.playFromBeginning();
    }, [])



    const initialMembers = 5000;
    const startDate = new Date('2024-07-19T00:00:00Z'); // Set your starting date here

    const calculateMembers = () => {
        const now = new Date();
        const diffInMilliseconds = now - startDate;
        const diffInMinutes = Math.floor(diffInMilliseconds / 20000);
        return initialMembers + diffInMinutes;
    };

    const [totalMembers, setTotalMembers] = useState(calculateMembers());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTotalMembers(calculateMembers());
        }, 20000); // Update every minute

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, []);


    return (
        <>

            <div className='new-members'>
                
                <Player
                    size={80}
                    ref={playerRef}

                    icon={team}
                    onComplete={() => playerRef.current?.playFromBeginning()}
                />

                <p>Total Subscribers</p>
                <h3>{totalMembers}</h3>
            </div>
        </>
    );
}

export default NewMembersCount;